.w288 {
  width: 288px;
}
.w440 {
  width: 440px;
}
.center_w0 {
  width: calc(100% - 440px);
}
.center_w1 {
  width: calc(100% - 288px - 440px);
}
