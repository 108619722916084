.campagin_DropDown {
  position: absolute;
  top: 42px;
  min-width: 127px;
  border-radius: 3px;
  z-index: 1000;
}
.campagin_DropDown span {
  min-height: 41px;
  cursor: pointer;
  padding: 8px 16px;
  justify-content: flex-start;
}
.campagin_DropDown .spanlight_hover:hover {
  background: #E0E0E0 !important;
}
