.header {
  width: 100%;
  padding: 16px 16px 13px 16px;
  min-height: 97px;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  font-weight: 400;
}
.header .title {
  display: block;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #008FC7;
  margin-bottom: 6px;
}
.header .msg {
  flex-wrap: wrap;
  align-items: flex-end;
}
.header .msg span {
  display: block;
}
.header .msg .cur {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}
.header .msg img {
  width: 13.86px;
  height: 12px;
  margin: 0 12px;
  position: relative;
  bottom: 4px;
}
.header .msg .num {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
.header .msg .date {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
  margin-top: 4px;
}
.chart {
  padding: 0 16px 16px 16px;
  position: relative;
}
.month_con {
  padding: 0 16px 16px 16px;
  flex-wrap: wrap;
}
.month_con span {
  display: block;
  width: calc(100% / 4);
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #828282;
  font-weight: 400;
  font-size: 12px;
}
.month_con span:last-child {
  border-right: 0 !important;
}
.month_con .values {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
}
.month_con .borderRight_light {
  border-right: 2px solid #E0E0E0 !important;
}
.month_con .borderRight_dark {
  border-right: 2px solid #373737 !important;
}
