.buyPower_con {
  min-height: 192px;
  width: 528px;
  padding: 16px;
  background: #222429;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}
.buyPower_con .buyPower_title {
  font-size: 16px;
  line-height: 18px;
}
.buyPower_con .buyPower_btn {
  justify-content: flex-end;
}
.buyPower_con .buyPower_btn button {
  width: 128px;
  height: 40px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  padding: 0;
}
.buyPower_con .buyPower_btn button:first-child {
  margin-right: 15px;
}
.buyPower_con .buyPower_btn :global(.ant-btn:focus) {
  background: #393939;
}
.buyPower_con_light {
  background: #FFFFFF;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}
.buyPower_con_mobile {
  width: 70%;
  margin: 0 auto;
}
.buyPower_con_mobile .buyPower_btn {
  justify-content: space-between;
}
.buyPower_con_mobile .buyPower_btn button {
  width: 49%;
}
