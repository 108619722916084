.common {
  padding: 8px 16px;
}
.common .child {
  width: 27%;
  justify-content: center;
  font-weight: 500;
  text-align: right;
}
.common .child:first-child {
  text-align: left;
  width: 19%;
}
.common .childCurrency {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-start;
}
.header {
  font-size: 13px;
  line-height: 15px;
  color: #828282;
  min-height: 57px;
}
