.alerts_history {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1;
}
.alerts_history .padding120 {
  padding: 120px 0;
}
.alerts_history .title {
  display: block;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 12px;
  min-height: 30px;
  line-height: 30px;
}
.alerts_history .title .icon_search {
  position: absolute;
  top: 15px;
  left: 30px;
}
.alerts_history .title .icon_clear {
  position: absolute;
  top: 15px;
  right: 30px;
}
.alerts_history .title input {
  padding-left: 38px;
  width: 100%;
  border-radius: 40px;
}
.alerts_history .title input.typing {
  padding-left: 25px;
}
.alerts_history .title .dropdown > div {
  top: 38px;
  padding: 0 16px;
}
.alerts_history .common {
  align-items: center;
}
.alerts_history .common > span {
  text-align: left;
  padding-right: 8px;
}
.alerts_history .common > span:first-child {
  width: 56px;
  padding-left: 8px;
}
.alerts_history .common > span:nth-child(2) {
  width: calc(100% - 340px);
}
.alerts_history .common > span:nth-child(3) {
  width: 60px;
}
.alerts_history .common > span:nth-child(4) {
  width: 80px;
  text-align: right;
}
.alerts_history .common > span:last-child {
  width: 144px;
  text-align: right;
}
.alerts_history .common_min span {
  /*
        width: 20%;
        &:first-child {
          width: 40%;
        }
        */
}
.alerts_history .header {
  height: 32px;
  padding: 0 8px;
}
.alerts_history .header span {
  font-size: 12px;
  line-height: 14px;
}
.alerts_history .header .sort_button {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.alerts_history .header .sort_button svg {
  margin-left: 5px;
  width: 7px;
}
.alerts_history .header .sort_button .asc path:nth-child(2) {
  fill: #ffffff;
}
.alerts_history .header .sort_button .asc.light path:nth-child(2) {
  fill: #333333;
}
.alerts_history .header .sort_button .desc path:nth-child(1) {
  fill: #ffffff;
}
.alerts_history .header .sort_button .desc.light path:nth-child(1) {
  fill: #333333;
}
.alerts_history .body .body_one {
  min-height: 32px;
  padding: 0 8px;
}
.alerts_history .body .body_one_min {
  min-height: 32px;
}
.alerts_history .body .empty_area {
  height: 300px;
}
.alerts_history .body .bold {
  font-weight: bold;
}
.alerts_history .bottom_pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding-right: 4px;
  /*
    :global(.ant-pagination-item),
    :global(.ant-pagination-prev),
    :global(.ant-pagination-next) {
      margin-right: 2px;
    }
    */
}
.alerts_history .bottom_pagination .page_number {
  padding: 24px 0 24px 16px;
}
.alerts_history .bottom_pagination :global(.ant-pagination-item:hover) {
  background: #008fc7 !important;
  border-radius: 3px;
}
.alerts_history .bottom_pagination :global(.ant-pagination-item:hover a) {
  color: #ffffff !important;
}
.alerts_history.chinese .common > span:first-child {
  width: 66px;
  padding-left: 8px;
}
.alerts_history.chinese .common > span:nth-child(2) {
  width: calc(100% - 360px);
}
.alerts_history.chinese .common > span:nth-child(3) {
  width: 80px;
}
.alerts_history.chinese .common > span:nth-child(4) {
  width: 70px;
  text-align: right;
}
.alerts_history.chinese .common > span:last-child {
  width: 144px;
  text-align: right;
}
.alerts_history.mobile .common.body_one {
  min-height: 55px;
  font-size: 16px;
}
.alerts_history.mobile .common .name_mobile {
  font-size: 14px;
}
.alerts_history.mobile .common > span {
  text-align: left;
  padding-right: 8px;
}
.alerts_history.mobile .common > span:first-child {
  width: calc(100% - 250px);
  padding-left: 8px;
}
.alerts_history.mobile .common > span:nth-child(2) {
  width: 50px;
}
.alerts_history.mobile .common > span:nth-child(3) {
  width: 100px;
  text-align: right;
}
.alerts_history.mobile .common > span:last-child {
  width: 100px;
  text-align: right;
}
.alerts_history.mobile .header {
  height: 42px;
}
.alerts_history.mobile .header span {
  font-size: 13px !important;
  line-height: 15px;
}
.alerts_history.mobile .bottom_pagination {
  flex-direction: column;
}
.alerts_history.mobile.chinese .common > span:first-child {
  width: calc(100% - 280px);
  padding-left: 8px;
}
.alerts_history.mobile.chinese .common > span:nth-child(2) {
  width: 90px;
  font-size: 14px;
}
.alerts_history.mobile.chinese .common > span:nth-child(3) {
  width: 90px;
  text-align: right;
}
.alerts_history.mobile.chinese .common > span:last-child {
  width: 100px;
  text-align: right;
}
