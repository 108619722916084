.Watchlist_title {
  height: 48px;
  min-height: 48px;
  padding: 0 8px;
  align-items: center;
}
.Watchlist_title span {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  padding-left: 6px;
}
.watchlist_name {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
}
.watchlist_edit {
  align-items: center;
  padding: 0 16px;
  cursor: default;
}
.watchlist_edit input {
  flex: 1 1;
  -webkit-flex: 1;
  padding-right: 20px;
  padding-left: 0;
  height: 100%;
}
.watchlist_edit [type=text] {
  -webkit-user-select: text !important;
}
.watchlist_edit input[disabled],
.watchlist_edit input:disabled,
.watchlist_edit input.disabled {
  color: #828282 !important;
  -webkit-text-fill-color: #828282 !important;
  -webkit-opacity: 1;
  opacity: 1;
}
.watchlist_footer_edit {
  height: 56px !important;
  min-height: 56px !important;
  line-height: 56px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  padding: 0 16px !important;
  justify-content: flex-end !important;
}
.watchlist_footer_edit > * {
  width: auto !important;
}
