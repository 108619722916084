.notification {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1;
}
.notification .min48_con {
  min-height: 48px;
  padding: 0 16px;
}
.notification .line {
  height: 8px;
  width: 100%;
}
.notification :global(.ant-switch) {
  background-color: #828282;
  height: 22px;
  line-height: 22px;
  width: 44px;
  border-radius: 100px;
}
.notification :global(.ant-switch-checked) {
  background: #008fc7;
}
.notification_botton_button_bar {
  background-color: #222429;
  width: 100%;
}
