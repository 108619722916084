.market {
  min-width: 127px;
  min-height: 32px;
  border-radius: 40px;
  padding: 2px 16px;
  cursor: pointer;
}
.dropDownImg {
  width: 16px;
  height: 12px;
}
.marketMobile {
  min-width: auto;
}
