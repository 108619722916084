.news_top {
  padding: 16px;
}
.news_top .news_dropdown_header {
  border-radius: 40px;
  padding: 8px 16px;
  cursor: pointer;
  margin-bottom: 8px;
  position: relative;
}
.news_top .news_dropdown_header .select_img {
  transform: rotate(0deg);
  width: 15px;
  height: 14px;
}
.news_top .news_dropdown_header .imgRotate {
  transform: rotate(180deg);
}
.news_top .news_dropdown_header .news_dropdown_con {
  position: absolute;
  top: 34px;
  left: 0;
  width: 100%;
  z-index: 10;
  background: #333333;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
.news_top .news_dropdown_header .news_dropdown_con span {
  display: block;
  height: 40px;
  padding: 12px 16px;
}
.news_top .news_reset {
  text-align: center;
}
.news_top .news_reset span {
  font-size: 12px;
  line-height: 14px;
  color: #56CCF2;
  border-bottom: 1px solid #56CCF2;
  cursor: pointer;
}
.news_result_con {
  min-height: 48px;
  padding: 0 8px;
}
.news_result_con img {
  margin-right: 4px;
}
.news_list_one {
  padding: 8px 16px;
  cursor: pointer;
}
.news_list_one span {
  font-weight: 700;
  line-height: 18px;
}
.news_list_one span:last-child {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}
