.inputs_con {
  width: 100%;
  height: 32px;
  background: #393939;
  border-radius: 40px;
  padding: 2px 16px;
  align-items: center;
}
.inputs_con input {
  flex: 1 1;
  -webkit-flex: 1;
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 14px;
  width: 80%;
}
.inputs_con .dropsDown_con {
  min-height: 100px;
  max-height: 300px;
  position: absolute;
}
.inputs_con_light input {
  color: #333333 !important;
}
