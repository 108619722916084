.header_con {
  height: 56px;
  min-height: 56px;
  align-items: center;
  position: relative;
}
.header_con .header_btn_auto {
  padding: 4px 6px 4px 2px;
  border-radius: 3px;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
  background: #373737;
}
.header_con .header_btn_auto_hover:hover {
  background: #1b1b1b;
}
.header_con .header_auto_active {
  color: #FFFFFF;
  background: #1b1b1b;
}
.header_con .header_btn_auto_light {
  color: #333333;
  background-color: #FFFFFF;
  border: 1px solid #e0e0e0;
}
.header_con .header_auto_hover_light:hover {
  background-color: #e0e0e0;
  border: 1px solid #f3f3f3;
}
.header_con .header_auto_active_light {
  background: #e0e0e0;
  border: 1px solid #e0e0e0;
}
