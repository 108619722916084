.dropDown {
  width: 100px;
  max-height: 208px;
  z-index: 99;
  border-radius: 3px;
  background: #373737;
  overflow-y: auto;
  box-shadow: 2px 2px 8px #000000;
  position: absolute;
  left: 0;
  top: 28px;
}
.dropDown::-webkit-scrollbar-thumb {
  background-color: #000000;
}
.dropDown span {
  padding: 0 16px;
  min-height: 35px;
  cursor: pointer;
  border-bottom: 1px solid #333333;
  align-items: center;
}
.dropDownLight {
  background: #FFFFFF;
  box-shadow: 2px 2px 8px #E0E0E0;
}
.dropDownLight::-webkit-scrollbar-thumb {
  background-color: #828282;
}
.dropDownLight span {
  border-bottom: 1px solid #E0E0E0;
}
.dropDownLight .spanlight_hover:hover {
  background: #E0E0E0 !important;
}
