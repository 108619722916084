.subTitleCon {
  min-height: 29px;
  padding: 0 16px;
}
.subTitleCon span {
  text-align: right;
  padding-right: 8px;
  font-size: 13px;
  line-height: 15px;
}
.childYear {
  min-width: 85px;
}
.common {
  padding: 8px 16px;
  min-height: 70px;
}
.common .child span {
  text-align: right;
  padding-right: 8px;
}
.header {
  font-size: 13px;
  line-height: 15px;
  color: #828282;
  min-height: 60px;
  font-weight: 400;
}
.body .childYear {
  font-size: 12px;
  font-weight: 500;
}
.body .child span {
  font-weight: 500;
}
