.portfolio_con {
  position: relative;
}
.portfolio_con .account_table .account_table_oneheader {
  width: 100%;
}
.portfolio_con .account_table .account_table_oneheader div {
  height: 32px;
  align-items: center;
  font-weight: 700;
}
.portfolio_con .account_table .account_table_oneheader .paddingRight_header_con {
  margin: 0 8px;
}
.portfolio_con .account_table .account_table_oneheader .table_oneheader_light {
  background: #E0E0E0;
  color: #333333;
}
.portfolio_con .account_table .account_table_oneheader .table_oneheader_dark {
  background: #393939;
  color: #FFFFFF;
}
.portfolio_con .account_table .sticky_con {
  position: fixed;
  left: 0;
  top: 171px;
  width: 100%;
  z-index: 9;
}
.portfolio_con .account_table .sticky_top_0 {
  top: 104px;
}
.portfolio_con .account_table .sticky_top_1 {
  top: 202px;
}
.portfolio_con .account_table .sticky_top_2 {
  top: 136px;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon {
  border-bottom: 1px solid #373737;
  align-items: center;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon > * {
  width: 8%;
  text-align: right;
  padding-right: 6px;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon > *:first-child {
  width: 10%;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon > *:nth-child(2) {
  width: 38%;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon > *:nth-child(3) {
  width: 10%;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon > *:nth-child(-n+3) {
  text-align: left;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon > *:last-child {
  padding-right: 0;
  width: 10%;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon_min {
  min-height: 48px !important;
  padding: 4px 16px !important;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon_min > * {
  width: 18%;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon_min > *:first-child {
  width: 42%;
  text-align: left;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon_min > *:nth-child(2) {
  width: 18%;
  text-align: right;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon_min > *:nth-child(3) {
  width: 18%;
  text-align: right;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon_min > *:last-child {
  width: 4%;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon_mobile {
  min-height: 48px !important;
  padding: 4px 8px !important;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon_mobile > * {
  width: 22%;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon_mobile > *:first-child {
  width: 32%;
  text-align: left;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon_mobile > *:nth-child(2) {
  width: 18%;
  text-align: right;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon_mobile > *:nth-child(3) {
  width: 21%;
  text-align: right;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon_mobile > *:nth-child(4) {
  width: 23%;
  text-align: right;
}
.portfolio_con .account_table .account_table_body .account_table_bodycommon_mobile > *:last-child {
  width: 6%;
}
.portfolio_con .account_table .account_table_body .account_table_bodyheader {
  padding: 4px 16px;
  min-height: 32px;
}
.portfolio_con .account_table .account_table_body .account_table_bodyheader > * {
  font-size: 10px;
  line-height: 12px;
}
.portfolio_con .account_table .account_table_body .header_pc > * {
  font-size: 12px;
  line-height: 14px;
}
.portfolio_con .account_table .account_table_body .header_mobile > * {
  font-size: 13px;
  line-height: 15px;
}
.portfolio_con .account_table .account_table_body .header_mobileD > * {
  color: #FFFFFF;
  font-size: 13px;
}
.portfolio_con .account_table .account_table_body .header_mobileW > * {
  color: #333333;
  font-size: 13px;
}
.portfolio_con .account_table .account_table_body .fz11_zhcn > * {
  font-size: 11px !important;
}
.portfolio_con .account_table .account_table_body .account_table_bodyBody {
  color: #FFFFFF;
  padding: 8px 16px;
  min-height: 52px;
}
.portfolio_con .account_table .account_table_body .account_table_bodyBody button {
  background: #008FC7;
  border-radius: 2.96517px;
  line-height: 14px;
  width: 100%;
  height: 24px;
}
.portfolio_con .account_table .account_table_body .account_table_bodyBody .account_cur {
  min-height: 35px;
  align-items: center;
}
.portfolio_con .account_table .account_table_body .account_table_bodyBody .account_cur label {
  cursor: pointer;
}
.portfolio_con .account_table .account_table_body .account_table_bodyBody .account_name_cur {
  border: 1px solid #828282;
  border-radius: 3px;
  padding: 2px 4px;
  color: #828282;
  font-size: 10px;
  line-height: 12px;
  margin-left: 4px;
}
.portfolio_con .account_table .account_table_body .account_table_bodyBody_light {
  color: #333333;
  border-bottom: 1px solid #E0E0E0;
}
.portfolio_con .account_table .account_table_body .account_table_bodyLast {
  padding: 8px 16px;
  font-weight: 700;
  min-height: 52px;
  border-bottom: 0;
}
.portfolio_con .account_table .account_table_body .account_table_bodyLast > *:first-child {
  width: 74%;
  text-align: left;
}
.portfolio_con .account_table .account_table_body .account_table_bodyLast > *:nth-child(2) {
  width: 8%;
  text-align: right;
}
.portfolio_con .account_table .account_table_body .account_table_bodyLast > *:nth-child(3) {
  width: 8%;
  text-align: right;
}
.portfolio_con .account_table .account_table_body .account_table_bodyLast > *:last-child {
  width: 10%;
}
.portfolio_con .account_table .account_table_body .account_table_bodyLast_min {
  padding: 4px 16px !important;
  min-height: 48px;
}
.portfolio_con .account_table .account_table_body .account_table_bodyLast_min > *:first-child {
  width: 62%;
  text-align: left;
}
.portfolio_con .account_table .account_table_body .account_table_bodyLast_min > *:nth-child(2) {
  width: 34%;
  text-align: right;
}
.portfolio_con .account_table .account_table_body .account_table_bodyLast_min > *:last-child {
  width: 4%;
}
.portfolio_con .account_table .account_table_body .account_table_bodyLast_mobile {
  min-height: 48px !important;
  padding: 4px 8px !important;
}
.portfolio_con .account_table .account_table_body .account_table_bodyLast_mobile > *:first-child {
  width: 60%;
  text-align: left;
}
.portfolio_con .account_table .account_table_body .account_table_bodyLast_mobile > *:nth-child(2) {
  width: 34%;
  text-align: right;
}
.portfolio_con .account_table .account_table_body .account_table_bodyLast_mobile > *:last-child {
  width: 6%;
}
.portfolio_con .account_table .account_header_totalcost {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.portfolio_con .account_table .account_header_totalcost span {
  width: 100%;
  text-align: right;
}
.portfolio_con .account_table .account_header_totalcost span:first-child {
  margin-bottom: 2px;
}
.portfolio_con .account_tableMobile {
  overflow: hidden;
  padding: 0 8px;
}
.portfolio_con .footer {
  min-height: 83px;
  padding: 16px;
  font-size: 12px;
  line-height: 14px;
}
.dropDown_img_up {
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  -moz-transform: translateY(-50%) rotate(90deg);
  -webkit-transform: translateY(-50%) rotate(90deg);
  -o-transform: translateY(-50%) rotate(90deg);
}
.dropDown_img_down {
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
}
