.sector_con {
  margin-left: 72px;
}
.sector_con .sector_con_body .header {
  align-items: center;
  height: 48px;
  min-height: 48px;
  padding: 0 16px;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.sector_con .sector_con_body .header::-webkit-scrollbar {
  height: 0 !important;
}
.sector_con .sector_con_body .header_line {
  display: block;
  width: 1px;
  height: 16px;
}
.sector_con .sector_con_body .list_con {
  overflow: hidden;
  height: 100%;
}
.sector_con .sector_con_body .list_con .list_con_header {
  min-height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 700;
}
.sector_con .sector_con_body .list_con .list_con_header img {
  margin: 0 4px 0 8px;
}
.sector_con .sector_con_body .list_con > * {
  width: calc(100% / 3);
}
.sector_con .sector_con_body .list_con .left .common {
  align-items: center;
}
.sector_con .sector_con_body .list_con .left .common span {
  display: block;
  width: 20%;
  text-align: center;
}
.sector_con .sector_con_body .list_con .left .common span:first-child {
  width: 60%;
  padding-left: 16px;
  text-align: left;
}
.sector_con .sector_con_body .list_con .left .common span:nth-child(2) {
  text-align: right;
  padding-right: 8px;
}
.sector_con .sector_con_body .list_con .left .header {
  min-height: 32px;
}
.sector_con .sector_con_body .list_con .left .header span {
  color: #828282;
  font-weight: 400;
  font-size: 10px;
}
.sector_con .sector_con_body .list_con .left .body {
  flex: 1 1;
  -webkit-flex: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 60px;
}
.sector_con .sector_con_body .list_con .left .body .body_one {
  min-height: 40px;
}
.sector_con .sector_con_body .list_con .left .body .body_one span {
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
}
.sector_con .sector_con_body .list_con .left .body .body_one span:first-child {
  font-weight: 700;
}
.sector_con .sector_con_body .list_con .left .body .body_one span:nth-child(2) {
  padding-right: 8px;
}
.sector_con .sector_con_body .list_con .left .body .body_one span:last-child {
  height: 40px;
  line-height: 40px;
}
.sector_con .sector_con_body .list_con .center .stocks {
  padding: 0 8px;
}
.sector_con .sector_con_body .list_con .center .stocks .title {
  display: block;
  font-weight: 500;
  font-size: 12px;
  padding: 7px 8px;
  color: #828282;
}
.sector_con .sector_con_body .list_con .center .stocks .common {
  align-items: center;
}
.sector_con .sector_con_body .list_con .center .stocks .common span {
  width: 18%;
  text-align: right;
  padding-right: 8px;
  font-weight: 400;
}
.sector_con .sector_con_body .list_con .center .stocks .common span:first-child {
  text-align: left;
  padding-left: 8px;
}
.sector_con .sector_con_body .list_con .center .stocks .common span:nth-child(2) {
  width: 28%;
  text-align: left;
}
.sector_con .sector_con_body .list_con .center .stocks .header {
  height: 32px;
}
.sector_con .sector_con_body .list_con .center .stocks .header span {
  font-size: 10px;
  line-height: 12px;
  color: #828282;
}
.sector_con .sector_con_body .list_con .center .stocks .body {
  min-height: 32px;
}
.sector_con .sector_con_body .list_con .center .stocks .body span {
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
}
.sector_con .sector_con_body .list_con .center .stocks .body span:nth-child(2) {
  font-weight: 500;
  color: #00D4F0;
}
.sector_con .sector_con_body .list_con .center_min {
  width: 60%;
}
.sector_con .sector_con_body .list_con .overflowY60 {
  overflow-y: auto;
  padding-bottom: 60px;
}
.sector_con .sector_con_body .list_con_mobile > * {
  width: 100%;
}
.sector_con_noMobile {
  height: 100%;
  overflow: hidden;
}
