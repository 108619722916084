.tipsCon {
  height: 100%;
  padding: 16px;
}
.tipsCon .tipsTitleCon {
  font-size: 16px;
  line-height: 18px;
}
.tipsCon .tipsTitleCon img {
  margin: 0 11px 2px 0;
}
.tipsCon .tipsBtnCon {
  justify-content: flex-end;
}
.tipsCon .tipsBtnCon button {
  width: 105px;
  height: 40px;
  background: #393939;
  border-radius: 3px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
}
.tipsCon .tipsBtnCon :global(.ant-btn:focus) {
  background: #393939;
}
