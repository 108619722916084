#colorpickerdialog-bg {
  position: fixed;
  top: 111px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}
#colorpickerdialog {
  height: 320px;
  z-index: 3;
}
.dialogtitle {
  height: 26px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dddddd;
  font-size: 12px;
  text-align: center;
  display: none;
}
.dialogclosebutton {
  position: absolute;
  width: 29px;
  height: 29px;
  background: url(../../../../asserts/imgs/chart/dialogclosebutton.png);
  top: -14px;
  right: -14px;
  cursor: pointer;
}
div.colorselector {
  display: none;
  position: absolute;
  width: 21px;
  height: 21px;
  background-image: url(../../../../asserts/imgs/chart/colorselector.gif);
  top: 0;
  left: 0;
}
.colorinputbox {
  margin: 5px auto;
  text-align: center;
}
.colorinputbox input {
  margin-top: 5px;
  width: 80px;
  text-align: center;
  float: none;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(#767676, #858585);
}
