.list_con {
  height: 100%;
}
.list_con .history_con {
  height: 40px;
  min-height: 40px;
  line-height: 40px;
  font-weight: 500;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}
.list_con .table_common {
  padding: 0 16px;
  align-items: center;
}
.list_con .table_common > * {
  width: 22%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 6px;
}
.list_con .table_common > *:first-child {
  width: 38%;
  justify-content: flex-start;
  overflow: hidden;
}
.list_con .table_common > *:last-child {
  padding-right: 0;
}
.list_con .table_common_moile {
  padding-right: 8px;
}
.list_con .table_common_moile > *:last-child {
  width: 24px;
}
.list_con .table_header {
  height: 32px;
  min-height: 32px;
}
.list_con .table_header > * {
  width: 22%;
  height: 100%;
  font-size: 12px;
  line-height: 13px;
}
.list_con .table_header > * .quote_table_sort {
  margin-left: 3px;
}
.list_con .table_header .upDown_con {
  width: 24%;
  position: relative;
}
.list_con .table_header .upDown_con span {
  margin-right: 14px;
}
.list_con .table_header .upDown_con img {
  width: 14px;
  height: 15px;
  position: absolute;
  right: 4px;
}
.list_con .table_header .upDown_con .upDown_con_img0 {
  top: 4px;
}
.list_con .table_header .upDown_con .upDown_con_img1 {
  bottom: 4px;
}
.list_con .table_header .upDown_con .up_img_seat {
  position: absolute;
  top: 0;
  width: 32px;
  height: 16px;
  cursor: pointer;
}
.list_con .table_header .upDown_con .down_img_seat {
  position: absolute;
  bottom: 0;
  width: 32px;
  height: 16px;
  cursor: pointer;
}
.list_con .table_header_mobile > * {
  font-size: 13px;
  line-height: 15px;
}
.list_con .table_body .body_one {
  font-size: 12px;
  line-height: 14px;
  height: 56px;
  cursor: pointer;
  position: relative;
}
.list_con .table_body .body_one .list_name {
  font-weight: 700;
  font-size: 14px;
  margin-left: 3px;
}
.list_con .table_body .body_one .list_cur {
  color: #00D4F0;
  font-weight: 500;
  display: block;
  width: 100%;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list_con .table_body .body_one .list_turnover {
  font-weight: 500;
  text-align: right;
}
.list_con .table_body .body_one .list_chg {
  border-radius: 3px;
  min-height: 28px;
  min-width: 64px;
  font-weight: 700;
  padding: 4px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-end;
}
.list_con .table_body .body_one .more_con {
  position: absolute;
  right: 40px;
  top: 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  min-width: 137px;
  z-index: 99;
  background: #393939;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
.list_con .table_body .body_one .more_con .more_con_one {
  align-items: center;
  justify-content: flex-start;
  min-height: 48px;
  width: 100%;
  padding: 14px 16px;
}
.list_con .table_body .body_one .more_con .more_con_one img {
  margin-right: 8px;
}
.list_con .table_body .body_one .more_con .more_con_one:last-child {
  border: 0;
}
.list_con .table_body .body_one .more_con_light {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px #e0e0e0;
}
.list_con .table_body .body_one:last-child {
  border-bottom: 0;
}
.list_con_PC {
  height: 100%;
  overflow: hidden;
}
.footer_con {
  height: 40px;
  min-height: 40px;
  background: #222429;
  box-shadow: inset 0px 1px 0px #373737;
  border-top: 1px solid #373737;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  z-index: 999;
}
.footer_con .all_img {
  margin-right: 4px;
}
.footer_con > * {
  width: 50%;
  border-right: 1px solid #373737;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.footer_con > *:last-child {
  border: 0;
}
.footer_con_light {
  background: #FFFFFF;
  box-shadow: inset 0px 1px 0px #E0E0E0;
  border-top: 1px solid #E0E0E0;
}
.footer_con_light > * {
  border-right: 1px solid #E0E0E0;
}
@supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
  .footer_env_mobile {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
