.modalExplain .content {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 16px;
}
.modalExplain .content span:first-child {
  margin-right: 6px;
}
.modalExplain .btnCon {
  height: 88px;
  padding: 16px;
  justify-content: flex-end;
}
.modalExplain .btnCon button {
  width: 144px;
  height: 40px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
}
