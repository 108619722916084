.tab_con span {
  display: block;
  padding: 0 5px;
  height: 24px;
  border-radius: 3px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  margin-right: 8px;
  cursor: pointer;
}
.tab_con .active {
  background: #008FC7;
  color: #FFFFFF !important;
}
.margin8 {
  margin: 0 8px;
}
.tab_portfolio {
  overflow-y: hidden;
  overflow-x: auto;
}
.tab_portfolio span:last-child {
  margin-right: 0;
}
.tab_portfolio_mobile span {
  white-space: nowrap;
}
.tab_portfolio_mobile span:first-child {
  margin-left: 16px;
}
.tab_portfolio_mobile span:last-child {
  margin-right: 16px;
}
.tab_portfolio_mobile::-webkit-scrollbar {
  height: 6px;
}
.tab_con_sector {
  flex: 1 1;
  -webkit-flex: 1;
}
.tab_con_screener span {
  height: 22px;
  line-height: 22px;
  padding: 0 8px;
}
