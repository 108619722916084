.msg_con {
  padding: 15px 16px 13px 16px;
  font-size: 12px;
  line-height: 14px;
  justify-content: space-between;
  position: relative;
}
.msg_con .msg_con_left {
  overflow: hidden;
}
.msg_con .msg_con_left .msg_con_cur {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}
.msg_con .msg_con_left .msg_con_name {
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  margin: 5px 0 0 0;
  flex-wrap: wrap;
}
.msg_con .msg_con_left .msg_con_name span:first-child {
  margin-right: 10px;
}
.msg_con .msg_con_btncon .quote_bellicon,
.msg_con .msg_con_btncon .quote_startcon {
  border-radius: 3px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
.msg_con .msg_con_btncon .quote_imgbell {
  width: 21px;
  height: 24px;
}
.msg_con .msg_con_btncon button {
  width: 103px;
  background: #39C46E;
}
.msg_con .msg_con_btncon .msg_con_tradebtn {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #222429;
  box-shadow: inset 0 1px 0 rgba(130, 130, 130, 0.4);
  z-index: 999;
  width: 100%;
  height: 64px;
  line-height: 64px;
  text-align: center;
}
.msg_con .msg_con_btncon .msg_con_tradebtn_light {
  background: #FFFFFF;
  box-shadow: inset 0 1px 0 #E0E0E0;
}
.msg_con_turnover {
  align-items: flex-end;
}
.msg_con_turnover img {
  margin-right: 10px;
  margin-bottom: 4px;
}
.msg_con_turnover span {
  display: block;
}
.msg_con_turnover span:first-child {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-right: 12px;
  position: relative;
  top: 1px;
}
.msg_con_turnover span:nth-child(n+1) {
  position: relative;
  bottom: 4px;
}
.msg_con_turnover span:nth-child(4) {
  margin: 0 10px 0 12px;
}
.msg_con_turnover span:nth-child(-n+4):nth-child(n+3) {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  bottom: 0px;
}
.msg_con_turnover span:last-child {
  bottom: 1px;
}
