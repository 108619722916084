.slider_con {
  position: fixed;
  left: 0;
  z-index: 999;
}
.slider_con .list {
  height: 100%;
  overflow: hidden;
}
.slider_con .list li {
  padding: 0 !important;
  margin: 0 auto 22px auto;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  height: auto;
  cursor: pointer;
}
.slider_con .list li:first-child {
  margin-top: 10px;
}
.slider_con .list li span {
  display: block;
}
.slider_con .list li img {
  width: 24px;
  height: 24px;
  margin: 0 auto 4px auto;
}
.slider_con .list li::after {
  border-right: 0;
}
.slider_con_PC {
  height: 100vh;
  width: 72px;
  top: 89px;
}
.slider_con_min {
  width: 100%;
  height: 56px;
  min-height: 56px;
  position: static;
}
.slider_con_min .list {
  border-right: 0;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.slider_con_min .list li {
  margin: 0;
  font-size: 10px;
  line-height: 12px;
}
.slider_con_min .list li:first-child {
  margin-top: 0 !important;
  margin-left: 54px;
}
.slider_con_min .list li:last-child {
  margin-right: 54px;
}
.slider_con_moblie {
  width: 100%;
  height: 56px;
  min-height: 56px;
  position: static;
}
.slider_con_moblie .list {
  border-right: 0;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.slider_con_moblie .list li {
  margin: 0;
}
.slider_con_moblie .list li:first-child {
  margin-left: 16px;
  margin-top: 0 !important;
}
.slider_con_moblie .list li:last-child {
  margin-right: 16px;
}
@supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
  .slider_con {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
