.alerts_count_button.account_page {
  margin: 0 16px;
}
.alerts_count_button .img {
  cursor: pointer;
}
.alerts_count_button .img.img_hover:hover path {
  fill: #ffffff;
}
.alerts_count_button .img.img_hover.light:hover path {
  fill: #333333;
}
