.header_sticky {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 9;
}
.list_left_shadow {
  box-shadow: 7px -12px 8px rgba(0, 0, 0, 0.1);
}
.list_left_shadow_light {
  box-shadow: 0px 40px 40px rgba(224, 224, 224, 0.25);
}
.wPer37 {
  width: 37%;
}
.wPer63 {
  width: 63%;
}
.header_con {
  overflow: hidden;
  overflow-x: auto;
}
.header_con::-webkit-scrollbar {
  height: 0;
}
.header_con .list_left_header .left_header_one {
  align-items: center;
}
.header_con .listM_right_header {
  width: 20%;
  min-height: 32px;
  text-align: right;
  padding: 8px 0 8px 8px;
  align-items: center;
  justify-content: flex-end;
}
.header_con .list_headerW_4 {
  width: 24%;
}
.header_con .list_headerW_4:last-child {
  width: 28%;
}
.header_con .list_headerW_3 {
  width: calc(100% / 3);
}
.header_con .list_headerW_5 {
  width: 16%;
}
.header_con .list_headerW_5:nth-child(n+6) {
  width: 19%;
}
.header_con .list_headerW_5:last-child {
  width: 21%;
}
.header_con .list_headerW_6 {
  width: 15%;
}
.header_con .list_headerW_6:nth-child(n+6) {
  width: 25%;
}
.header_con .list_headerW_6:last-child {
  width: 30%;
}
.header_con .list_headerW_7 {
  width: 15%;
}
.header_con .list_headerW_7:nth-child(n+6) {
  width: calc(55% / 3);
}
.header_con .list_headerW_8 {
  width: 20%;
}
.header_con .list_headerW_8:nth-child(n+6) {
  width: 24%;
}
.header_con .list_headerW_8:last-child {
  width: 28%;
}
.header_con .list_header_tablet {
  width: 25%;
}
.header_con .list_header_tablet:nth-child(n+6) {
  width: 35%;
}
.header_con .list_header_tablet_0 {
  width: calc(100% / 3);
}
.header_con .list_headerW_tablet_1 {
  width: 23%;
}
.header_con .list_headerW_tablet_1:last-child {
  width: 31%;
}
.header_con .list_header_mobile {
  width: 30%;
}
.header_con .list_header_mobile:nth-child(n+6) {
  width: 45%;
}
.list_con {
  overflow-y: auto;
}
.list_con .list_con_xScroll {
  overflow-x: auto;
}
.list_con .list_con_xScroll::-webkit-scrollbar {
  height: 14px;
}
.list_con .list_tablet_xScroll::-webkit-scrollbar {
  height: 0;
}
.list_con .scrollbar_corner_dark::-webkit-scrollbar-corner {
  background-color: #222429;
}
.list_con .scrollbar_corner_light::-webkit-scrollbar-corner {
  background-color: #FFFFFF;
}
.list_con .list_left {
  z-index: 9;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}
.list_con .list_left .listM_left_body {
  padding: 8px 0;
  cursor: pointer;
}
.list_con .list_left .listM_left_body span {
  font-weight: 500;
}
.list_con .list_left .listM_left_body span:first-child {
  font-weight: 700;
}
.list_con .list_right .listM_right_body {
  min-height: 32px;
  cursor: pointer;
  align-items: center;
}
.list_con .list_right .listM_right_body span {
  text-align: right;
  width: 20%;
  padding-left: 8px;
  justify-content: flex-end;
}
.list_con .list_right .list_bodyW_4 span {
  width: 24%;
}
.list_con .list_right .list_bodyW_4 span:last-child {
  width: 28%;
}
.list_con .list_right .list_bodyW_3 span {
  width: calc(100% / 3);
}
.list_con .list_right .list_bodyW_5 span {
  width: 16%;
}
.list_con .list_right .list_bodyW_5 span:nth-child(n+4) {
  width: 19%;
}
.list_con .list_right .list_bodyW_5 span:last-child {
  width: 21%;
}
.list_con .list_right .list_bodyW_6 span {
  width: 15%;
}
.list_con .list_right .list_bodyW_6 span:nth-child(n+4) {
  width: 25%;
}
.list_con .list_right .list_bodyW_6 span:last-child {
  width: 30%;
}
.list_con .list_right .list_bodyW_7 span {
  width: 15%;
}
.list_con .list_right .list_bodyW_7 span:nth-child(n+4) {
  width: calc(55% / 3);
}
.list_con .list_right .list_bodyW_8 span {
  width: 20%;
}
.list_con .list_right .list_bodyW_8 span:nth-child(n+4) {
  width: 24%;
}
.list_con .list_right .list_bodyW_8 span:last-child {
  width: 28%;
}
.list_con .list_right .list_body_tablet span {
  width: 25%;
}
.list_con .list_right .list_body_tablet span:nth-child(n+4) {
  width: 35%;
}
.list_con .list_right .list_body_tablet_0 span {
  width: calc(100% / 3);
}
.list_con .list_right .list_bodyW_tablet_1 span {
  width: 23%;
}
.list_con .list_right .list_bodyW_tablet_1 span:last-child {
  width: 31%;
}
.list_con .list_right .list_body_mobile span {
  width: 30%;
}
.list_con .list_right .list_body_mobile span:nth-child(n+4) {
  width: 45%;
}
.list_con .list_more {
  bottom: 20px;
}
