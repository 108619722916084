.slidingPanel_con {
  position: fixed;
  top: 0;
  left: 0;
  width: 414px;
  height: 100%;
  z-index: 999999;
  transition: 0.3s ease-out;
}
.slidingPanel_con .line {
  background: linear-gradient(90deg, #0089D1 0%, #01B6AD 100%);
  height: 5px;
}
.slidingPanel_con .list {
  height: 48px;
  align-items: center;
  padding: 0 16px;
  font-weight: 500;
  cursor: pointer;
}
.slidingPanel_con .list img {
  margin-right: 8px;
}
.slidingPanel_bg {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.ease_in {
  transform: translateX(0%);
}
.ease_out {
  transform: translateX(-100%);
}
