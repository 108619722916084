.msg_con {
  position: relative;
}
.msg_con .title {
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}
.msg_con .num {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}
.msg_con .title_info_btn {
  padding: 2px 4px 4px 4px;
  color: #FFFFFF;
  background-color: #008fc7;
  cursor: pointer;
}
.msg_con .tooltip_con {
  align-items: center;
}
.msg_con .tooltip_con img {
  position: relative;
  left: 1px;
}
.msg_con .tooltip_con .tooltip_cur {
  font-size: 12px;
  line-height: 14px;
  margin: 0 2px 0 3px;
}
.msg_con .marginLeft24 {
  margin-left: 24px !important;
}
.msg_con .marginRight36 {
  margin-right: 36px;
}
.msg_con .marginRight24 {
  margin-right: 24px !important;
}
.msg_con .marginRight16 {
  margin-right: 16px !important;
}
.msg_con .min_width124 {
  min-width: 124px !important;
}
.msg_con .min_height24 {
  min-height: 24px !important;
}
.msg_con .min_height62 {
  min-height: 62px !important;
}
.msg_con .min_height70 {
  min-height: 70px !important;
}
.msg_con .min_height80 {
  min-height: 80px !important;
}
.msg_con .min_height100 {
  min-height: 100px !important;
}
.msg_con .width_per5 {
  width: calc(100% / 5);
}
.msg_con .width_per3 {
  width: calc(100% / 3);
}
.msg_con .msg_market img {
  width: 13px;
  height: 12px;
  cursor: pointer;
  position: relative;
  top: 1px;
}
.msg_con .msg_security {
  margin-bottom: 16px;
}
.msg_con .msg_buying .dropDown_img {
  width: 13px;
  height: 12px;
  cursor: pointer;
}
.msg_con .line {
  height: 80%;
  width: 1px;
  margin: 0 20px 0 41px;
}
.msg_con .dropsDown_con {
  min-height: 100px;
  max-height: 300px;
  position: absolute;
}
.msg_con .value_w {
  width: calc(100% / 4) !important;
}
.msg_con_mobileD .title {
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
}
.msg_con_mobileD .tooltip_cur {
  color: #FFFFFF;
}
.msg_con_mobileW .title {
  font-size: 16px;
  line-height: 18px;
  color: #333333;
}
.msg_con_mobileW .tooltip_cur {
  color: #333333;
}
