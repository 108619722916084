.right .right_header {
  align-items: center;
  height: 47px;
  min-height: 47px;
  font-size: 16px;
  font-weight: 700;
}
.right .right_header img {
  margin: 0 4px 0 8px;
}
.right .right_content {
  padding-bottom: 40px;
}
.right .right_content .title {
  display: block;
  margin-bottom: 16px;
}
.right .right_content .content {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 18px;
}
.right .right_content .time {
  color: #828282;
  display: block;
}
.right .right_content .table_con {
  align-items: center;
  min-height: 32px;
}
.right .right_content .table_con span {
  display: block;
  width: calc(100% / 4);
  padding: 10px 8px;
}
.right .right_content .table_con_1 span {
  width: calc(100% / 5);
}
.right .right_content .table_header {
  margin-top: 16px;
}
.right .right_content .table_header span {
  font-size: 10px;
  line-height: 12px;
}
.right .right_content .title,
.right .right_content .content,
.right .right_content .time,
.right .right_content .table_con,
.right .right_content .table_header {
  width: 80%;
  margin: 0 auto 16px auto;
}
.right .right_content .table_header {
  margin-bottom: 0;
}
