.price_con_fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}
.price_con_fixed .price_con {
  height: 193px;
  background: #222429;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 16px;
}
.price_con_fixed .price_con .price_title {
  align-items: center;
  font-size: 16px;
  line-height: 19px;
}
.price_con_fixed .price_con .price_title img {
  margin: 0 11px 2px 0;
}
.price_con_fixed .price_con .price_con_btn {
  justify-content: flex-end;
}
.price_con_fixed .price_con .price_con_btn button {
  width: 105px;
  height: 40px;
  background: #393939;
  border-radius: 3px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
}
.price_con_fixed .price_con .price_con_btn :global(.ant-btn:focus) {
  background: #393939;
}
.price_con_light {
  background: #FFFFFF !important;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05) !important;
}
