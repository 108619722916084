.setting_fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}
.setting_fixed .setting_con {
  border-radius: 3px;
  overflow: hidden;
}
.setting_fixed .setting_con .min48_con {
  min-height: 48px;
  padding: 0 16px;
}
.setting_fixed .setting_con .header {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
}
.setting_fixed .setting_con .body .title {
  display: block;
  min-height: 32px;
  line-height: 32px;
  padding: 0 16px;
  font-size: 12px;
}
.setting_fixed .setting_con .body .msg {
  color: #828282;
}
.setting_fixed .setting_con .body .msg :global(.ant-switch) {
  background-color: #828282;
  height: 22px;
  line-height: 22px;
  width: 44px;
  border-radius: 100px;
}
.setting_fixed .setting_con .body .msg :global(.ant-switch-checked) {
  background: #008FC7;
}
.setting_fixed .setting_con .body .line {
  height: 8px;
  width: 100%;
}
.setting_fixed .setting_con .footer {
  height: 72px;
  padding: 16px;
}
.setting_fixed .setting_con .footer button {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  height: 40px;
}
.setting_fixed .setting_con .footer button:first-child {
  margin-right: 8px;
  width: 50%;
}
.setting_fixed .setting_con .footer button:last-child {
  width: calc(50% - 8px);
}
