.header {
  position: relative;
}
.header .tabs_con {
  margin-bottom: 5px;
}
.header .tabs_con ::-webkit-scrollbar {
  display: none;
}
.header .header_line {
  height: 0.5px;
  width: 100%;
  margin-bottom: 11px;
  z-index: 99;
}
.header .header_line div {
  height: 100%;
  margin: 0 16px;
  background: #373737;
}
.header .header_line_light div {
  background: #E0E0E0;
}
.header .header_more {
  width: 100%;
  text-align: center;
  z-index: 8;
}
.header .header_more div {
  margin: 0 auto;
  width: 40px;
}
.header .header_more div .header_down {
  transform: rotate(-90deg);
  margin-bottom: 10px;
}
.header .header_more div .header_up {
  transform: rotate(90deg);
}
.header .header_more_up {
  position: absolute;
  bottom: 4px;
}
.header :global(.ant-carousel .slick-dots li) {
  width: 6px;
  height: 6px;
  margin: 0;
  margin-right: 8px;
}
.header :global(.ant-carousel .slick-dots li button) {
  width: 6px;
  height: 6px;
  line-height: 6px;
  border-radius: 50%;
  background: #E0E0E0;
  opacity: 1;
}
.header :global(.ant-carousel .slick-dots li.slick-active button) {
  width: 13px;
  background: #008FC7;
  border-radius: 5px;
}
.header :global(.ant-carousel .slick-dots li.slick-active) {
  width: 13px;
}
