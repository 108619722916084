.alerts_components {
  position: relative;
  height: 100%;
}
.alerts_components .name {
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  padding: 0 16px;
}
.alerts_components .name_mobile {
  padding: 0 8px;
  justify-content: flex-start;
}
.alerts_components .name_mobile span {
  padding-left: 6px;
}
.alerts_components .tradeflow_con {
  align-items: center;
  height: 48px;
  padding: 0 16px;
}
.alerts_components.mobile .tradeflow_con {
  padding: 0 8px;
}
