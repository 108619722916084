html,
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto' !important;
  color: #828282;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: normal;
  font-variant: normal;
  padding-top: constant(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  padding-bottom: constant(safe-area-inset-bottom);
}
html img,
body img {
  cursor: pointer;
}
html ul,
body ul {
  margin: 0;
  padding: 0;
}
html li,
body li {
  list-style: none;
  margin: 0;
  padding: 0;
}
:global(.ant-message) {
  z-index: 9999999999;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
  font-family: 'Roboto';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
:global(.ant-message-notice-content) {
  background: #222429;
}
:global(.ant-btn) {
  border: 0;
  color: #FFFFFF;
  background: none;
}
:global(.ant-btn:hover) {
  border: 0;
  color: #FFFFFF;
  background: none;
}
:global(.ant-btn:active) {
  border: 0;
  color: #FFFFFF;
  background: #828282;
}
:global(.ant-btn:focus) {
  border: 0;
  color: #FFFFFF;
  background: #828282;
}
:global(.ant-spin-spinning) {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
:global(.ant-spin-dot-item) {
  background: #008FC7;
}
:global(.ant-pagination .ant-pagination-prev) {
  margin-top: 0 !important;
}
:global(.ant-pagination) li {
  background-color: transparent !important;
  border: none !important;
  font-size: 12px;
}
:global(.ant-pagination) li * {
  border: none !important;
  color: #828282 !important;
}
:global(.ant-pagination) li button {
  background-color: transparent !important;
}
:global(.ant-pagination) li:global(.ant-pagination-item) {
  min-width: 32px;
  height: 24px;
  line-height: 24px;
}
:global(.ant-pagination) li:global(.ant-pagination-item-active) {
  background-color: #008FC7 !important;
}
:global(.ant-pagination) li:global(.ant-pagination-item-active) * {
  color: #FFFFFF !important;
}
