.stock_report_plus {
  padding-bottom: 40px;
}
.stock_report_plus .section {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
}
.stock_report_plus .section:first-child {
  border-bottom: 1px solid #373737;
}
.stock_report_plus .section > div {
  width: 50%;
  min-height: 272px;
}
.stock_report_plus .section > div:first-child {
  border-right: 1px solid #373737;
}
.stock_report_plus .section > div:nth-child(2) {
  padding-left: 16px;
}
.stock_report_plus .peer_comparison {
  position: relative;
}
.stock_report_plus .peer_comparison .peer_comparison_legend {
  position: absolute;
  top: 2px;
  right: 0;
  display: flex;
  color: #E0E0E0;
  font-size: 12px;
}
.stock_report_plus .peer_comparison .peer_comparison_legend .item_wrapper {
  display: flex;
  align-items: center;
}
.stock_report_plus .peer_comparison .peer_comparison_legend .item_wrapper:first-child {
  margin-right: 16px;
}
.stock_report_plus .peer_comparison .peer_comparison_legend .item_wrapper .legend_rect {
  margin-right: 8px;
  width: 6px;
  height: 6px;
  background-color: #afadad;
}
.stock_report_plus .peer_comparison .peer_comparison_legend .item_wrapper .legend_rect.ric {
  background-color: #008fc7;
}
.stock_report_plus .analyst_price_target {
  position: relative;
}
.stock_report_plus .analyst_price_target .data_section {
  display: flex;
  align-items: baseline;
  position: absolute;
  left: 0;
  top: 32px;
}
.stock_report_plus .analyst_price_target .data_section > * {
  margin-right: 12px;
}
.stock_report_plus .analyst_price_target .data_section .data_section_price {
  font-size: 24px;
  font-weight: bold;
}
.stock_report_plus .analyst_price_target .data_section .data_section_netchange {
  font-size: 16px;
  font-weight: 700;
}
.stock_report_plus .stock_report_plus_header {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}
.stock_report_plus .stock_report_plus_content.flex {
  display: flex;
}
.stock_report_plus .stock_report_plus_content.flex .left {
  width: 35%;
}
.stock_report_plus .stock_report_plus_content.flex .left .rect {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  width: 127px;
  height: 112px;
  background-color: #008fc7;
}
.stock_report_plus .stock_report_plus_content.flex .left .rect .number {
  display: block;
  font-size: 40px;
  text-align: center;
  color: #ffffff;
}
.stock_report_plus .stock_report_plus_content.flex .left .rect .number_h {
  height: 36px;
  line-height: 36px;
}
.stock_report_plus .stock_report_plus_content.flex .left .rect .rect_text {
  display: block;
  padding: 0 30px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  word-break: break-word;
  color: #ffffff;
}
.stock_report_plus .stock_report_plus_content.flex .right {
  width: 65%;
}
.stock_report_plus .stock_report_plus_content .text {
  padding-top: 16px;
  padding-bottom: 20px;
}
.stock_report_plus .stock_report_plus_content .text .text_red {
  color: #f5475b;
  font-weight: 700;
}
.stock_report_plus .noDatas {
  text-align: center;
  min-height: 200px;
}
.stock_report_plus.light .section:first-child {
  border-bottom: 1px solid #e0e0e0;
}
.stock_report_plus.light .section > div:first-child {
  border-right: 1px solid #e0e0e0;
}
.stock_report_plus.light .stock_report_plus_header {
  color: #333333;
}
.stock_report_plus.light .data_section_price {
  color: #333333 !important;
}
.stock_report_plus .disclaimer_con {
  font-size: 12px;
  line-height: 14px;
  padding: 0 16px;
}
.stock_report_plus.tablet .section {
  display: block;
  margin: 16px;
  padding: 0;
}
.stock_report_plus.tablet .section > div {
  width: 100%;
}
.stock_report_plus.tablet .section > div:first-child {
  border-right: none;
  border-bottom: 1px solid #373737;
}
.stock_report_plus.tablet .section > div:nth-child(2) {
  padding-left: 0;
  padding-top: 16px;
}
.stock_report_plus.tablet .peer_comparison .peer_comparison_legend {
  top: 16px;
}
.stock_report_plus.tablet.light .section > div:first-child {
  border-bottom: 1px solid #e0e0e0;
}
.stock_report_plus.mobile .company_score .stock_report_plus_content {
  display: block;
  position: relative;
}
.stock_report_plus.mobile .company_score .stock_report_plus_content .left {
  padding-bottom: 16px;
  border-bottom: 1px solid #373737;
  margin-bottom: 16px;
  width: 100%;
}
.stock_report_plus.mobile .company_score .stock_report_plus_content .left .rect {
  width: 100%;
}
.stock_report_plus.mobile .company_score .stock_report_plus_content .right {
  width: 100%;
}
.stock_report_plus.mobile.light .company_score .stock_report_plus_content .left {
  border-bottom: 1px solid #e0e0e0;
}
