.flex_row {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
}
.flex_row1 {
  display: flex !important;
  display: -webkit-flex !important;
  flex-direction: row !important;
}
.flex_column {
  display: flex;
  display: -webkit-flex;
  flex-direction: column !important;
}
.flex_topcenter {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
.WHPer100 {
  width: 100%;
  height: 100%;
}
.flex_center {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}
.flex_between {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
.img12 {
  width: 12px;
  min-width: 12px;
  height: 12px;
}
.img14 {
  width: 14px;
  min-width: 14px;
  height: 14px;
}
.img16 {
  width: 16px;
  min-width: 16px;
  height: 16px;
}
.img18 {
  width: 18px;
  min-width: 18px;
  height: 18px;
}
.img20 {
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.img24 {
  width: 24px;
  min-width: 24px;
  height: 24px;
}
.fw700 {
  font-weight: 700 !important;
}
.fw500 {
  font-weight: 500 !important;
}
.fz10 {
  font-size: 10px !important;
}
.fz11 {
  font-size: 11px !important;
}
.fz12 {
  font-size: 12px !important;
}
.fz13 {
  font-size: 13px !important;
}
.fz14 {
  font-size: 14px !important;
}
.fz16 {
  font-size: 16px !important;
}
.fz17 {
  font-size: 17px !important;
}
.fz18 {
  font-size: 18px !important;
}
.fz20 {
  font-size: 20px !important;
}
.marginBottom2 {
  margin-bottom: 2px;
}
.transformY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.transformX {
  position: absolute;
  left: 50% !important;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.isLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  z-index: 999;
  color: #828282 !important;
}
.noData {
  text-align: center;
  color: #828282;
  margin: 60px auto;
}
pre {
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
}
.btn_blue:hover {
  background: #006F9B !important;
}
.btn_red:hover {
  background: #C93748 !important;
}
.btn_green:hover {
  background: #2B9F57 !important;
}
.btn_black:hover {
  background: #1B1B1B !important;
}
.btn_gray:hover {
  background: #626262 !important;
}
.btn_disabled {
  background: #373737 !important;
}
.btn_disabled:hover {
  background: #373737 !important;
}
.star_black_hover:hover {
  background: #2C2C2C !important;
}
.star_green_hover:hover {
  background: #018281 !important;
}
.btn_hover_light:hover {
  background: #F3F3F3 !important;
}
.bg_star_on {
  background: #009E9C !important;
}
.color_009E9C {
  color: #009E9C !important;
}
.bg_star_off {
  background: #333333 !important;
}
.bg_star_light {
  border: 1px solid #E0E0E0;
}
.bg_event_black {
  background: #1B1B1B !important;
}
.bg_blue {
  background: #008FC7 !important;
}
.bg_red {
  background: #F5475B !important;
}
.bg_red span {
  color: #FFFFFF !important;
}
.bg_green {
  background: #39C46E !important;
}
.bg_green span {
  color: #FFFFFF !important;
}
.bg_black {
  background: #393939 !important;
}
.bg_grey {
  background: #828282 !important;
}
.bg_page_black {
  background: #222429 !important;
}
.color_red {
  color: #F5475B !important;
}
.color_green {
  color: #39C46E !important;
}
.color_black {
  color: #393939 !important;
}
.color_theme_black {
  color: #333333 !important;
}
.color_white {
  color: #FFFFFF !important;
}
.color_blue {
  color: #008FC7 !important;
}
.color_grey {
  color: #828282 !important;
}
.color_grey1 {
  color: #E0E0E0 !important;
}
.color_cur {
  color: #00D4F0 !important;
}
.color_AFADAD {
  color: #AFADAD !important;
}
.color_56CCF2 {
  color: #56CCF2 !important;
}
.borderBottom_56CCF2_hover:hover {
  border-bottom: 1px solid #56CCF2 !important;
}
.borderBottom_008FC7:hover {
  border-bottom: 1px solid #008FC7 !important;
}
.bg_light {
  background: #FFFFFF !important;
}
.bg_chart_light {
  background: #E0E0E0 !important;
}
.bg_dark {
  background: #000000 !important;
}
.bg_dark_hover:hover {
  background: #000000 !important;
}
.bg_1b1b1b {
  background: #1b1b1b !important;
}
.bg_1b1b1b_hover:hover {
  background: #1b1b1b !important;
}
.bg_content_dark {
  background: #222429 !important;
}
.bg_list_light {
  background: #F3F3F3 !important;
}
.bg_event_light:hover {
  background: #F3F3F3 !important;
}
.bg_C8C7C7_hover:hover {
  background: #C8C7C7 !important;
}
.line_dark {
  background: #161616 !important;
}
.bg_backTop_black {
  background: #373737 !important;
}
.inputcon_dark {
  background: #393939 !important;
}
.inputcon_light {
  background: #E0E0E0 !important;
}
.dropDown_black {
  background: #373737 !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25) !important;
}
.dropDown_light {
  background: #FFFFFF !important;
  box-shadow: 2px 2px 8px #e0e0e0 !important;
}
.border_dark {
  border: 1px solid #373737 !important;
}
.border_white {
  border: 1px solid #FFFFFF !important;
}
.border_light {
  border: 1px solid #E0E0E0 !important;
}
.box_shadow_black {
  box-shadow: inset 0px 1px 0px #373737 !important;
}
.box_shadow_light {
  box-shadow: inset 0px 1px 0px #e0e0e0 !important;
}
.borderRight_light {
  border-right: 1px solid #E0E0E0 !important;
}
.borderRight_dark {
  border-right: 1px solid #373737 !important;
}
.borderBottom_light {
  border-bottom: 1px solid #E0E0E0 !important;
}
.borderBottom_dark {
  border-bottom: 1px solid #373737 !important;
}
.borderTop_light {
  border-top: 1px solid #E0E0E0 !important;
}
.borderTop_dark {
  border-top: 1px solid #373737 !important;
}
.borderLeft_light {
  border-left: 1px solid #E0E0E0 !important;
}
.borderLeft_dark {
  border-left: 1px solid #373737 !important;
}
.borderBottom_red_hover:hover {
  border-bottom: 1px solid #F5475B !important;
}
.borderBottom_green_hover:hover {
  border-bottom: 1px solid #39C46E !important;
}
.borderBottom_828282 {
  border-bottom: 1px solid #828282 !important;
}
.borderTop_828282 {
  border-top: 1px solid #828282 !important;
}
.box_shadow_e0e0e0 {
  box-shadow: 2px 2px 8px #e0e0e0 !important;
}
.calendar_light {
  color: #333333 !important;
  background: #E0E0E0 !important;
}
.calendar_dark {
  color: #FFFFFF !important;
  background: #373737 !important;
}
.reset_light span {
  color: #009E9C !important;
  border-bottom: 1px solid #009E9C !important;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ellipsis_more2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.paddingBottom20 {
  padding-bottom: 20px !important;
}
.paddingBottom30 {
  padding-bottom: 30px !important;
}
.paddingBottom40 {
  padding-bottom: 40px !important;
}
.paddingBottom60 {
  padding-bottom: 60px !important;
}
.paddingBottom80 {
  padding-bottom: 80px !important;
}
.paddingBottom100 {
  padding-bottom: 100px !important;
}
.overflowYScroll {
  overflow-x: hidden;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
}
.overflowYHidden {
  overflow-y: hidden !important;
}
.bgRgba_black {
  background: rgba(0, 0, 0, 0.7);
}
.heightper {
  height: 100% !important;
}
.heightvh {
  height: 100vh !important;
}
.minH300 {
  min-height: 300px;
}
.minH400 {
  min-height: 400px;
}
.margin0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.fz12_zhcn > * {
  font-size: 12px !important;
}
.rotate_img {
  transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
}
.highcharts-container {
  width: 100%;
  height: 100%;
}
.tooltip_con_custurm {
  z-index: 999999999999;
}
.tooltip_con_custurm div *:first-child {
  background: none;
}
.tooltip_con_custurm div *:first-child span {
  background: #373737;
  padding: 0;
}
.tooltip_con_custurm div *:last-child {
  color: #E0E0E0;
  padding: 8px 12px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  background: #373737;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}
:global(.ant-tooltip-arrow-content) {
  width: 11px;
  height: 11px;
}
.tooltip_con_custurm_light {
  z-index: 999999999999;
}
.tooltip_con_custurm_light div *:first-child {
  background: none;
}
.tooltip_con_custurm_light div *:first-child span {
  background: #FFFFFF;
  padding: 0;
}
.tooltip_con_custurm_light div *:last-child {
  color: #333333;
  padding: 8px 12px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px #e0e0e0;
}
.tooltipcon_custurm_left {
  left: 10px !important;
}
.tooltipcon_custurm_right {
  right: 16px !important;
  left: unset !important;
}
.isChecked_mobile {
  transform: translateX(0%);
  transition: 0.3s ease-out;
}
.isnoCheck_mobile {
  transform: translateX(100%);
}
.app_layout {
  height: 100vh;
  /*整个滚动条*/
  /*定义滑块*/
}
.app_layout ::-webkit-scrollbar {
  width: 9px;
}
.app_layout ::-webkit-scrollbar-thumb {
  background-color: #373737;
  border-radius: 100px;
}
.app_layout button {
  padding: 0;
  margin: 0;
  outline: none;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 12px;
  border-radius: 3px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
}
.app_layout button span {
  color: #FFFFFF;
}
.app_layout input {
  flex: 1 1;
  -webkit-flex: 1;
  background: none;
  outline: none;
  border: 0;
  color: #FFFFFF;
  padding: 0 6px;
  margin: 0;
  font-weight: 400;
}
.app_layout input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.app_layout input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.app_layout input[type='number'] {
  -moz-appearance: textfield;
}
.app_layout --antd-wave-shadow-color {
  color: '';
}
.app_layout input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #828282 inset;
}
.app_layout input:-internal-autofill-selected {
  background-color: #828282 !important;
  background: transparent;
}
.app_layout_light {
  /*定义滑块*/
}
.app_layout_light ::-webkit-scrollbar-thumb {
  background-color: #828282 !important;
}
.app_layout_back {
  /*定义滑块*/
}
.app_layout_back ::-webkit-scrollbar-thumb {
  background-color: #000000 !important;
}
.dropDown_con_light {
  background: #FFFFFF !important;
  box-shadow: 2px 2px 8px #E0E0E0 !important;
}
.dropDown_con_light::-webkit-scrollbar-thumb {
  background-color: #828282 !important;
}
.sticky_top_mobile {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0;
  left: 0 !important;
  z-index: 999;
  width: 100%;
}
.sticky_bottom_mobile {
  position: sticky !important;
  position: -webkit-sticky !important;
  bottom: 0;
  left: 0 !important;
  z-index: 999;
  width: 100%;
}
.flex1_auto {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1;
  -webkit-flex: 1;
  /*-webkit-overflow-scrolling: touch;*/
}
.flex_x_auto {
  overflow-x: auto;
  overflow-y: hidden;
  flex: 1 1;
  -webkit-flex: 1;
  -webkit-overflow-scrolling: touch;
}
.flex1_hidden {
  flex: 1 1;
  -webkit-flex: 1;
  overflow: hidden;
}
.flex_1 {
  flex: 1 1;
  -webkit-flex: 1;
}
.marginLeft0 {
  margin-left: 0 !important;
}
.marginRight0 {
  margin-right: 0 !important;
}
.marginLeft72 {
  margin-left: 72px !important;
}
.border0 {
  border: 0 !important;
}
.padding0 {
  padding: 0 !important;
}
.wPer30 {
  width: 30% !important;
}
.wPer35 {
  width: 35% !important;
}
.wCalc {
  width: calc((100% - 384px) / 2) !important;
}
.wPer40 {
  width: 40% !important;
}
.wPer50 {
  width: 50% !important;
}
.wPer60 {
  width: 60% !important;
}
.wPer65 {
  width: 65% !important;
}
.wPer70 {
  width: 70% !important;
}
.wPer80 {
  width: 80% !important;
}
.wPer100 {
  width: 100% !important;
}
.HPer100 {
  height: 100% !important;
}
.w384 {
  width: 384px !important;
}
.margin0 {
  margin: 0 !important;
}
.marginauto {
  margin: 0 auto !important;
}
.lineHeight20 {
  line-height: 20px !important;
}
.refresh_img {
  animation: rotation 1s ease-out 1;
  -ms-animation: rotation 1s ease-out 1;
  -webkit-animation: rotation 1s ease-out 1;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
.alert_con {
  position: relative;
  margin-right: 22px;
}
.alert_con .nums {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #F5475B;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  position: absolute;
  left: 15px;
  top: -3px;
}
.alert_con .nums_rect {
  padding: 0 3px;
  height: 16px;
  border-radius: 5px;
  background: #F5475B;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  position: absolute;
  left: 15px;
  top: -3px;
}
.display_none {
  display: none !important;
}
.place_button {
  padding: 24px 16px 16px 16px;
  height: 80px;
  min-height: 80px;
}
.place_button button {
  width: 50%;
  height: 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-right: 8px;
  background: #828282;
}
.place_button button:last-child {
  width: calc(50% - 8px);
  margin-right: 0;
}
.place_button_alert {
  padding: 16px;
  height: auto;
  min-height: auto;
}
.position_fixed {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
  top: 0 !important;
  z-index: 9999999 !important;
  -webkit-overflow-scrolling: touch;
}
.loading_more {
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  padding: 16px 0;
  color: #828282 !important;
}
.mask_con_fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}
.mask_con_fixed .mask_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.upDown_con {
  position: relative;
  height: 20px;
}
.upDown_con img {
  width: 15px;
  height: 14px;
  position: relative;
}
.upDown_con .img_dropdown_up {
  position: absolute;
  left: -1px;
  top: 0;
  width: 20px;
  height: 10px;
  text-align: left;
  cursor: pointer;
}
.upDown_con .img_dropdown_up img {
  bottom: 1px;
}
.upDown_con .img_dropdown_down {
  position: absolute;
  left: -1px;
  bottom: 0;
  width: 20px;
  height: 10px;
  text-align: left;
  cursor: pointer;
}
.upDown_con .img_dropdown_down img {
  bottom: 3px;
}
.hover_icon {
  cursor: pointer;
}
.hover_icon:hover path {
  fill: #ffffff;
}
.hover_icon_light {
  cursor: pointer;
}
.hover_icon_light path {
  fill: #828282;
}
.hover_icon_light:hover path {
  fill: #484848;
}
.page_absolute_sticky {
  position: fixed;
  top: 48px;
  left: 0;
  z-index: 99;
}
.hPer_overflowH {
  height: 100%;
  overflow: hidden;
}
.minHeight300 {
  min-height: 300px;
}
.img_shadow {
  height: 48px;
  line-height: 48px;
  box-shadow: 8px 0px 38px rgba(0, 0, 0, 0.6);
}
.img_shadow_light {
  box-shadow: 8px 0px 38px rgba(0, 0, 0, 0.15);
}
.touch {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}
.fontSize13_colorWhite {
  font-size: 13px !important;
  color: #FFFFFF !important;
}
.fontSize13_colorWhite span {
  color: #FFFFFF !important;
  font-size: 13px !important;
}
.fontSize16_colorWhite {
  font-size: 16px !important;
  color: #FFFFFF !important;
}
.fontSize16_colorWhite span {
  color: #FFFFFF !important;
  font-size: 16px !important;
}
.fontSize13_colorDark {
  font-size: 13px !important;
  color: #333333 !important;
}
.fontSize13_colorDark span {
  color: #333333 !important;
  font-size: 13px !important;
}
.fontSize16_colorDark {
  font-size: 16px !important;
  color: #333333 !important;
}
.fontSize16_colorDark span {
  color: #333333 !important;
  font-size: 16px !important;
}
.bg_chart5_ohlc_black {
  background: #000000;
  color: #ffffff;
}
.bg_chart5_ohlc_white {
  background: #ffffff;
  color: #000000;
}
