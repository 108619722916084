.trade_layout {
  height: 100%;
  position: relative;
}
.trade_layout .spanW {
  width: 95px;
  font-size: 16px;
  font-weight: 500;
}
.trade_layout .img_help {
  position: relative;
  left: 2px;
  bottom: 3px;
}
.trade_layout .tradeflow_con {
  align-items: center;
  height: 48px;
  padding: 0 16px;
}
.trade_layout .line {
  height: 8px;
  width: 100%;
}
.trade_layout .trade_tips {
  padding: 8px 16px;
  min-height: 37px;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}
.trade_layout .typebutton_active {
  background: #E0E0E0 !important;
}
.trade_layout .typebutton_active span {
  color: #000000 !important;
}
.trade_layout .direction_right_light button {
  border: 1px solid #E0E0E0;
}
.trade_layout .direction_right_light button span {
  color: #000000 !important;
}
.trade_layout .direction_right_light button[disabled] {
  background: none !important;
}
.trade_layout .name {
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  padding: 0 16px;
}
.trade_layout .name_mobile {
  padding: 0 8px;
}
.trade_layout .name_mobile span {
  padding-left: 6px;
}
.trade_layout .accountNumber {
  position: relative;
}
.trade_layout .accountNumber span {
  white-space: nowrap;
}
.trade_layout .accountNumber span:last-child {
  display: block;
  flex: 1 1;
  -webkit-flex: 1;
  font-size: 16px;
}
.trade_layout .buyingPower .buySelected {
  flex: 1 1;
  -webkit-flex: 1;
  height: 100%;
  position: relative;
}
.trade_layout .buyingPower .buySelected .dropsDown_con {
  position: absolute;
  right: 0;
  top: 48px;
  width: 100%;
}
.trade_layout .buyingPower .buySelected .buySelected_right {
  flex: 1 1;
  -webkit-flex: 1;
  height: 100%;
  align-items: center;
}
.trade_layout .buyingPower .buySelected .buySelected_right .buySelected_right_name span {
  font-size: 16px;
  line-height: 18px;
  opacity: 0.5;
}
.trade_layout .buyingPower .buySelected .buySelected_right .buySelected_right_name1 {
  background-color: #393939;
  padding: 4px;
}
.trade_layout .buyingPower .buySelected .buySelected_right .buySelected_right_name1 label {
  color: #828282;
}
.trade_layout .buyingPower .buySelected .buySelected_right .buySelected_right_symbol {
  align-items: center;
}
.trade_layout .buyingPower .buySelected .buySelected_right .buySelected_right_symbol span:first-child {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-right: 6px;
}
.trade_layout .buyingPower .buySelected .buySelected_right .buySelected_right_symbol span:nth-child(2) {
  position: relative;
  top: 1px;
}
.trade_layout .buyingPower .buySelected .buySelected_right .Delivery {
  color: #00D4F0;
  border: 1px solid #00D4F0;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 10px;
  line-height: 12px;
  margin-left: 6px;
}
.trade_layout .buyingPower .buySelected .entername {
  width: 100%;
  height: 100%;
  cursor: default;
}
.trade_layout .buyingPower .buySelected .entername input {
  flex: unset;
  font-size: 14px;
  line-height: 16px;
  height: 32px;
  padding: 0 8px;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 500;
  width: 42%;
}
.trade_layout .buyingPower .buySelected .entername input::placeholder {
  color: #828282;
  opacity: 0.5;
}
.trade_layout .buyingPower .buySelected .entername .getNamecon {
  align-items: center;
  flex: 1 1;
  -webkit-flex: 1;
}
.trade_layout .buyingPower .buySelected .entername .getNamecon span {
  font-weight: 700;
}
.trade_layout .buyingPower .buySelected .entername .getNamecon span:first-child {
  margin-right: 10px;
  min-height: 32px;
  border-radius: 3px;
  min-width: 80px;
  padding: 0 8px;
}
.trade_layout .buyingPower .buySelected .entername .getNamecon span:last-child {
  color: #00D4F0;
  font-weight: 400;
  font-size: 18px;
}
.trade_layout .buyingPower .buySelected .entername_light input {
  color: #333333;
}
.trade_layout .buyingPower .img_dropDown {
  height: 14px;
  width: 15px;
}
.trade_layout .quote_con .quote_con_turnover {
  flex: 1 1;
  -webkit-flex: 1;
  align-items: flex-end;
}
.trade_layout .quote_con .quote_con_turnover img {
  margin: 0 10px;
  margin-bottom: 2px;
}
.trade_layout .quote_con .quote_con_turnover span {
  position: relative;
  bottom: -1px;
}
.trade_layout .quote_con .quote_con_turnover span:first-child {
  font-size: 18px;
  line-height: 21px;
  position: relative;
  top: 2px;
}
.trade_layout .quote_con .quote_con_turnover span:nth-child(-n+4):nth-child(n+2) {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.trade_layout .direction .direction_right button {
  width: 50%;
  margin-right: 2px;
  border-radius: 3px;
  font-weight: 400;
  font-size: 24px;
}
.trade_layout .direction .direction_right button:last-child {
  width: calc(50% - 2px);
  margin-right: 0;
}
.trade_layout .direction .direction_right button span {
  height: 100%;
  color: #828282;
}
.trade_layout .direction .direction_right .direction_right_bg {
  padding: 2px;
  border-radius: 3px;
}
.trade_layout .position {
  height: 32px;
  justify-content: flex-start;
}
.trade_layout .amount {
  justify-content: flex-start;
}
.trade_layout .amount .amountNum {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}
.trade_layout .trade_choicecon {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
}
.trade_layout .trade_choicecon .img_dropDown {
  height: 14px;
  width: 15px;
}
.trade_layout .market_con {
  cursor: pointer;
  min-height: 32px;
  border-radius: 3px;
  width: 100%;
  padding: 0 8px;
  font-weight: 700;
  font-size: 18px;
}
.trade_layout .market_con .img_dropDown {
  height: 14px;
  width: 15px;
}
.trade_layout .curreny_con .curreny_span1 {
  min-height: 32px;
  border-radius: 3px;
  width: 25%;
  background: rgba(0, 0, 0, 0.3);
  margin-right: 8px;
  font-weight: 700;
  font-size: 20px;
}
.trade_layout .curreny_con .curreny_span3 {
  font-size: 20px;
  font-weight: 700;
  margin-left: 10px;
}
.trade_layout .linear_gradient_bg {
  background: linear-gradient(#b6b6b6, rgba(255, 255, 255, 0.99));
}
.trade_layout .trade_btncon {
  min-height: 72px;
}
.trade_layout .trade_btncon .trade_btncon_child {
  min-width: 292px;
  margin: 0 auto;
  position: relative;
}
.trade_layout .trade_btncon .trade_btncon_child .buyingPower {
  background: #484848;
  min-height: 40px;
  min-width: 142px;
  margin-right: 8px;
  border-radius: 3px;
  padding: 10px;
  font-weight: 700;
}
.trade_layout .trade_btncon .trade_btncon_child .buyingPower span:first-child {
  font-size: 16px !important;
}
.trade_layout .trade_btncon .trade_btncon_child button {
  background: #484848;
  width: 142px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 700;
}
.trade_layout .color_buysell span {
  color: #FFFFFF !important;
}
.trade_layout .bg_buy {
  background: #6C0903 !important;
}
.trade_layout .bg_sell {
  background: #113D06 !important;
}
.trade_layout .linear_gradient_buy {
  background: linear-gradient(#ff9899, #fe2a1c) !important;
}
.trade_layout .linear_gradient_sell {
  background: linear-gradient(#afcab7, #214c2b) !important;
}
.trade_layout_minPC {
  color: #FFFFFF;
}
.trade_layout_minPC .buyingPower .buySelected .entername input {
  max-width: 88px;
}
.trade_layout_minPC .direction .direction_right button {
  font-weight: 700;
  width: 50%;
}
.trade_layout_minPC .direction .direction_right button:last-child {
  width: calc(50% - 2px);
}
.trade_layout_minPC .curreny_con .curreny_span1 {
  min-width: 80px;
}
.trade_layout_mobile {
  font-size: 16px;
}
.trade_layout_mobile .buyingPower {
  position: relative;
}
.trade_layout_mobile .buyingPower .buySelected .buySelected_right .buySelected_right_name span {
  font-size: 16px;
  line-height: 18px;
}
.trade_layout_mobile .buyingPower .buySelected .buySelected_right .buySelected_right_symbol:first-child {
  font-size: 20px;
  line-height: 22px;
}
.trade_layout_mobile .buyingPower .buySelected .entername input {
  font-size: 16px;
  line-height: 18px;
}
.trade_layout_mobile .buyingPower .buySelected .entername .getNamecon span:nth-child(2) {
  margin: 0 10px 0 5px;
}
.trade_layout_mobile .buyingPower .buySelected_name {
  position: static;
}
.trade_layout_mobile .buyingPower .buySelected_name .dropsDown_con {
  width: 70%;
  right: 16px;
}
.trade_layout_mobile .quote_con .quote_con_turnover span:first-child {
  font-size: 20px;
  line-height: 22px;
}
.trade_layout_mobile .quote_con .quote_con_turnover span:nth-child(-n+4):nth-child(n+2) {
  font-size: 16px;
  line-height: 18px;
}
.trade_layout_mobile .direction .direction_right button span {
  font-size: 16px;
}
.trade_layout_mobile .amount .amountNum {
  font-size: 20px;
  line-height: 22px;
}
.trade_layout_mobileD .spanW {
  color: #FFFFFF;
}
.trade_layout_mobileD .direction_right_mobile button span {
  font-size: 18px !important;
}
.trade_layout_mobileW .spanW {
  color: #333333;
}
.trade_layout_mobileW .direction_right_mobile button span {
  font-size: 18px !important;
}
.direction_mobile {
  height: 58px !important;
}
.direction_mobile .direction_right button {
  height: 40px;
  line-height: 40px;
}
.direction_mobile .direction_right button span {
  font-size: 24px !important;
}
.trade_layout_hasBuySelll .spanW {
  color: #FFFFFF;
}
.trade_order_light span {
  color: #333333 !important;
}
