.TechnicalChart {
  /*background: #ffffff;*/
  /*border: 1px solid #dddddd;*/
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 16px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /*
  min-width: 850px;
  min-height: 600px;
  */
}
.TechnicalChart.zoom {
  position: fixed;
  z-index: 999;
  top: 138px;
  bottom: 0;
  left: 71px;
  padding: 0 12px 16px;
  min-height: 300px;
}
.TechnicalChart.zoom.tablet {
  top: 0;
  bottom: 0;
  left: 0;
  transform: rotate(90deg) translateY(-100%);
  transform-origin: top left;
  height: 100vw;
  width: 100vh;
}
.TechnicalChart.zoom.mobile {
  padding-top: 15px;
  top: 0;
  bottom: 0;
  transform: rotate(90deg) translateY(-100%);
  transform-origin: top left;
  height: 100vw;
  width: 100vh;
}
.TechnicalChart .headerContainer {
  display: flex;
  justify-content: space-between;
  height: 52px;
  min-height: 52px;
  max-height: 52px;
  box-shadow: inset 0px -2px 0px #e6003e, inset 0px 1px 0px #000000;
  align-items: center;
}
.TechnicalChart .headerContainer .leftContainer {
  display: flex;
}
.TechnicalChart .headerContainer .leftContainer .dropdown {
  width: 159px;
  position: relative;
}
.TechnicalChart .headerContainer .leftContainer .dropdown .Dropdown {
  height: 30px;
}
.TechnicalChart .headerContainer .leftContainer .darkDropdown {
  width: 159px;
  position: relative;
}
.TechnicalChart .headerContainer .leftContainer .darkDropdown .DropdownDarkMode,
.TechnicalChart .headerContainer .leftContainer .darkDropdown .DarkModeDropdown {
  height: 30px;
  color: #ffffff;
}
.TechnicalChart .headerContainer .leftContainer .darkDropdown .DropdownDarkMode option,
.TechnicalChart .headerContainer .leftContainer .darkDropdown .DarkModeDropdown option {
  background: "#000000";
}
.TechnicalChart .headerContainer .rightContainer {
  display: flex;
}
.TechnicalChart .headerContainer .rightContainer .label {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  text-align: center;
  color: #000000;
  margin: auto;
  margin-left: 10px;
  height: 14px;
}
.TechnicalChart .headerContainer .rightContainer .divider {
  border-left: 1px solid #cecece;
  margin-right: 5px;
  margin-left: 10px;
  height: 24px;
  top: 5px;
  position: relative;
}
.TechnicalChart .headerContainer .rightContainer .buttonBar {
  margin: auto;
  margin-left: 10px;
  height: 24px;
}
.TechnicalChart .headerContainer .rightContainer .closeButton {
  display: flex;
  align-items: center;
  width: 66px;
}
.TechnicalChart .content {
  height: 100%;
  /*background: #b0bec5;*/
  box-sizing: border-box;
  /*padding: 10px;*/
}
.TechnicalChart .content .container {
  position: relative;
  /*background: #ffffff;*/
  /*border: 1px solid #dddddd;*/
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}
.TechnicalChart .content .container .chartHeader {
  display: flex;
  justify-content: space-between;
  height: 48px;
  /*background-color: #f0f0f0;*/
  background-color: #222429;
}
.TechnicalChart .content .container .chartHeader.mobile {
  height: 56px;
  /* padding-bottom: 8px; */
  box-sizing: content-box !important;
}
.TechnicalChart .content .container .chartHeader.light {
  background-color: #ffffff;
}
.TechnicalChart .content .container .chartHeader .chartContent {
  margin: auto;
  margin-left: 10px;
}
.TechnicalChart .content .container .chartHeader .chartContent .content-top {
  display: flex;
  align-items: center;
}
.TechnicalChart .content .container .chartHeader .chartContent .content-top .ricName {
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  color: #0000ff;
  margin-right: 30px;
}
.TechnicalChart .content .container .chartHeader .chartContent .content-top .marketName {
  font-size: 14px;
  line-height: 16px;
  align-items: center;
}
.TechnicalChart .content .container .chartHeader .chartContent .title {
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: #7f7f7f;
}
.TechnicalChart .content .container .chartHeader .chartContent .value {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  align-items: center;
  color: #000000;
  margin: 0px 10px;
}
.TechnicalChart .content .container .chartHeader .chartContent .prevValue,
.TechnicalChart .content .container .chartHeader .chartContent .prevPercent {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  margin: 0px 10px;
}
.TechnicalChart .content .container .chartHeader .chartContent .prevValue.upval,
.TechnicalChart .content .container .chartHeader .chartContent .prevPercent.upval {
  color: #e5003e;
}
.TechnicalChart .content .container .chartHeader .chartContent .prevValue.downval,
.TechnicalChart .content .container .chartHeader .chartContent .prevPercent.downval {
  color: #4a4aff;
}
.TechnicalChart .content .container .chartHeader .menu {
  width: 100%;
}
.TechnicalChart .content .container .chart {
  flex: 1 1;
}
.TechnicalChart .content .container .statusbar {
  left: 0;
  right: 0;
  bottom: 0;
  height: 28px;
  z-index: 2;
  background-color: #ffffff;
  border-top: 2px solid #dddddd;
  position: relative;
}
.TechnicalChart .content .container .statusbar .statusball {
  top: 2px;
  left: 8px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}
.TechnicalChart .calendar-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.TechnicalChart .calendar-container .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.TechnicalChart .calendar_pick {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 372px;
  min-height: 344px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  background: #000000;
  overflow: hidden;
  z-index: 9999;
}
.TechnicalChart .calendar_pick.mobile {
  width: 100%;
  height: 100%;
}
.TechnicalChart .calendar_pick .ant-picker-calendar {
  background: #000000;
  line-height: 16px;
  border-radius: 4px;
}
.TechnicalChart .calendar_pick .ant-picker-content th {
  color: #ffffff;
  font-weight: 500;
}
.TechnicalChart .calendar_pick .ant-picker-calendar-header {
  padding: 22px 21px;
  align-items: center;
  justify-content: center;
}
.TechnicalChart .calendar_pick .ant-select {
  color: #ffffff;
}
.TechnicalChart .calendar_pick .ant-select-selector {
  border: 0;
}
.TechnicalChart .calendar_pick .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
  background: #222429;
  border-radius: 3px;
  height: 36px;
  line-height: 36px;
  text-align: center;
}
.TechnicalChart .calendar_pick .ant-select-single.ant-select-sm:not(.ant-select-customize-input
        .ant-select-selector) {
  padding: 0;
}
.TechnicalChart .calendar_pick .ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 79px;
}
.TechnicalChart .calendar_pick .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item {
  line-height: 36px;
  min-width: 79px;
}
.TechnicalChart .calendar_pick .ant-radio-group {
  border: 0;
  color: #ffffff;
  background: #222429;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  display: -webkit-flex;
  flex-wrap: nowrap;
}
.TechnicalChart .calendar_pick .ant-radio-button-wrapper:last-child {
  padding: 0;
  border-radius: 0;
}
.TechnicalChart .calendar_pick .ant-radio-button-wrapper {
  border: 0;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  background: #222429;
  min-width: 71px;
}
.TechnicalChart .calendar_pick .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background: #373737;
}
.TechnicalChart .calendar_pick .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background: #373737;
}
.TechnicalChart .calendar_pick .ant-radio-button-wrapper:not(:first-child)::before {
  background: #373737;
}
.TechnicalChart .calendar_pick .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #ffffff;
  background: #008fc7;
}
.TechnicalChart .calendar_pick .ant-select-arrow {
  color: #ffffff;
}
.TechnicalChart .calendar_pick .ant-select-dropdown {
  background: #373737;
  color: #ffffff;
  border: 1px solid #000000;
}
.TechnicalChart .calendar_pick .ant-select-item {
  background: #373737;
  color: #ffffff;
  font-weight: 400;
}
.TechnicalChart .calendar_pick .ant-select-item:hover {
  background: #1b1b1b;
}
.TechnicalChart .calendar_pick .ant-picker-panel {
  background: #000000;
  border-top: 1px solid #393939;
  border-radius: 0 0 4px 4px;
}
.TechnicalChart .calendar_pick .ant-picker-cell-in-view .ant-picker-cell-inner {
  color: #ffffff;
  border-radius: 3px;
  padding: 0;
}
.TechnicalChart .calendar_pick .ant-picker-cell-in-view .ant-picker-cell-inner:hover {
  color: #ffffff;
  background: #008fc7;
}
.TechnicalChart .calendar_pick .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #008fc7;
}
.TechnicalChart .calendar_pick .ant-picker-cell {
  color: #828282;
  border-radius: 3px;
}
.TechnicalChart .calendar_pick .ant-picker-cell:hover .ant-picker-calendar-date-value {
  background: #008fc7;
  border-radius: 3px;
  color: #ffffff;
}
.TechnicalChart .calendar_pick_light {
  box-shadow: 0 3px 10px #f3f3f3;
  border: 1px solid #f3f3f3;
  background: #ffffff;
}
.TechnicalChart .calendar_pick_light .ant-picker-calendar {
  background: #ffffff;
  line-height: 16px;
  border-radius: 4px;
}
.TechnicalChart .calendar_pick_light .ant-picker-content th {
  color: #333333;
}
.TechnicalChart .calendar_pick_light .ant-select {
  color: #333333;
}
.TechnicalChart .calendar_pick_light .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #ffffff;
  border: 1px solid #f3f3f3;
}
.TechnicalChart .calendar_pick_light .ant-radio-group {
  background: #ffffff;
}
.TechnicalChart .calendar_pick_light .ant-radio-button-wrapper {
  color: #333333;
  background: #ffffff;
  border: 1px solid #f3f3f3;
}
.TechnicalChart .calendar_pick_light .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background: #ffffff;
}
.TechnicalChart .calendar_pick_light .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background: #ffffff;
}
.TechnicalChart .calendar_pick_light .ant-radio-button-wrapper:not(:first-child)::before {
  background: #ffffff;
}
.TechnicalChart .calendar_pick_light .ant-select-arrow {
  color: #333333;
}
.TechnicalChart .calendar_pick_light .ant-select-dropdown {
  background: #ffffff;
  color: #333333;
  border: 1px solid #f3f3f3;
}
.TechnicalChart .calendar_pick_light .ant-select-item {
  background: #ffffff;
  color: #333333;
}
.TechnicalChart .calendar_pick_light .ant-select-item:hover {
  background: #f3f3f3;
}
.TechnicalChart .calendar_pick_light .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  color: #ffffff;
}
.TechnicalChart .calendar_pick_light .ant-picker-panel {
  background: #ffffff;
  border-top: 1px solid #f3f3f3;
}
.TechnicalChart .calendar_pick_light .ant-picker-cell-in-view .ant-picker-cell-inner {
  color: #333333;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .TechnicalChart {
    /* IE10+ CSS styles go here */
  }
  .TechnicalChart .content .container .chartHeader .chartContent {
    margin-top: 5.5px;
  }
}
#colorpickerdialog {
  display: none;
  position: absolute;
  /*width: 234px;*/
  height: 255px;
  padding: 10px;
  z-index: 101;
  font-family: Verdana, sans-serif;
  font-size: 10px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
}
.HeaderInput {
  border: 1px solid #dddde2;
  border-radius: 3px;
  margin-left: 5px;
  width: 110px !important;
}
.HeaderInput input {
  border: none;
  width: 100px !important;
  height: 26px !important;
  margin-left: 2px !important;
}
.Print-MainTooltip {
  position: absolute;
  line-height: 18px;
  height: auto;
  left: 12px;
  right: 61px;
  font-size: 10px;
  margin-top: 5px;
  color: #8687ab;
  top: 28px;
}
