.tabs_con {
  height: 48px;
  min-height: 48px;
  padding: 0 16px;
  align-items: center;
  line-height: 14px;
  width: auto !important;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.tabs_con .tab_one {
  height: 100%;
}
.tabs_con .tab_one * {
  position: relative;
}
.tabs_con .tab_one * span {
  height: 100%;
  margin-right: 16px;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  font-weight: 700;
  white-space: nowrap;
}
.tabs_con .tab_one * span label {
  cursor: pointer;
}
.tabs_con .tab_one * .tab_seatnot {
  position: absolute;
  left: 0;
  bottom: 0;
  font-weight: 400;
  z-index: 99;
}
.tabs_con .tab_one * .tab_seat {
  opacity: 0;
  z-index: 1;
  position: relative;
}
.tabs_con .tab_one * .tab_quote {
  position: relative;
  bottom: 0.5px;
}
.tabs_con .tab_one * .active {
  color: #008FC7 !important;
}
.tabs_con .tab_one * .active::after {
  content: '';
  width: 100%;
  height: 3px;
  background: #008FC7;
  border-radius: 8px;
  display: block;
  position: absolute;
  bottom: 0;
  transition: 0.2s all linear;
}
.tabs_con .tab_one *:last-child span:last-child {
  margin-right: 0;
}
.tabs_con::-webkit-scrollbar {
  height: 0 !important;
}
.tabs_con_account {
  overflow: unset !important;
}
