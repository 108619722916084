.modal_fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99999999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}
.modal_fixed .modal_con {
  background: #222429;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}
.modal_fixed .modal_con .title {
  min-height: 48px;
  padding: 0 16px;
}
.modal_fixed .modal_con .switch_content {
  min-height: 53px;
  padding: 0 16px;
  margin-bottom: 34px;
}
.modal_fixed .modal_con .switch_content .switch_content_left {
  color: #828282;
  min-width: 150px;
}
.modal_fixed .modal_con .switch_content .switch_content_right {
  font-size: 16px;
  line-height: 19px;
  min-height: 52px;
  cursor: pointer;
  position: relative;
}
.modal_fixed .modal_con .switch_content .switch_content_right .img_dropDown {
  height: 14px;
  width: 15px;
}
.modal_fixed .modal_con .switch_content .switch_content_right .dropsDown_con {
  width: 100%;
  max-height: 208px;
  position: absolute;
  left: 0;
  top: 53px;
  z-index: 9991;
  border-radius: 3px;
  background: #373737;
  box-shadow: 2px 2px 8px #000000;
  overflow-y: auto;
}
.modal_fixed .modal_con .switch_content .switch_content_right .dropsDown_con span {
  padding: 0 16px;
  min-height: 52px;
  border-bottom: 1px solid #333333;
  cursor: pointer;
}
.modal_fixed .modal_con .switch_content .switch_content_right .dropsDown_con .options_one:last-child {
  border-bottom: 0 !important;
}
.modal_fixed .modal_con .switch_content .switch_content_right .select_con_light {
  background: #FFFFFF;
  box-shadow: 2px 2px 8px #E0E0E0;
}
.modal_fixed .modal_con .switch_content .switch_content_right .select_con_light span {
  border-bottom: 1px solid #E0E0E0;
}
.modal_fixed .modal_con button {
  width: 105px;
  height: 40px;
  background: #393939;
  border-radius: 3px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  padding: 0;
}
.modal_fixed .modal_con :global(.ant-btn:focus) {
  background: #393939;
}
.modal_fixed .modal_con .button_con {
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px 16px 0;
}
.modal_fixed .modal_con .button_con button {
  width: 132px;
}
.modal_fixed .modal_con .button_con button:first-child {
  margin-right: 8px;
}
.modal_fixed .modal_con .button_con_mobile {
  width: 100%;
  right: 0;
  justify-content: space-between;
  padding: 0 16px 16px 16px;
}
.modal_fixed .modal_con .button_con_mobile button {
  width: 50%;
}
.modal_fixed .modal_con .button_con_mobile button:last-child {
  width: calc(50% - 8px);
}
.modal_fixed .modal_con_light {
  background: #FFFFFF;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}
