.list_tab {
  height: 48px;
  padding: 0 16px;
  font-size: 14px;
  overflow-x: auto;
}
.list_tab.list_tab::-webkit-scrollbar {
  display: none;
}
.list_tab span {
  cursor: pointer;
  white-space: nowrap;
  margin-right: 16px;
  position: relative;
  padding: 0 4px;
}
.list_tab span:last-child {
  margin-right: 0;
}
.list_tab span label {
  position: absolute;
  bottom: -15px;
  left: -6px;
  display: block;
  width: 120%;
  height: 4px;
  background: #008FC7;
  border-radius: 2px;
}
.list_tab button {
  padding: 0 8px;
  height: 25px;
  line-height: 25px;
  font-size: 13px;
  font-weight: normal;
  margin-right: 16px;
}
