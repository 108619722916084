:global(.ant-modal-mask) {
  background-color: rgba(0, 0, 0, 0.7);
}
:global(.ant-modal-wrap) {
  z-index: 999999999999;
}
:global(.ant-modal) {
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 16px;
  border-radius: 3px;
  overflow: hidden;
  width: 528px !important;
  min-height: 238px;
  background-color: #222429;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}
:global(.ant-modal-content) {
  box-shadow: none;
  min-height: 238px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
}
:global(.ant-modal-body) {
  padding: 0;
  line-height: 16px;
  background-color: #222429;
  flex: 1 1;
  -webkit-flex: 1;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
}
:global(.ant-modal-close) {
  display: none;
}
.modalLight {
  background: #FFFFFF;
}
.modalLight :global(.ant-modal-body) {
  background: #FFFFFF;
}
.modalLight .noteTradeContent {
  color: #828282;
}
.modalMobile {
  width: 326px !important;
}
.modalMobile .noteButton button {
  flex: 1 1;
  -webkit-flex: 1;
}
.noteTitle {
  padding: 8px 16px;
  min-height: 48px;
}
.noteTitle span {
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  margin-right: 10px;
}
.noteContent {
  padding: 16px;
  min-height: 102px;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 18px;
}
.noteButton {
  padding: 8px 16px;
  min-height: 88px;
  align-items: center;
  justify-content: flex-end;
}
.noteButton button {
  min-height: 40px;
  width: 144px;
  white-space: pre-wrap;
  cursor: pointer;
  border-radius: 3px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  padding: 4px;
}
.noteButton button:first-child {
  margin-right: 8px;
}
.agreeCon .messageCon {
  padding: 8px 16px;
  min-height: 48px;
  align-items: center;
}
.agreeCon .messageCon span:first-child {
  width: 104px;
  min-width: 104px;
}
.agreeCon .agreeContent {
  height: 354px;
}
.agreeCon .agreeContent::-webkit-scrollbar {
  width: 8px;
}
.agreeCon .agreeContent::-webkit-scrollbar-thumb {
  background-color: #373737;
  border-radius: 100px;
}
.agreeCon .agreeContent .lineCon {
  width: 100%;
  height: 8px;
  display: block;
  background-color: #161616;
}
.agreeCon .agreeContent .titleCon {
  font-weight: 700;
  margin-bottom: 6px;
}
.agreeCon .agreeBtnCon {
  height: 72px;
  padding: 0 16px;
}
.agreeCon .agreeBtnCon button {
  flex: 1 1;
  -webkit-flex: 1;
  border-radius: 3px;
  height: 40px;
  padding: 0;
}
.agreeCon .agreeBtnCon button:first-child {
  margin-right: 4px;
}
.agreeCon .agreeBtnCon button:last-child {
  margin-left: 4px;
}
.agreeCon .agreeBtnCon button span {
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  color: #FFFFFF;
}
.agreeNoticeCon .agreeContent {
  height: 427px;
}
.agreeNoticeCon .agreeContent .accountListCon {
  border: 1px solid #373737;
  border-right: 0;
}
.agreeNoticeCon .agreeContent .accountListCon span {
  padding: 8px;
  min-height: 16px;
  text-align: center;
}
.agreeNoticeCon .agreeContent .accountListCon span:first-child {
  font-size: 11px;
  line-height: 15px;
  font-weight: 700;
  background-color: #009E9C;
  border-bottom: 1px solid #373737;
}
