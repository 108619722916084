.calendarcon .calendar {
  width: 190px;
  height: 32px;
  background: #393939;
  border-radius: 40px;
  padding: 2px 16px;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  justify-content: space-between;
}
.calendarcon .calendar .calendar_left {
  align-items: center;
  cursor: pointer;
}
.calendarcon .calendar .calendar_left img {
  transform: rotate(0deg);
  width: 14px;
  height: 12px;
  margin-left: 2px;
}
.calendarcon .calendar .calendar_left .imgRotate {
  transform: rotate(180deg);
}
.calendarcon .calendar_pick {
  position: absolute;
  top: 40px;
  width: 372px;
  min-height: 344px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  overflow: hidden;
  z-index: 9999;
}
.calendarcon .calendar_pick :global(.ant-picker-calendar) {
  background: #000000;
  line-height: 16px;
  border-radius: 4px;
}
.calendarcon .calendar_pick :global(.ant-picker-content th) {
  color: #FFFFFF;
  font-weight: 500;
}
.calendarcon .calendar_pick :global(.ant-picker-calendar-header) {
  padding: 22px 21px;
  align-items: center;
  justify-content: center;
}
.calendarcon .calendar_pick :global(.ant-select) {
  color: #FFFFFF;
}
.calendarcon .calendar_pick :global(.ant-select-selector) {
  border: 0;
}
.calendarcon .calendar_pick :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border: 0;
  background: #222429;
  border-radius: 3px;
  height: 36px;
  line-height: 36px;
  text-align: center;
}
.calendarcon .calendar_pick :global(.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector) {
  padding: 0;
}
.calendarcon .calendar_pick :global(.ant-picker-calendar-header .ant-picker-calendar-year-select) {
  min-width: 79px;
}
.calendarcon .calendar_pick :global(.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item) {
  line-height: 36px;
  min-width: 79px;
}
.calendarcon .calendar_pick :global(.ant-radio-group) {
  border: 0;
  color: #FFFFFF;
  background: #222429;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  display: -webkit-flex;
  flex-wrap: nowrap;
}
.calendarcon .calendar_pick :global(.ant-radio-button-wrapper:last-child) {
  padding: 0;
  border-radius: 0;
}
.calendarcon .calendar_pick :global(.ant-radio-button-wrapper) {
  border: 0;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #FFFFFF;
  background: #222429;
  min-width: 71px;
}
.calendarcon .calendar_pick :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before) {
  background: #373737;
}
.calendarcon .calendar_pick :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before) {
  background: #373737;
}
.calendarcon .calendar_pick :global(.ant-radio-button-wrapper:not(:first-child)::before) {
  background: #373737;
}
.calendarcon .calendar_pick :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
  color: #FFFFFF;
  background: #008FC7;
}
.calendarcon .calendar_pick :global(.ant-select-arrow) {
  color: #FFFFFF;
}
.calendarcon .calendar_pick :global(.ant-select-dropdown) {
  background: #373737;
  color: #FFFFFF;
  border: 1px solid #000000;
}
.calendarcon .calendar_pick :global(.ant-select-item) {
  background: #373737;
  color: #FFFFFF;
  font-weight: 400;
}
.calendarcon .calendar_pick :global(.ant-select-item:hover) {
  background: #1b1b1b;
}
.calendarcon .calendar_pick :global(.ant-picker-panel) {
  background: #000000;
  border-top: 1px solid #393939;
  border-radius: 0 0 4px 4px;
}
.calendarcon .calendar_pick :global(.ant-picker-cell-in-view .ant-picker-cell-inner) {
  color: #FFFFFF;
  border-radius: 3px;
  padding: 0;
}
.calendarcon .calendar_pick :global(.ant-picker-cell-in-view .ant-picker-cell-inner:hover) {
  color: #FFFFFF;
  background: #008FC7;
}
.calendarcon .calendar_pick :global(.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner) {
  background: #008FC7;
}
.calendarcon .calendar_pick :global(.ant-picker-cell) {
  color: #828282;
  border-radius: 3px;
}
.calendarcon .calendar_pick :global(.ant-picker-cell:hover .ant-picker-calendar-date-value) {
  background: #008FC7;
  border-radius: 3px;
  color: #FFFFFF;
}
.calendarcon_light .calendar {
  color: #333333;
  background: #E0E0E0;
}
.calendarcon_light .calendar_pick {
  box-shadow: 0 3px 10px #F3F3F3;
  border: 1px solid #F3F3F3;
}
.calendarcon_light .calendar_pick :global(.ant-picker-calendar) {
  background: #FFFFFF;
  line-height: 16px;
  border-radius: 4px;
}
.calendarcon_light .calendar_pick :global(.ant-picker-content th) {
  color: #333333;
}
.calendarcon_light .calendar_pick :global(.ant-select) {
  color: #333333;
}
.calendarcon_light .calendar_pick :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
}
.calendarcon_light .calendar_pick :global(.ant-radio-group) {
  background: #FFFFFF;
}
.calendarcon_light .calendar_pick :global(.ant-radio-button-wrapper) {
  color: #333333;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
}
.calendarcon_light .calendar_pick :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before) {
  background: #FFFFFF;
}
.calendarcon_light .calendar_pick :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before) {
  background: #FFFFFF;
}
.calendarcon_light .calendar_pick :global(.ant-radio-button-wrapper:not(:first-child)::before) {
  background: #FFFFFF;
}
.calendarcon_light .calendar_pick :global(.ant-select-arrow) {
  color: #333333;
}
.calendarcon_light .calendar_pick :global(.ant-select-dropdown) {
  background: #FFFFFF;
  color: #333333;
  border: 1px solid #F3F3F3;
}
.calendarcon_light .calendar_pick :global(.ant-select-item) {
  background: #FFFFFF;
  color: #333333;
}
.calendarcon_light .calendar_pick :global(.ant-select-item:hover) {
  background: #F3F3F3;
}
.calendarcon_light .calendar_pick :global(.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner) {
  color: #FFFFFF;
}
.calendarcon_light .calendar_pick :global(.ant-picker-panel) {
  background: #FFFFFF;
  border-top: 1px solid #F3F3F3;
}
.calendarcon_light .calendar_pick :global(.ant-picker-cell-in-view .ant-picker-cell-inner) {
  color: #333333;
}
.calendarOverseas {
  margin-right: 0 !important;
  width: 100% !important;
}
.calendarOverseas .calendar {
  width: 100% !important;
}
