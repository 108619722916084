.ui-datepicker {
  width: 300px;
  padding: 5px 5px 0;
  display: none;
}
.ui-widget-content {
  border: 1px solid #ddd;
  background: #fff;
  color: #333;
}
.ui-widget-header {
  border: 1px solid #ddd;
  background: #e9e9e9;
  color: #333;
  font-weight: bold;
}
.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: 5px;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: relative;
  top: 10px;
}
.ui-datepicker .ui-datepicker-next {
  float: right;
}
.ui-datepicker .ui-datepicker-next span {
  display: block;
  top: 50%;
  margin-top: -8px;
}
.ui-datepicker .ui-datepicker-prev span {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -8px;
}
.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
}
.ui-icon-circle-triangle-w {
  background-position: -80px -192px;
}
.ui-icon-circle-triangle-w-disable {
  background-position: -80px -192px;
  opacity: 0.35;
}
.ui-datepicker-Week {
  display: flex;
}
.ui-datepicker-Week-Header {
  display: flex;
}
.ui-datepicker-Day-Header {
  width: 100%;
  padding: 0.7em 0.3em;
  text-align: center;
  font-weight: bold;
  border: 0;
}
.ui-datepicker-Day {
  display: flex;
  font-size: 14px;
  line-height: 16px;
  color: #454545;
  flex: 1 1;
  align-items: center;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  padding: 1px;
}
.ui-datepicker-Day-Text.Select {
  border: 1px solid #003eff;
  background: #007fff;
  font-weight: normal;
  color: #fff;
}
.ui-datepicker-Day-Text.Today {
  border: 1px solid #dad55e;
  background: #fffa90;
  color: #777620;
}
.ui-datepicker-Day-Text.Disable {
  opacity: 0.35;
  background-image: none;
}
.ui-datepicker-Day-Text {
  flex: 1 1;
  font-size: 14px;
  line-height: 16px;
  text-align: end;
  padding: 5px;
  border: 1px solid #c5c5c5;
  background: #f6f6f6;
  font-weight: normal;
}
.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: 0.7em 0 0 0;
  padding: 0 0.2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: 0.5em 0.2em 0.4em;
  cursor: pointer;
  padding: 0.2em 0.6em 0.3em 0.6em;
  width: auto;
  overflow: visible;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 45%;
}
.ui-icon {
  background-image: url(../../../../asserts/imgs/chart/ui-icons_444444_256x240.png);
}
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
  width: 18px;
  height: 15px;
}
.ui-icon-circle-triangle-e {
  background-position: -48px -192px;
}
.ui-icon-circle-triangle-e-disable {
  background-position: -48px -192px;
  opacity: 0.35;
}
