.header_con .header_left {
  align-items: center;
}
.header_con .header_left .header_left_tab {
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
}
.header_con .header_left .header_left_tab::-webkit-scrollbar {
  height: 0 !important;
}
