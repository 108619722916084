.results_con {
  padding-top: 16px;
}
.results_con .filters_con {
  padding: 0 16px 14px 16px;
}
.results_con .filters_con span:last-child {
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
}
.results_con .filters_con img {
  width: 16px;
}
.results_con .month_con_out {
  padding: 0 16px 24px 16px;
}
.results_con .month_con_out .month_con {
  flex-wrap: wrap;
}
.results_con .month_con_out .month_con span {
  display: block;
  width: calc(100% / 4);
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #828282;
  font-weight: 400;
  font-size: 12px;
}
.results_con .month_con_out .month_con span:last-child {
  border-right: 0 !important;
}
.results_con .month_con_out .month_con .values {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
}
.results_con .month_con_out .month_con .borderRight_light {
  border-right: 2px solid #E0E0E0 !important;
}
.results_con .month_con_out .month_con .borderRight_dark {
  border-right: 2px solid #373737 !important;
}
.results_con .checkbox_con {
  padding: 0 16px;
}
.results_con .checkbox_con .results_list {
  padding-bottom: 22px;
}
.results_con .checkbox_con .results_list .results_item {
  margin-left: 8px;
}
.results_con .checkbox_con .results_list .results_item .results_title {
  margin-bottom: 4px;
}
.results_con .checkbox_con .results_list .results_item .results_subtitle {
  font-size: 12px;
  line-height: 14px;
}
.results_con .range_con .range_title {
  align-items: center;
}
.results_con .range_con .range_dropDown {
  margin-bottom: 16px;
}
.results_con .range_con .range_dropDown .range_dropDown_title {
  position: relative;
  align-items: center;
  padding: 8px 12px;
  background: #333333;
  border-radius: 2px;
  cursor: pointer;
}
.results_con .range_con .range_dropDown .range_dropDown_title img {
  height: 14px;
  width: 14px;
}
.results_con .range_con .inputs_con {
  margin-bottom: 8px;
}
.results_con .range_con .inputs_con span {
  padding: 0 16px;
  text-align: center;
}
.results_con .range_con .inputs_con input {
  height: 32px;
  background: #333333;
  border: 1px solid #373737;
  border-radius: 2px;
  padding: 6px 12px;
}
.results_con .range_con .inputs_con .inputs_con_one {
  flex: 1 1;
  -webkit-flex: 1;
  position: relative;
  cursor: pointer;
  height: 32px;
  background: #333333;
  border: 1px solid #373737;
  border-radius: 2px;
  padding: 6px 12px;
}
.results_con .range_con .inputs_con .inputs_con_one img {
  height: 14px;
  width: 14px;
}
.results_con .range_con .inputs_con_light input {
  background: #E0E0E0;
  border: 1px solid #E0E0E0;
  color: #333333;
}
.results_con .range_con .slider_con {
  padding: 1px 0;
}
.results_con .range_con .slider_con :global(.ant-slider-rail) {
  background: #828282;
  border-radius: 4px;
}
.results_con .range_con .slider_con :global(.ant-slider-track) {
  background: #008FC7;
  border-radius: 4px;
}
.results_con .range_con .slider_con :global(.ant-slider-handle) {
  border: solid 2px #008FC7;
  background: #008FC7;
}
.results_con .range_con .slider_con_light :global(.ant-slider-rail) {
  background: #E0E0E0;
}
