.trade_layout {
  height: 100%;
  position: relative;
}
.trade_layout .spanW {
  width: 120px;
  min-width: 120px;
}
.trade_layout .img_help {
  position: relative;
  left: 2px;
  bottom: 3px;
}
.trade_layout .tradeflow_con {
  align-items: center;
  height: 48px;
  padding: 0 16px;
}
.trade_layout .line {
  height: 8px;
  width: 100%;
}
.trade_layout .trade_tips {
  padding: 8px 16px;
  height: auto;
  min-height: 48px;
}
.trade_layout .typebutton_active {
  background: #E0E0E0 !important;
}
.trade_layout .typebutton_active span {
  color: #000000 !important;
}
.trade_layout .direction_right_light button {
  border: 1px solid #E0E0E0;
}
.trade_layout .direction_right_light button span {
  color: #333333;
}
.trade_layout .direction_right_light button[disabled] {
  background: none !important;
}
.trade_layout .name {
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  padding: 0 16px;
}
.trade_layout .name_mobile {
  padding: 0 8px;
}
.trade_layout .name_mobile span {
  padding-left: 6px;
}
.trade_layout .accountNumber {
  position: relative;
}
.trade_layout .accountNumber span {
  white-space: nowrap;
}
.trade_layout .accountNumber span:last-child {
  display: block;
  flex: 1 1;
  -webkit-flex: 1;
}
.trade_layout .buyingPower .buySelected {
  flex: 1 1;
  -webkit-flex: 1;
  height: 100%;
  position: relative;
}
.trade_layout .buyingPower .buySelected .dropsDown_con {
  position: absolute;
  right: 0;
  top: 48px;
  width: 100%;
}
.trade_layout .buyingPower .buySelected .buySelected_right {
  flex: 1 1;
  -webkit-flex: 1;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
.trade_layout .buyingPower .buySelected .buySelected_right .buySelected_right_name {
  font-size: 14px;
  line-height: 16px;
  opacity: 0.5;
  align-items: center;
}
.trade_layout .buyingPower .buySelected .buySelected_right .buySelected_right_name1 {
  background-color: #393939;
  padding: 4px;
}
.trade_layout .buyingPower .buySelected .buySelected_right .buySelected_right_name1 label {
  color: #828282;
}
.trade_layout .buyingPower .buySelected .buySelected_right .buySelected_right_symbol {
  align-items: center;
}
.trade_layout .buyingPower .buySelected .buySelected_right .buySelected_right_symbol span:first-child {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-right: 6px;
}
.trade_layout .buyingPower .buySelected .buySelected_right .buySelected_right_symbol span:nth-child(2) {
  position: relative;
  top: 1px;
}
.trade_layout .buyingPower .buySelected .buySelected_right .Delivery {
  color: #00D4F0;
  border: 1px solid #00D4F0;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 10px;
  line-height: 12px;
  margin-left: 6px;
}
.trade_layout .buyingPower .buySelected .entername {
  width: 100%;
  height: 100%;
}
.trade_layout .buyingPower .buySelected .entername input {
  font-size: 14px;
  line-height: 16px;
}
.trade_layout .buyingPower .buySelected .entername input::placeholder {
  color: #828282;
  opacity: 0.5;
}
.trade_layout .buyingPower .buySelected .entername .getNamecon {
  align-items: center;
  flex: 1 1;
  -webkit-flex: 1;
}
.trade_layout .buyingPower .buySelected .entername .getNamecon span {
  font-weight: 700;
}
.trade_layout .buyingPower .buySelected .entername .getNamecon span:nth-child(2) {
  margin: 0 10px 0 3px;
}
.trade_layout .buyingPower .buySelected .entername .getNamecon span:last-child {
  color: #00D4F0;
  font-weight: 400;
}
.trade_layout .buyingPower .buySelected .entername_light input {
  color: #333333;
}
.trade_layout .buyingPower .img_dropDown {
  height: 14px;
  width: 15px;
}
.trade_layout .quote_con .quote_con_turnover {
  flex: 1 1;
  -webkit-flex: 1;
  align-items: flex-end;
}
.trade_layout .quote_con .quote_con_turnover img {
  margin: 0 10px;
  margin-bottom: 2px;
}
.trade_layout .quote_con .quote_con_turnover span {
  position: relative;
  bottom: -1px;
}
.trade_layout .quote_con .quote_con_turnover span:first-child {
  font-size: 18px;
  line-height: 21px;
  position: relative;
  top: 2px;
}
.trade_layout .quote_con .quote_con_turnover span:nth-child(-n+4):nth-child(n+2) {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.trade_layout .direction .direction_right {
  flex: 1 1;
  -webkit-flex: 1;
}
.trade_layout .direction .direction_right button {
  width: 50%;
  margin-right: 8px;
  border-radius: 3px;
}
.trade_layout .direction .direction_right button span {
  height: 100%;
}
.trade_layout .direction .direction_right button:last-child {
  width: calc(50% - 8px);
  margin-right: 0;
}
.trade_layout .position {
  height: 32px;
  justify-content: flex-start;
}
.trade_layout .amount {
  justify-content: flex-start;
}
.trade_layout .amount .amountNum {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}
.trade_layout .until_con div {
  flex: 1 1;
  -webkit-flex: 1;
}
.trade_layout .until_con div button {
  width: calc(100%/4);
  height: 32px;
  line-height: 32px;
  margin-right: 8px;
  border-radius: 3px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 12px;
}
.trade_layout .until_con div button:last-child {
  margin-right: 0;
}
.trade_layout .until_con div .until_active {
  background: #E0E0E0;
}
.trade_layout .until_con div .until_active span {
  color: #000000;
}
.trade_layout .trade_choicecon {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
}
.trade_layout .trade_choicecon .img_dropDown {
  height: 14px;
  width: 15px;
}
.trade_layout_mobile {
  font-size: 16px;
}
.trade_layout_mobile .spanW {
  width: 140px;
  min-width: 140px;
}
.trade_layout_mobile .trade_tips {
  line-height: 18px;
}
.trade_layout_mobile .buyingPower {
  position: relative;
}
.trade_layout_mobile .buyingPower .buySelected .buySelected_right .buySelected_right_name {
  font-size: 16px;
  line-height: 18px;
}
.trade_layout_mobile .buyingPower .buySelected .buySelected_right .buySelected_right_symbol span:first-child {
  font-size: 20px;
  line-height: 22px;
}
.trade_layout_mobile .buyingPower .buySelected .entername input {
  font-size: 16px;
  line-height: 18px;
}
.trade_layout_mobile .buyingPower .buySelected .entername .getNamecon span:nth-child(2) {
  margin: 0 10px 0 5px;
}
.trade_layout_mobile .buyingPower .buySelected_name {
  position: static;
}
.trade_layout_mobile .buyingPower .buySelected_name .dropsDown_con {
  width: 70%;
  right: 16px;
}
.trade_layout_mobile .quote_con .quote_con_turnover span:first-child {
  font-size: 20px;
  line-height: 22px;
}
.trade_layout_mobile .quote_con .quote_con_turnover span:nth-child(-n+4):nth-child(n+2) {
  font-size: 16px;
  line-height: 18px;
}
.trade_layout_mobile .direction .direction_right button span {
  font-size: 16px;
}
.trade_layout_mobile .amount .amountNum {
  font-size: 20px;
  line-height: 22px;
}
.trade_layout_mobile .until_con div button {
  font-size: 14px;
}
.trade_layout_mobileD .spanW {
  color: #FFFFFF;
}
.trade_layout_mobileD .direction_right_mobile button span {
  font-size: 18px !important;
}
.trade_layout_mobileW .spanW {
  color: #333333;
}
.trade_layout_mobileW .direction_right_mobile button span {
  font-size: 18px !important;
}
.direction_mobile {
  height: 58px !important;
}
.direction_mobile .direction_right button {
  height: 40px;
  line-height: 40px;
}
.direction_mobile .direction_right button span {
  font-size: 24px !important;
}
