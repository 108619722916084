.stocks_quote_chart {
  height: 400px;
  width: 100%;
  margin: 8px 0 16px 0;
  overflow: hidden;
  position: relative;
}
.stocks_quote {
  padding: 0 16px;
}
.stocks_quote .stocks_quote_tab {
  padding: 12px 0;
  align-items: center;
  flex-wrap: wrap;
}
.stocks_quote .stocks_quote_tab .stocks_quote_tableft button {
  width: 31px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  font-size: 12px;
  margin-right: 5px;
  cursor: pointer;
  background: none;
  color: #828282;
  font-weight: 400;
}
.stocks_quote .stocks_quote_tab .stocks_quote_tableft .time_active {
  color: #FFFFFF;
  font-weight: 700;
  background: #008FC7;
  border-radius: 3px;
}
.stocks_quote .stocks_quote_tab .stocks_quote_tableftMin1 {
  width: 100%;
  justify-content: space-between;
}
.stocks_quote .stocks_quote_tab .stocks_quote_tableftMin1 button:last-child {
  margin-right: 0;
}
.stocks_quote .stocks_quote_tab .stocksquote_tabright {
  border-left: 1px solid #373737;
  padding-left: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #008FC7;
  align-items: center;
}
.stocks_quote .stocks_quote_tab .stocksquote_tabright .stocksquote_tabright_span {
  margin-right: 16px;
}
.stocks_quote .stocks_quote_tab .stocksquote_tabright .quote_candlestick {
  margin-right: 4px;
}
.stocks_quote .stocks_quote_tab .stocksquote_tabrightMin1 {
  margin-top: 10px;
  padding-left: 0;
  border: 0;
}
.stocks_quote .stocks_quote_tabMobile {
  margin: 14px 0 16px 0;
}
.stocks_quote .stocks_quote_tabMobile .stocks_quote_tableft {
  width: 100%;
  justify-content: space-between;
}
.stocks_quote .stocks_quote_tabMobile .stocks_quote_tableft button:last-child {
  margin-right: 0;
}
.stocks_quote .stocksquote_tradeinfo {
  position: relative;
}
.stocks_quote .stocksquote_tradeinfo > * {
  width: calc(100%/3);
  border-right: 1px solid #373737;
}
.stocks_quote .stocksquote_tradeinfo > *:first-child .stocksquote_tradeinfo_one {
  margin-left: 0;
}
.stocks_quote .stocksquote_tradeinfo > *:last-child {
  border: 0;
}
.stocks_quote .stocksquote_tradeinfo .stocksquote_tradeinfo_one {
  justify-content: space-between;
  margin: 0 13px 8px 13px;
}
.stocks_quote .stocksquote_tradeinfo .stocksquote_tradeinfo_one span:last-child {
  color: #FFFFFF;
  font-weight: 500;
}
.stocks_quote .stocksquote_tradeinfo .stocksquote_tradeinfo_sqaure {
  flex-wrap: wrap;
}
.stocks_quote .stocksquote_tradeinfo .stocksquote_tradeinfo_sqaure > * {
  width: calc(100%/3 - 1px);
  margin-right: 1px;
  flex-wrap: wrap;
  background: #4F824F;
  height: 102px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.stocks_quote .stocksquote_tradeinfo .stocksquote_tradeinfo_sqaure > * span {
  display: block;
  text-align: center;
}
.stocks_quote .stocksquote_tradeinfo .stocksquote_tradeinfo_sqaure > * span:first-child {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
}
.stocks_quote .stocksquote_tradeinfo .stocksquote_tradeinfo_sqaure > * span:last-child {
  margin-top: 4px;
}
.stocks_quote .stocksquote_tradeinfo .stocksquote_tradeinfo_sqaure div:last-child {
  margin-right: 0;
}
.stocks_quote .stocksquote_tradeinfo_oneleft > * {
  margin-left: 0 !important;
}
.stocks_quote .stocksquote_tradeinfo_light > * {
  border-right: 1px solid #E0E0E0;
}
.stocks_quote .stocksquote_tradeinfo_light .stocksquote_tradeinfo_one span:last-child {
  color: #333333;
}
.stocks_quote .stocksquote_tradeinfoList {
  flex-wrap: wrap;
}
.stocks_quote .stocksquote_tradeinfoList > * {
  width: 50%;
}
.stocks_quote .stocksquote_tradeinfoList .stocksquote_ask_con {
  border: 0;
}
.stocks_quote .stocksquote_tradeinfoList .stocksquote_ask_con .stocksquote_tradeinfo_one {
  margin-right: 0;
}
.stocks_quote .stocksquote_tradeinfoList .stocksquote_tradeinfo_sqaure_out {
  width: 100%;
  margin-top: 16px;
}
.stocks_quote .stocksquote_tradeinfoList .stocksquote_tradeinfo_sqaure_out .stocksquote_tradeinfo_sqaure {
  margin-left: 0;
}
.stocks_quoteMobile {
  padding-bottom: 80px;
}
.stocks_quoteMobile .stocks_quote_chart {
  height: 400px;
}
.stocks_quoteMobile .stocks_quote_tab {
  height: auto;
  height: 400px;
}
.stocks_quoteMobile .stocks_quote_tab .stocksquote_tabright {
  border: 0;
  padding: 0;
  margin: 19px 0 8px 0;
}
.stocks_quoteMobile .stocks_quote_tab .stocksquote_tabright .stocksquote_tabright_span {
  margin-right: 8px !important;
  padding-right: 8px;
  border-right: 1px solid #373737;
}
