.detail_con {
  height: 100%;
}
.detail_con .quote_right_fold {
  height: 32px;
  min-height: 32px;
  padding: 0 16px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
.detail_con .back_mobile {
  height: 48px;
  min-height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  padding: 0 8px;
  align-items: center;
  justify-content: flex-start;
}
.detail_con .back_mobile span {
  padding-left: 6px;
}
.detail_con .detail_con_1 {
  height: 100%;
  overflow: hidden;
}
