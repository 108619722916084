.BankBalancePage {
  height: 100%;
  position: relative;
}
.BankBalancePage .headerCon {
  align-items: center;
}
.BankBalancePage .headerCon button {
  width: 84px;
  padding: 4px;
  border-radius: 3px;
  line-height: unset;
}
