.apply_con {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  background: #222429;
  /*整个滚动条*/
  /*定义滚动条轨道*/
  /*定义滑块*/
}
.apply_con .trade_place_button {
  margin: 24px 14.5px;
}
.apply_con .trade_place_button button {
  width: 50%;
  height: 48px;
  background: #393939;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  margin-right: 8px;
  background: #828282;
}
.apply_con .trade_place_button button:last-child {
  width: calc(50%);
  margin-right: 0;
}
.apply_con .trade_apply_con {
  width: 525px;
  padding: 20px;
  background: #FFFFFF;
  color: #404141;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.apply_con .trade_apply_con .trade_apply_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  text-align: center;
}
.apply_con .trade_apply_con .trade_apply_subtitle {
  margin: 30px 0 20px 0;
}
.apply_con .trade_apply_con .trade_apply_msg {
  color: #000000;
  margin-bottom: 40px;
  font-weight: 700;
}
.apply_con .trade_apply_con .trade_apply_check {
  align-items: center;
  border: 1px solid #008FC7;
  padding: 10px;
  width: 60%;
  margin: 10px auto;
}
.apply_con .trade_apply_con .trade_apply_check img {
  margin-right: 10px;
}
.apply_con .trade_apply_con .trade_apply_terms {
  border: 0;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 40px;
}
.apply_con .trade_apply_con .trade_apply_name {
  align-items: center;
  margin-bottom: 10px;
  width: 80%;
}
.apply_con .trade_apply_con .trade_apply_name input {
  flex: 1 1;
  border: 1px solid #008FC7;
  margin-left: 16px;
  padding: 10px;
  height: 38px;
  color: #000000;
}
.apply_con .trade_apply_con .trade_apply_name .trade_apply_occupation {
  flex: 1 1;
  margin-left: 16px;
  position: relative;
  /*整个滚动条*/
}
.apply_con .trade_apply_con .trade_apply_name .trade_apply_occupation .trade_apply_occupation_getName {
  min-height: 38px;
  border: 1px solid #008FC7;
  display: block;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}
.apply_con .trade_apply_con .trade_apply_name .trade_apply_occupation .trade_apply_dropdown {
  position: absolute;
  left: 0;
  top: 38px;
  z-index: 99;
  background: #FFFFFF;
  border: 1px solid #008FC7;
  border-top: 0;
  width: 100%;
  height: 160px;
  padding: 10px 0;
  overflow: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.apply_con .trade_apply_con .trade_apply_name .trade_apply_occupation .trade_apply_dropdown span {
  min-height: 32px;
  padding: 0 10px;
  cursor: pointer;
  align-items: flex-start;
  justify-content: center;
}
.apply_con .trade_apply_con .trade_apply_name .trade_apply_occupation .trade_apply_dropdown span:hover {
  background: #E0E0E0;
}
.apply_con .trade_apply_con .trade_apply_name .trade_apply_occupation ::-webkit-scrollbar {
  display: block;
}
.apply_con .trade_apply_con .trade_apply_btn {
  text-align: center;
  margin: 60px 0;
}
.apply_con .trade_apply_con .trade_apply_btn button {
  background: #008FC7;
  width: 150px;
  height: 46px;
}
.apply_con ::-webkit-scrollbar {
  display: block;
  width: 9px;
  height: 8px;
}
.apply_con ::-webkit-scrollbar-track {
  background-color: #E0E0E0;
}
.apply_con ::-webkit-scrollbar-thumb {
  background-color: #84878A;
  border-radius: 8px;
}
.apply_con .trade_apply_DisclaimerRead {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  border: 1px solid #008FC7;
  overflow: hidden;
}
.apply_con .trade_apply_DisclaimerRead img {
  position: absolute;
  right: 10px;
  top: 10px;
}
.apply_con .app_pdfcon {
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px 0;
  width: 600px;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.apply_con .app_pdfcon .app_pdfcon_pageTool {
  align-items: center;
  justify-content: flex-end;
  color: #FFFFFF;
  margin-top: 40px;
  position: absolute;
  bottom: 30px;
  right: 46px;
}
.apply_con .app_pdfcon .app_pdfcon_pageTool span:first-child {
  margin-right: 10px;
}
.apply_con .app_pdfcon .app_pdfcon_pageTool span:last-child {
  margin-left: 10px;
}
.apply_con .app_pdfcon .app_pdfcon_pageTool span:nth-child(4) {
  margin: 0 10px;
}
.apply_con .app_pdfcon .app_pdfcon_pageTool .app_pdfcon_pageAll {
  margin-left: 10px;
}
.apply_con .app_pdfcon .app_PDFclose {
  color: #FFFFFF;
  font-size: 30px;
  position: absolute;
  top: 36px;
  right: 46px;
}
.App_loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
