.add_stocks {
  padding: 0 16px 0 8px;
  height: 56px;
  min-height: 56px;
  align-items: center;
}
.add_stocks .add_inputcon {
  flex: 1 1;
  -webkit-flex: 1;
  border-radius: 40px;
  overflow: hidden;
  position: relative;
  background: #393939;
}
.add_stocks .add_inputcon input {
  font-size: 12px;
  line-height: 14px;
  padding: 0 36px 0 34px;
  border-radius: 0;
  height: 32px;
  width: 90%;
}
.add_stocks .add_inputcon input::placeholder {
  color: #FFFFFF;
  opacity: 0.5;
}
.add_stocks .add_inputcon .close_img {
  right: 16px;
}
.add_stocks .add_inputcon_light {
  background: #E0E0E0;
}
.add_stocks .add_inputcon_light input {
  color: #333333;
}
.add_stocks .add_inputcon_light input::placeholder {
  color: #333333;
}
.history_list_con {
  padding: 18px 0  0 18px;
  flex-wrap: wrap;
}
.history_list_con span {
  color: #E0E0E0;
  background: #333333;
  border-radius: 20px;
  padding: 8px 15px;
  font-size: 10px;
  line-height: 12px;
  margin: 0 8px 16px 0;
  cursor: pointer;
}
.history_list_con_light span {
  color: #333333;
  background: #E0E0E0;
}
.table_icons {
  text-align: right;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #E0E0E0;
  align-items: center;
}
.table_icons img {
  margin-left: 4px;
}
