.body {
  padding: 16px 0;
}
.body .minH392 {
  min-height: 240px;
}
.body .minH250 {
  min-height: 250px;
}
.body .minH350 {
  min-height: 350px;
}
.body .minH134 {
  min-height: 134px;
}
.body .title {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}
.body .sub_title {
  color: #828282;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.body .chart_title {
  margin-bottom: 10px;
}
.body .chart_lenged {
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 16px 0;
}
.body .chart_lenged span {
  display: block;
}
.body .chart_lenged span:nth-child(2) {
  margin-right: 16px;
}
.body .chart_lenged span:nth-child(3) {
  background: #8D48A2;
}
.body .chart_lenged .lenged_icon {
  width: 6px;
  height: 6px;
  background: #008FC7;
  margin-right: 6px;
}
.body .chart {
  padding: 0 16px 16px 16px;
  margin-bottom: 16px;
}
.body .chart .chart_left {
  width: 50%;
  height: 100%;
  padding: 0 16px 16px 0;
  position: relative;
}
.body .chart .chart_left :global(.ant-carousel .slick-dots li) {
  width: 6px;
  height: 6px;
  margin: 0;
  margin-right: 8px;
}
.body .chart .chart_left :global(.ant-carousel .slick-dots li button) {
  width: 6px;
  height: 6px;
  line-height: 6px;
  border-radius: 50%;
  background: #E0E0E0;
  opacity: 1;
}
.body .chart .chart_left :global(.ant-carousel .slick-dots li.slick-active button) {
  width: 13px;
  background: #008FC7;
  border-radius: 5px;
}
.body .chart .chart_left :global(.ant-carousel .slick-dots li.slick-active) {
  width: 13px;
}
.body .chart .chart_left_min {
  width: 100%;
  padding: 0 0 16px 0;
  margin-bottom: 16px;
}
.body .chart .chart_right {
  width: calc(50% - 1px);
  padding: 0 0 16px 16px;
  position: relative;
}
.body .chart .chart_right_min {
  width: 100% !important;
  padding: 0 0 16px 0 !important;
}
.body .chart_min {
  margin-bottom: 0;
}
.body .chart_dvnone {
  padding-bottom: 0;
}
.body .dh_tabsData {
  position: absolute;
  left: 0;
  top: 17px;
  width: 100%;
  z-index: 999;
}
.body .dh_tabsData .chart_lenged {
  margin-right: 16px;
}
.body .dh_tabsData .chart_lenged span:nth-child(3) {
  background: #008FC7;
}
.body .dh_tabsData .chart_lenged .lenged_icon {
  background: #AFADAD;
}
.body .chart_bottom {
  border-bottom: 0;
  margin-bottom: 0;
  flex-wrap: wrap;
}
.body .chart_bottom .chart_Balance_Sheet {
  padding: 0;
  padding-left: 16px;
}
.body .chart_bottom .chart_Balance_Sheet_none {
  padding: 0 16px 0 0;
}
.body .chart_bottom .chart_left_none {
  padding: 0;
  margin: 0 !important;
}
.body .chart_bottom .chart_right {
  width: 100%;
  padding: 16px 0 0 0;
  margin: 16px 0 0 0;
}
.body .chart_bottom .chart_Cash_Flow_none {
  margin: 0;
}
.body .chart_bottom .chart_right_dvnone {
  margin: 0;
  padding: 0 0 0 16px;
}
.body .chart_gauge {
  height: calc(100% / 2);
}
.body .chart_gauge .chart_msg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}
.body .chart_gauge .chart_msg span {
  display: block;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}
.body .chart_gauge .chart_msg span:last-child {
  color: #008FC7;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  margin-top: 4px;
}
