.chart_msg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}
.chart_msg span {
  display: block;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}
.chart_msg span:last-child {
  color: #008FC7;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  margin-top: 4px;
}
.guage_con {
  width: 100%;
  text-align: center;
}
.chart_left {
  width: 50%;
  height: 100%;
  padding: 0 16px 16px 0;
  position: relative;
}
.chart_right {
  width: calc(50% - 1px);
  padding: 0 0 16px 16px;
  position: relative;
}
