.right_con {
  height: 100%;
  position: relative;
}
.right_con .fold_con {
  padding: 0 16px;
  height: 32px;
  min-height: 32px;
}
.right_con .criteria_title_con {
  align-items: center;
  min-height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  padding: 0 8px;
}
.right_con .criteria_title_con img {
  margin-right: 4px;
}
.right_con .papge_msg {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
.right_con .page_top_con {
  padding: 0 16px;
}
