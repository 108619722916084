.disclaimerCon {
  padding: 20px 0;
}
.disclaimerCon span {
  display: block;
  margin-bottom: 8px;
}
.disclaimerConMobile {
  padding-top: 80px;
}
