.quote_layout {
  margin-left: 72px;
}
.quote_layout .quote_left {
  width: 30%;
  position: relative;
}
.quote_layout .quote_leftMobile {
  width: 100%;
  border-right: 0;
}
.quote_layout .quote_right .quote_right_fold {
  height: 32px;
  padding: 0 16px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
.zoom_chart {
  position: absolute;
  width: calc(100% - 72px);
  min-height: 400;
  z-index: 100000;
}
.zoom_chart.tablet {
  width: 100%;
  height: calc(100% - 64px - 48px);
  /* top 48px, bottom 64px */
}
.zoom_chart.mobile {
  top: 48px;
  bottom: 64px;
  width: 100%;
  height: auto;
  /* top 48px, bottom 64px */
  overflow-y: hidden;
}
