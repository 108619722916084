.Chart5 {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.Chart5-OHLCTable {
  margin-bottom: 10px;
  padding: 10px;
  position: absolute;
  width: 100%;
}
.Chart5-MainTooltip {
  position: absolute;
  line-height: 18px;
  height: auto;
  left: 12px;
  width: calc(100% - 86px);
  font-size: 12px;
  margin-top: 5px;
  color: #8687ab;
  top: -1px;
}
.Chart5-TATooltip {
  position: absolute;
  line-height: 18px;
  height: auto;
  left: 12px;
  right: 61px;
  font-size: 12px;
  bottom: 89px;
  color: #8687ab;
}
.ChartWidget.full .Chart5 {
  margin-top: 0;
}
.ChartWidget.full .Chart5-MainTooltip {
  line-height: 18px;
  font-size: 12px;
  margin-top: -5px;
}
.ChartWidget.full .Chart5-TATooltip {
  top: auto;
  bottom: 89px;
}
.canvasbox {
  padding-top: 10px;
}
.scrollpane-ScrollTooltip {
  position: absolute;
  line-height: 18px;
  height: auto;
  width: auto;
  left: 12px;
  margin-top: 15px;
}
.scrollpane {
  transform: translateY(0px);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  height: 80px;
  z-index: 1;
  border-top: 1px solid #dddddd;
  /*
  -webkit-box-shadow: 0 -1px 2px #888888;
  box-shadow: 0 -1px 2px #888888;
  */
  display: flex;
  flex-wrap: wrap;
  transition: transform 200ms ease 0s;
}
.scrollpane .scrollcanvasbox {
  margin-top: 25px;
}
.scrollpane .openpanehandle {
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -10px;
  position: relative;
  z-index: 100;
}
.openpanehandleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.openpanehandle {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-repeat: no-repeat;
}
.openpanehandle.zommIn {
  background-image: url(../../asserts/imgs/chart/icon_zoom_in.png);
  position: relative;
  z-index: 100;
  top: -10px;
  opacity: 0.8;
}
.openpanehandle.down {
  background-image: url(../../asserts/imgs/chart/icon_expand.png);
  position: relative;
  z-index: 100;
  top: -10px;
  opacity: 0.8;
  margin-left: 10px;
  margin-right: 10px;
}
.openpanehandle.zommOut {
  background-image: url(../../asserts/imgs/chart/icon_zoom_out.png);
  position: relative;
  z-index: 100;
  top: -10px;
  opacity: 0.8;
}
.tooltips {
  top: -120px;
  position: relative;
  margin-left: 10px;
}
#canvasbox:hover #chtip {
  display: block;
}
#chtip {
  display: none;
  position: absolute;
  min-height: 20px;
  line-height: 15px;
  width: 250px;
  padding: 0 4px;
  color: #000000;
  background: #ffffff;
  opacity: 0.9;
  border: 1px solid #dddddd;
  box-shadow: 0 2px 2px #888888;
  z-index: 50;
}
#chtip #mainchart {
  margin-top: 2px;
}
#chtip #mainchart div {
  height: 20px;
}
#chtip .chtiplbl {
  text-align: left;
  float: left;
}
#chtip .chtipval {
  text-align: right;
  float: right;
}
#trendlinepopupmenu {
  display: none;
  z-index: 102;
  position: absolute;
  top: 25px;
  right: 64px;
  min-height: 40px;
  line-height: 40px;
  opacity: 0.3;
  color: #bbbbbb;
  background: #fcfcfc;
  /* border: 1px solid #DDDDDD; */
  box-shadow: 0 2px 2px #888888;
}
#trendlinepopupmenu #editform_t {
  display: block;
  width: 490px;
}
#trendlinepopupmenu .actionbtngrp {
  float: right;
  margin-top: 6px;
  margin-right: 6px;
}
#trendlinepopupmenu .actionbtngrp .btnsep {
  float: left;
  margin: 0 2px 0 6px;
  width: 1px;
  height: 28px;
  border-left: 1px dotted #dddddd;
}
#trendlinepopupmenu .actionbtngrp .btnsep .doneButton {
  width: 105px;
}
#trendlinepopupmenu .actionbtngrp button {
  float: left;
  width: auto;
  height: 28px;
  line-height: normal;
  margin: 0 0 0 4px;
  cursor: pointer;
  border: 1px solid #dddddd;
}
#trendlinepopupmenu .actionbtngrp button.deletebutton {
  padding: 0 8px;
  background: #800000;
  color: #ffffff;
}
#trendlinepopupmenu .actionbtngrp button.copybutton {
  padding: 0 8px;
}
#trendlinepopupmenu .actionbtngrp button.doneButton {
  width: 105px;
}
#trendlinepopupmenu .stylebox {
  float: left;
  margin-left: 8px;
}
#trendlinepopupmenu .stylebox .styleboxlbl {
  float: left;
  margin-right: 4px;
}
#trendlinepopupmenu .stylebox input,
#trendlinepopupmenu .stylebox select {
  color: #828282;
}
#trendlinepopupmenu .stylebox .selectbox,
#trendlinepopupmenu .inputbox,
#trendlinepopupmenu .colorpicker {
  float: left;
  margin: 10px 4px 10px 0;
}
#trendlinepopupmenu .colorpicker {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 1px solid #dddddd;
}
#trendlinepopupmenu button {
  background: #c0c0c0;
  white-space: nowrap;
  padding: 0;
  margin: 0;
}
#trendlinepopupmenu .inputbox {
  position: relative;
  width: auto;
  height: 18px;
  padding: 0 2px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
}
#trendlinepopupmenu .inputbox input {
  float: left;
  padding: 0 2px;
  margin: 0;
  border: none;
  height: 18px;
  line-height: 18px;
}
#trendlinepopupmenu .selectbox select {
  float: left;
  padding: 0 16px 0 4px;
  margin: 0;
  border: none;
  outline: none;
  overflow: hidden;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: url(../../asserts/imgs/chart/select-arrow.png) no-repeat right center #f4f4f4;
}
#trendlinepopupmenu:hover {
  color: #ffffff;
  background-color: #66aacc;
  opacity: 1;
}
.eventBox {
  font-size: 11px;
  position: absolute;
  margin-left: 0px;
  margin-top: 0px;
  z-index: 10;
  background-color: white;
  color: red;
  /* padding: 3px; */
  width: 250px;
  padding: 0 4px;
  color: #000000;
  background: #ffffff;
  opacity: 0.9;
  border: 1px solid #dddddd;
  box-shadow: 0 2px 2px #888888;
}
