.header_con {
  height: 75px;
  min-height: 75px;
  align-items: center;
  position: relative;
}
.header_con .msg_con {
  padding: 0;
  min-width: 175px;
  min-height: 48px;
  margin-right: 4px;
}
.header_con .msg_con .msg_dropdown_con {
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  margin-bottom: 6px;
  cursor: pointer;
  position: relative;
}
.header_con .msg_con .msg_dropdown_con .msg_dropdown_img {
  width: 13px;
  height: 12px;
}
.header_con .msg_con .msg_dropdown_con label {
  cursor: pointer;
}
.header_con .msg_con .msg_dropdown_con .dropsDown_con {
  min-height: 100px;
  max-height: 300px;
  z-index: 9;
  position: absolute;
}
.header_con .msg_con .msg_dropdown_val {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}
.header_con .msg_con_mobile {
  padding: 10px 16px;
  min-width: auto;
  min-height: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.header_con .header_right {
  flex: 1 1;
  -webkit-flex: 1;
  align-items: center;
}
.header_con .header_right .input_con_mobile {
  width: calc(45% - 8px);
}
.header_con .header_right_mobile {
  padding: 12px 16px;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.header_con_mobile {
  height: auto;
  min-height: auto;
  margin: 0;
}
