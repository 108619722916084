.alert_modal_mask {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999999;
  padding: 0 16px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert_modal_mask .alert_modal {
  display: flex;
  flex-direction: column;
  width: 528px;
  max-height: 490px;
  font-weight: normal;
  background: #222429;
}
.alert_modal_mask .alert_modal .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  min-height: 48px;
  font-size: 16px;
  font-weight: bold;
  box-sizing: border-box;
  border-bottom: 1px solid #373737;
}
.alert_modal_mask .alert_modal .title img {
  margin: 0;
}
.alert_modal_mask .alert_modal .header {
  height: 32px;
  padding: 0 8px;
}
.alert_modal_mask .alert_modal .header span {
  font-size: 10px;
  line-height: 12px;
}
.alert_modal_mask .alert_modal .header .sort_button {
  cursor: pointer;
  display: flex;
  justify-content: end;
  align-items: center;
}
.alert_modal_mask .alert_modal .header .sort_button svg {
  margin-left: 5px;
  width: 7px;
}
.alert_modal_mask .alert_modal .header .sort_button .asc path:nth-child(2) {
  fill: #ffffff;
}
.alert_modal_mask .alert_modal .header .sort_button .asc.light path:nth-child(2) {
  fill: #333333;
}
.alert_modal_mask .alert_modal .header .sort_button .desc path:nth-child(1) {
  fill: #ffffff;
}
.alert_modal_mask .alert_modal .header .sort_button .desc.light path:nth-child(1) {
  fill: #333333;
}
.alert_modal_mask .alert_modal .content {
  overflow-y: overlay;
}
.alert_modal_mask .alert_modal .content .bold {
  font-weight: bold;
}
.alert_modal_mask .alert_modal .body_one {
  min-height: 32px;
  cursor: pointer;
  padding: 0 8px;
}
.alert_modal_mask .alert_modal .common {
  align-items: center;
}
.alert_modal_mask .alert_modal .common > span {
  text-align: left;
  padding-right: 8px;
}
.alert_modal_mask .alert_modal .common > span:first-child {
  width: 56px;
  padding-left: 8px;
}
.alert_modal_mask .alert_modal .common > span:nth-child(2) {
  width: calc(100% - 380px);
}
.alert_modal_mask .alert_modal .common > span:nth-child(3) {
  width: 80px;
}
.alert_modal_mask .alert_modal .common > span:nth-child(4) {
  width: 80px;
  text-align: right;
}
.alert_modal_mask .alert_modal .common > span:last-child {
  width: 164px;
  text-align: right;
  color: #828282;
}
.alert_modal_mask .alert_modal .footer {
  position: relative;
  min-height: 98px;
}
.alert_modal_mask .alert_modal .footer .checkbox_container {
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  align-items: center;
}
.alert_modal_mask .alert_modal .footer .checkbox_container .checkbox {
  display: none;
}
.alert_modal_mask .alert_modal .footer .checkbox_container .checkbox + label {
  cursor: pointer;
  display: inline-block;
  padding: 0;
  width: 16px;
  height: 16px;
  background: url("../../../asserts/imgs/svg/checkbox-blank-line.svg") no-repeat;
  background-size: 16px;
}
.alert_modal_mask .alert_modal .footer .checkbox_container .checkbox:checked + label {
  cursor: pointer;
  display: inline-block;
  padding: 0;
  width: 16px;
  height: 16px;
  background: url("../../../asserts/imgs/svg/checkbox-fill.svg") no-repeat;
  background-size: 16px;
}
.alert_modal_mask .alert_modal .footer .checkbox_container .text {
  cursor: pointer;
  padding-left: 10px;
  font-size: 14px;
  line-height: 1;
}
.alert_modal_mask .alert_modal .footer .botton_button_bar {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 55%;
}
.alert_modal_mask.chinese .common > span:first-child {
  width: 56px;
  padding-left: 8px;
}
.alert_modal_mask.chinese .common > span:nth-child(2) {
  width: calc(100% - 380px);
}
.alert_modal_mask.chinese .common > span:nth-child(3) {
  width: 80px;
}
.alert_modal_mask.chinese .common > span:nth-child(4) {
  width: 80px;
  text-align: right;
}
.alert_modal_mask.chinese .common > span:last-child {
  width: 164px;
  text-align: right;
  color: #828282;
}
.alert_modal_mask.mobile .alert_modal {
  width: inherit;
}
.alert_modal_mask.mobile .alert_modal .content {
  height: 275px;
}
.alert_modal_mask.mobile .alert_modal .common.body_one {
  min-height: 55px;
  font-size: 16px;
}
.alert_modal_mask.mobile .alert_modal .common .name_mobile {
  padding: 0;
  font-size: 14px;
}
.alert_modal_mask.mobile .alert_modal .common > span {
  text-align: left;
  padding-right: 8px;
}
.alert_modal_mask.mobile .alert_modal .common > span:first-child {
  width: calc(100% - 250px);
  padding-left: 8px;
}
.alert_modal_mask.mobile .alert_modal .common > span:nth-child(2) {
  width: 50px;
}
.alert_modal_mask.mobile .alert_modal .common > span:nth-child(3) {
  width: 100px;
  text-align: right;
}
.alert_modal_mask.mobile .alert_modal .common > span:last-child {
  width: 100px;
  text-align: right;
}
.alert_modal_mask.mobile .alert_modal .header span {
  font-size: 13px !important;
}
.alert_modal_mask.mobile .alert_modal .footer {
  height: 112px;
}
.alert_modal_mask.mobile .alert_modal .footer .botton_button_bar {
  width: 100%;
}
.alert_modal_mask.mobile.chinese .common > span:first-child {
  width: calc(100% - 255px);
  padding-left: 8px;
}
.alert_modal_mask.mobile.chinese .common > span:nth-child(2) {
  width: 65px;
  font-size: 14px;
}
.alert_modal_mask.mobile.chinese .common > span:nth-child(3) {
  width: 90px;
  text-align: right;
}
.alert_modal_mask.mobile.chinese .common > span:last-child {
  width: 100px;
  text-align: right;
}
