.streetev_con {
  flex: 1 1;
  -webkit-flex: 1;
  position: relative;
}
.streetev_con .title {
  font-size: 12px;
  line-height: 14px;
  color: #E0E0E0;
}
.streetev_con .left {
  height: 100%;
}
.streetev_con .left .header {
  padding: 16px;
}
.streetev_con .left .header .select_con {
  padding: 8px 16px;
  border-radius: 40px;
  cursor: pointer;
  margin-bottom: 8px;
  position: relative;
}
.streetev_con .left .header .select_con .select_img {
  transform: rotate(0deg);
  width: 15px;
  height: 14px;
}
.streetev_con .left .header .select_con .imgRotate {
  transform: rotate(180deg);
}
.streetev_con .left .header .reset {
  text-align: center;
}
.streetev_con .left .header .reset span {
  color: #56CCF2;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  border-bottom: 1px solid #56CCF2;
}
.streetev_con .left .list {
  position: relative;
}
.streetev_con .left .list .list_item {
  padding: 8px 16px;
  cursor: pointer;
}
.streetev_con .left .list .list_item .content {
  font-weight: 700;
  margin-bottom: 4px;
}
.streetev_con .left .list .list_item .time {
  color: #828282;
  font-size: 12px;
  line-height: 14px;
}
.streetev_con .right {
  width: calc(70% - 1px);
  padding: 16px 64px 60px 80px;
  position: relative;
}
.streetev_con .right .right_header {
  align-items: center;
  height: 47px;
  min-height: 47px;
  font-size: 16px;
  font-weight: 700;
}
.streetev_con .right .right_header img {
  margin: 0 4px 0 8px;
}
.streetev_con .right .right_content .title {
  display: block;
  margin-bottom: 16px;
}
.streetev_con .right .right_content .content {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 18px;
}
.streetev_con .right .right_content .time {
  color: #828282;
  display: block;
  margin-bottom: 16px;
}
.streetev_con .right .right_content .table_con .table_con_one {
  width: calc(100% / 4);
}
.streetev_con .right .right_content .table_con .table_con_one span {
  padding: 0 8px;
  min-height: 32px;
  white-space: nowrap;
}
.streetev_con .right .right_content .table_con .table_con_one .table_con_header {
  font-size: 10px;
  line-height: 12px;
}
.streetev_con .right .right_content .table_con .table_con_one_1 {
  width: calc(100% / 5);
}
.streetev_con .right .right_content .table_con .table_con_one_2 {
  width: 30%;
}
.streetev_con .right .right_content .table_con .table_con_one_3 {
  width: 35%;
}
.streetev_con .right .right_content .table_con_moble {
  overflow-x: auto;
}
.streetev_con .right .right_content .table_con_moble::-webkit-scrollbar {
  height: 6px;
}
.streetev_con .right_min {
  padding: 0;
}
.streetev_con .right_min .right_content {
  padding-bottom: 40px;
}
.streetev_con .right_min .right_content .title,
.streetev_con .right_min .right_content .content,
.streetev_con .right_min .right_content .time,
.streetev_con .right_min .right_content .table_con,
.streetev_con .right_min .right_content .table_header {
  width: 80%;
  margin: 0 auto 16px auto;
}
.streetev_con .right_min .right_content .table_header {
  margin-bottom: 0;
}
.streetev_con .right_mobile .right_content .title,
.streetev_con .right_mobile .right_content .content,
.streetev_con .right_mobile .right_content .time,
.streetev_con .right_mobile .right_content .table_con,
.streetev_con .right_mobile .right_content .table_header {
  width: auto;
  margin: 0 16px 16px 16px;
}
.streetev_con .right_mobile .right_content .table_header {
  margin-bottom: 0;
}
.streetev_con .position_absolute {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.heightPer {
  height: 100%;
}
