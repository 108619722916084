.dropDown_content {
  position: absolute;
  top: 42px;
  right: 22px;
  z-index: 1000;
  min-width: 80px;
}
.dropDown_content .dropDown_content_child {
  min-height: 52px;
  font-size: 14px;
  cursor: pointer;
  padding: 0 !important;
  justify-content: center;
}
.dropDown_market {
  right: unset;
  left: 0;
  top: 36px;
  width: 100%;
}
.dropDown_market .dropDown_content_child {
  min-height: 40px;
  font-weight: 700;
  font-size: 18px;
}
