.quick_con {
  padding: 8px 16px 12px 16px;
}
.quick_con .preset_con {
  flex-wrap: wrap;
}
.quick_con .preset_con .preset_item {
  color: #FFFFFF;
  cursor: pointer;
  text-align: center;
  margin: 0  8px 8px 0;
  width: calc(100% / 2 - 4px);
  min-height: 40px;
  background: #333333;
  border-radius: 2px;
  line-height: 16px;
}
.quick_con .preset_con .preset_item:nth-child(2n) {
  margin-right: 0;
}
.quick_con .preset_con .preset_item .num {
  font-size: 11px;
  line-height: 13px;
  opacity: 0.6;
}
.quick_con .preset_con .preset_item_light {
  background: none;
  color: #333333;
  border: 1px solid #E0E0E0;
}
