.price_con {
  min-height: 192px;
}
.price_con .price_title {
  padding: 16px;
  align-items: flex-start;
}
.price_con .price_title img {
  margin-right: 8px;
}
.price_con button {
  width: 128px;
  height: 40px;
  bottom: 16px;
  right: 16px;
}
