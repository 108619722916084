.order_fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  -webkit-overflow-scrolling: touch;
}
.order_fixed .order_con .title_con {
  font-weight: 700;
  font-size: 16px;
  align-items: center;
  min-height: 48px;
  border-bottom: 1px solid #373737;
}
.order_fixed .order_con .detail_msg_con {
  min-height: 61px;
}
.order_fixed .order_con .detail_msg_con .detail_msg_title {
  display: block;
  font-size: 15px;
  margin-bottom: 10px;
}
.order_fixed .order_con .detail_msg_con .detail_msg_num {
  font-size: 20px;
  font-weight: 700;
}
.order_fixed .order_con .detail_input_con {
  height: 56px;
  min-height: 56px;
  padding: 0 16px;
  position: relative;
}
.order_fixed .order_con .detail_table_common {
  padding: 8px 16px;
}
.order_fixed .order_con .detail_table_common span {
  text-align: right;
  padding-right: 6px;
}
.order_fixed .order_con .detail_table_common span:first-child {
  width: 30%;
  text-align: left;
}
.order_fixed .order_con .detail_table_common span:nth-child(2) {
  width: 20%;
}
.order_fixed .order_con .detail_table_common span:nth-child(3) {
  width: 25%;
}
.order_fixed .order_con .detail_table_common span:last-child {
  width: 25%;
  padding-right: 0;
}
.order_fixed .order_con .detail_table_header {
  min-height: 46px;
}
.order_fixed .order_con .detail_table_header span {
  font-size: 13px;
  line-height: 15px;
}
.order_fixed .order_con .detail_table_body {
  min-height: 48px;
}
.order_fixed .order_con .detail_table_body .detail_table_name {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  display: block;
  margin-bottom: 2px;
}
.order_fixed .order_con .detail_table_body span:last-child {
  font-size: 16px;
}
