.modal_fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}
.modal_fixed .modal_con {
  z-index: 999999;
  width: 525px;
  height: 193px;
  background: #222429;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  overflow: hidden;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.modal_fixed .modal_con .title {
  align-items: center;
  padding: 25px 24px 0 24px;
  font-size: 16px;
  line-height: 19px;
}
.modal_fixed .modal_con .title img {
  margin: 0 11px 2px 0;
}
.modal_fixed .modal_con .title_mobile {
  padding: 25px 20px 0 20px;
}
.modal_fixed .modal_con button {
  width: 105px;
  height: 40px;
  background: #393939;
  border-radius: 3px;
  position: absolute;
  bottom: 20px;
  right: 22px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  padding: 0;
}
.modal_fixed .modal_con :global(.ant-btn:focus) {
  background: #393939;
}
.modal_fixed .modal_con .button_con {
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 16px;
}
.modal_fixed .modal_con .button_con button {
  position: static;
  width: 132px;
}
.modal_fixed .modal_con .button_con button:first-child {
  margin-right: 8px;
}
.modal_fixed .modal_con .button_con_mobile {
  width: 100%;
  right: 0;
  justify-content: space-between;
}
.modal_fixed .modal_con .button_con_mobile button {
  width: 45%;
}
.modal_fixed .modal_con .button_con_mobile button:first-child {
  margin-left: 20px;
}
.modal_fixed .modal_con .button_con_mobile button:last-child {
  margin-right: 20px;
}
.modal_fixed .modal_con .button_con_screener {
  bottom: 16px;
}
.modal_fixed .modal_con .button_con_screener_mobile button:first-child {
  width: 50%;
  margin: 0 12px 0 16px;
}
.modal_fixed .modal_con .button_con_screener_mobile button:last-child {
  margin-right: 16px;
  width: calc(50% - 12px);
}
.modal_fixed .modal_con_light {
  background: #FFFFFF;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}
