.pagination_con {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
}
.pagination_con li {
  background: none;
  border: 0;
  width: 32px;
  min-width: auto;
  height: 24px;
  line-height: 24px;
  font-family: 'Roboto';
  font-size: 12px;
}
.pagination_con li a {
  color: #828282 !important;
}
.pagination_con button {
  background: none !important;
  border: 0 !important;
}
.pagination_con button span {
  color: #828282 !important;
}
.pagination_con :global(.ant-pagination-item-active) {
  background: #008FC7;
  border-radius: 3px;
}
.pagination_con :global(.ant-pagination-item-active a) {
  color: #FFFFFF !important;
  padding: 0 !important;
}
.pagination_con :global(.ant-pagination-prev) {
  position: relative;
  bottom: 4px;
}
.pagination_con :global(.ant-pagination-next) {
  position: relative;
  bottom: 4px;
}
.pagination_con :global(.ant-pagination-item a) {
  padding: 0;
}
.pagination_con :global(.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis) {
  top: 2px;
  letter-spacing: 0;
  color: #828282;
  transform: scale(0.8);
}
.pagination_con :global(.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis) {
  top: 2px;
  letter-spacing: 0;
  color: #828282;
  transform: scale(0.8);
}
.pagination_con :global(.ant-pagination-item:hover) {
  background: #008FC7 !important;
  border-radius: 3px;
}
.pagination_con :global(.ant-pagination-item:hover a) {
  color: #FFFFFF !important;
}
.pagination_con_mobile {
  width: 100%;
}
.pagination_con_mobile li {
  font-size: 14px;
}
.pagination_con_mobile :global(.ant-pagination) {
  display: flex;
  display: -webkit-flex;
}
.prevnext_none :global(.ant-pagination-prev) {
  display: none;
}
.prevnext_none :global(.ant-pagination-next) {
  display: none;
}
.next_none :global(.ant-pagination-next) {
  display: none;
}
.prev_none :global(.ant-pagination-prev) {
  display: none;
}
.pagination_PC {
  height: 54px;
  margin: 0 16px;
}
