.left_con {
  padding-bottom: 20px;
  position: relative;
}
.screener_left_tab {
  padding: 12px 16px 8px 16px;
}
.criteria_con {
  padding: 16px;
  z-index: 10;
}
.criteria_con .criteria_btn {
  color: #FFFFFF;
  cursor: pointer;
  height: 40px;
  font-size: 16px;
  line-height: 19px;
  background: #373737;
  border-radius: 3px;
}
.criteria_con .criteria_btn img {
  margin-right: 10px;
}
.criteria_con .criteria_btn_notallowed {
  cursor: not-allowed;
}
.criteria_con .criteria_btn_result {
  width: 100%;
  margin: 8px 0;
}
.criteria_con .criteria_btn_light {
  color: #333333;
  background: none;
  border: 1px solid #E0E0E0;
}
.criteria_con .criteria_reset {
  text-align: center;
}
.criteria_con .criteria_reset span {
  font-size: 12px;
  line-height: 14px;
  color: #56CCF2;
  border-bottom: 1px solid #56CCF2;
  cursor: pointer;
}
