.tradeflow_con {
  min-height: 48px;
  padding: 4px 16px;
  border-bottom: 1px solid #373737;
}
.tradeflow_con_stauts .label_span {
  font-size: 10px;
  line-height: 12px;
  color: #008FC7;
  border: 1px solid #008FC7;
  border-radius: 3px;
  padding: 2px 5px;
  margin-left: 4px;
  vertical-align: middle;
  cursor: pointer;
}
.tradeflow_con_stauts .label_light {
  color: #009E9C;
  border: 1px solid #009E9C;
}
.body .name span {
  display: block;
  text-align: right;
}
.body .time div span {
  display: block;
  text-align: right;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
.body_mobile .tradeflow_con_stauts .label_span {
  font-size: 12px;
  line-height: 14px;
}
.body_mobile .body_name_mobileD {
  font-size: 16px;
  color: #FFFFFF;
}
.body_mobile .body_name_mobileW {
  font-size: 16px;
  color: #333333;
}
