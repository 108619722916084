.background {
  background-color: #373737;
}
.taconfbox > div > div > .menusubitem {
  min-height: 48px !important;
}
.reset-button {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  color: #56ccf2;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.seperate-line {
  height: 16px;
  border-left: 1px solid #373737;
}
.connection-box {
  position: absolute;
  width: 32px;
  height: 4px;
  background-color: white;
  z-index: 6;
}
.selectbox select {
  float: left;
  padding: 0 16px 0 4px;
  margin: 0;
  border: none;
  outline: none;
  overflow: hidden;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  background: url(../../../../asserts/imgs/chart/select-arrow.png) no-repeat right center #f4f4f4;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
select::-ms-expand {
  display: none;
}
.inputbox input {
  float: left;
  padding: 0 2px;
  margin: 0;
  border: none;
  height: 18px;
  line-height: 18px;
}
.menu-bg-close {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.menubox.open {
  top: 0px;
  right: -336px;
  height: 48px;
  /*width: 400px;*/
  z-index: 3;
  display: flex;
  /*justify-content: end;*/
  justify-content: space-between;
  /*
    #mi-period:hover {
      background-image: url(./../../../../asserts/imgs/chart/fubon/Period_outlined_light.svg);
      background-color: #008fc7;
    }
    */
  /*
    #mi-charttype:hover {
      background-image: url(./../../../../asserts/imgs/chart/fubon/candlestick_light.svg);
      background-color: #008fc7;
    }
    */
  /*
    #mi-drawtool:hover {
      background-image: url(./../../../../asserts/imgs/chart/fubon/Action_outlined_light.svg);
      background-color: #008fc7;
    }
    */
  /*
    #mi-ta:hover {
      background-image: url(./../../../../asserts/imgs/chart/fubon/Analysis_outlined_light.svg);
      background-color: #008fc7;

      .taselboxfilter {
        margin: 8px 8px;
      }
    }
    */
  /*
    #mi-zoom:hover {
      background-image: url(./../../../../asserts/imgs/chart/fubon/fullscreen_24px_rounded_light.svg);
      background-color: #008fc7;
    }
    */
}
.menubox.open .hide {
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}
.menubox.open .setting-icon.selected {
  background-color: #373737;
}
.menubox.open .setting-icon.selected path {
  fill: #008fc7;
}
.menubox.open .setting-option {
  position: absolute;
  top: 40px;
  left: -16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0 16px;
  width: calc(100% + 32px);
  height: 80px;
  background-color: #373737;
  z-index: 5;
}
.menubox.open .setting-option .setting-button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  flex: 1 1;
  height: 56px;
  border: 1px solid #ffffff;
  border-radius: 3px;
  line-height: 1;
  color: #ffffff;
  /* svg */
}
.menubox.open .setting-option .setting-button.selected {
  border: 1px solid #008fc7;
  background-color: #008fc7;
}
.menubox.open .setting-option .setting-button svg {
  margin-bottom: 5px;
  width: 20px;
  height: 20px;
}
.menubox.open .setting-option .setting-button:last-child {
  margin-right: 0;
}
.menubox.open .setting-option .setting-button:first-child svg rect {
  fill: #ffffff;
}
.menubox.open .setting-option .setting-button:first-child svg rect:first-child,
.menubox.open .setting-option .setting-button:first-child svg rect:nth-child(4) {
  fill: none;
  stroke: #ffffff;
}
.menubox.open .setting-option .setting-button:nth-child(2) svg path {
  fill: #ffffff;
}
.menubox.open .setting-option .setting-button:last-child svg rect {
  fill: #ffffff;
}
.menubox.open .openmenuhandle {
  float: left;
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-color: #f0f0f0;
}
.menubox.open .openmenuhandle .openmenuicon {
  width: 48px;
  height: 48px;
  background: url(../../../../asserts/imgs/chart/menu.png) center center no-repeat;
  transform: rotate(-90deg);
  transition: transform 300ms ease;
}
.menubox.open .period-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  height: 24px;
  border-radius: 3px;
  padding: 0 8px;
}
.menubox.open .period-button.chinese {
  padding: 0 5px;
}
.menubox.open .period-button:hover,
.menubox.open .period-button.selected {
  color: #ffffff;
  background-color: #008fc7;
}
.menubox.open #mi-period {
  /*background-image: url(./../../../../asserts/imgs/chart/btn_02_off.png);*/
  background-image: url(../../../../asserts/imgs/chart/fubon/Period_outlined.svg);
  background-size: 18px 18px;
}
.menubox.open #mi-period .menudropdown {
  display: none;
}
.menubox.open #mi-period-on {
  background-image: url(../../../../asserts/imgs/chart/fubon/Period_outlined_light.svg);
  background-size: 18px 18px;
  background-color: #008fc7;
}
.menubox.open #mi-period-on .msi-optbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.menubox.open #mi-period-on .msi-optbox .menusubitem {
  margin: 0;
  font-size: 12px;
  min-height: 32px;
  line-height: 32px;
}
.menubox.open #mi-period-on .msi-optbox .date-input-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: none;
}
.menubox.open #mi-period-on .msi-optbox .date-input-container > span {
  display: block;
  width: 55px;
  height: 32px;
  border: 1px solid #ffffff;
  border-radius: 3px;
}
.menubox.open #mi-period-on .menudropdown.selected,
.menubox.open #mi-period-on .menudropdown .selected {
  color: #008fc7;
}
.menubox.open #mi-charttype {
  background-image: url(../../../../asserts/imgs/chart/fubon/candlestick.svg);
}
.menubox.open #mi-charttype-on {
  background-image: url(../../../../asserts/imgs/chart/fubon/candlestick_blue.svg);
  background-color: #373737;
}
.menubox.open #mi-charttype-on .connection-box {
  top: 35px;
  right: 120px;
}
.menubox.open #mi-charttype-on select {
  padding-left: 8px;
  box-sizing: border-box;
  color: #828282;
}
.menubox.open #mi-charttype-on span.selected {
  color: #008fc7;
}
.menubox.open #mi-charttype-on .icon {
  position: relative;
  top: 7px;
  width: 16px;
  margin-right: 8px;
  pointer-events: none;
}
.menubox.open #mi-charttype-on .icon.selected path,
.menubox.open #mi-charttype-on .icon.selected rect {
  fill: #008fc7;
}
.menubox.open #mi-charttype-on .icon.selected.candlestick path,
.menubox.open #mi-charttype-on .icon.selected.candlestick rect {
  fill: none;
  stroke: #008fc7;
}
.menubox.open #mi-charttype-on .msi-optbox .menusubitem {
  margin: 0;
}
.menubox.open #mi-charttype-on .msiicon.line {
  background-image: url(../../../../asserts/imgs/chart/fubon/line-chart_light.svg);
  background-size: 16px 16px;
}
.menubox.open #mi-charttype-on .msiicon.candlestick {
  background-image: url(../../../../asserts/imgs/chart/fubon/candlestick_light.svg);
  background-size: 16px 16px;
}
.menubox.open #mi-charttype-on .msiicon.bar {
  background-image: url(../../../../asserts/imgs/chart/fubon/bar-chart_light.svg);
  background-size: 16px 16px;
}
.menubox.open #mi-drawtool {
  background-image: url(../../../../asserts/imgs/chart/fubon/Action_outlined.svg);
}
.menubox.open #mi-drawtool-on {
  background-image: url(../../../../asserts/imgs/chart/fubon/Action_outlined_blue.svg);
  background-color: #373737;
}
.menubox.open #mi-drawtool-on .connection-box {
  top: 35px;
  right: 80px;
}
.menubox.open #mi-drawtool-on select {
  padding-left: 8px;
  box-sizing: border-box;
  color: #828282;
}
.menubox.open #mi-drawtool-on .selected {
  color: #008fc7;
}
.menubox.open #mi-drawtool-on .icon {
  position: relative;
  top: 7px;
  width: 16px;
  margin-right: 8px;
}
.menubox.open #mi-drawtool-on .icon.selected path,
.menubox.open #mi-drawtool-on .icon.selected rect {
  fill: #008fc7;
}
.menubox.open #mi-drawtool-on .msi-optbox .menusubitem {
  margin: 0;
}
.menubox.open #mi-ta {
  background-image: url(../../../../asserts/imgs/chart/fubon/Analysis_outlined.svg);
}
.menubox.open #mi-ta-on {
  background-image: url(../../../../asserts/imgs/chart/fubon/Analysis_outlined_blue.svg);
  background-color: #373737;
  /*
      .header:hover {
        color: #ffffcc;
        font-weight: bold;
      }
      */
}
.menubox.open #mi-ta-on .connection-box {
  top: 35px;
  right: 40px;
}
.menubox.open #mi-ta-on select {
  padding-left: 8px;
  box-sizing: border-box;
}
.menubox.open #mi-ta-on input {
  color: #828282;
}
.menubox.open #mi-ta-on .taselboxfilter {
  margin: 8px 16px 16px;
  height: 32px;
  border: none;
  background-color: none;
  border-radius: 40px;
}
.menubox.open #mi-ta-on .taselboxfilter input {
  padding: 8px;
  width: 100%;
  height: 32px;
  border-radius: 40px;
}
.menubox.open #mi-ta-on .msiicon {
  background-image: url(../../../../asserts/imgs/chart/icon_remove.png);
}
.menubox.open #mi-ta-on .stylebox {
  float: right;
  margin-right: 0;
}
.menubox.open #mi-ta-on .stylebox .showtracker {
  float: left;
}
.menubox.open #mi-ta-on .msi-optbox {
  clear: both;
  padding-bottom: 16px;
}
.menubox.open #mi-ta-on .taselbox > .menusubitem {
  width: 45%;
  float: left;
}
.menubox.open #mi-ta-on .taselbox > .msi-optbox {
  padding-bottom: 0;
}
.menubox.open #mi-ta-on .msi-optbox.noclose {
  display: block;
  margin-top: 16px;
  padding-top: 16px;
}
.menubox.open #mi-ta-on .checkbox {
  position: relative;
  top: 7px;
  margin-right: 8px;
}
.menubox.open #mi-ta-on .flex {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.menubox.open #mi-ta-on .flex .menusubitem.msiaction {
  width: 266px;
}
.menubox.open #mi-ta-on .inputbox.taparam {
  border-radius: 40px;
}
.menubox.open #mi-ta-on .inputbox.taparam input {
  border-radius: 40px;
  color: #828282;
}
.menubox.open #mi-ta-on .selectbox > select {
  color: #828282;
}
.menubox.open #mi-ta-on .techicalindicator .box-container,
.menubox.open #mi-ta-on .techicaloverlay .box-container {
  padding: 0 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 32px;
  border-radius: 40px;
  background-color: #484848;
}
.menubox.open #mi-ta-on .techicalindicator .box-container span,
.menubox.open #mi-ta-on .techicaloverlay .box-container span {
  position: relative;
  top: -3px;
  display: inline-block;
  height: 32px;
  line-height: 32px;
}
.menubox.open #mi-ta-on .techicalindicator .close-icon,
.menubox.open #mi-ta-on .techicaloverlay .close-icon {
  position: relative;
  top: 4px;
  width: 16px;
  min-width: 16px;
  margin-left: 8px;
}
.menubox.open #mi-zoom {
  background-image: url(../../../../asserts/imgs/chart/fubon/fullscreen_24px.svg);
}
.menubox.open #mi-zoom-on {
  background-image: url(../../../../asserts/imgs/chart/fubon/fullscreen-exit.svg);
}
.menubox.open #mi-compare {
  background-image: url(../../../../asserts/imgs/chart/btn_05_off.png);
}
.menubox.open #mi-compare-on {
  background-image: url(../../../../asserts/imgs/chart/btn_05_on.png);
  background-color: #008fc7;
}
.menubox.open #mi-compare:hover {
  background-image: url(../../../../asserts/imgs/chart/btn_05_on.png);
  background-color: #008fc7;
}
.menubox.open #mi-viewswitch {
  background-image: url(../../../../asserts/imgs/chart/btn_08_off.png);
}
.menubox.open #mi-viewswitch-on {
  background-image: url(../../../../asserts/imgs/chart/btn_08_on.png);
  background-color: #008fc7;
}
.menubox.open #mi-viewswitch-on span:hover {
  color: #ffffcc;
  font-weight: bold;
}
.menubox.open #mi-viewswitch:hover {
  background-image: url(../../../../asserts/imgs/chart/btn_08_on.png);
  background-color: #008fc7;
}
.menubox.open #mi-setting {
  background-image: url(../../../../asserts/imgs/chart/btn_06_off.png);
}
.menubox.open #mi-setting-on {
  background-image: url(../../../../asserts/imgs/chart/btn_06_on.png);
  background-color: #333333;
}
.menubox.open #mi-setting:hover {
  background-image: url(../../../../asserts/imgs/chart/btn_06_on.png);
  background-color: #333333;
}
.menubox.open .flex-icon-container {
  display: flex;
  align-items: center;
  /*
      &.mobile {
        justify-content: space-between;
        height: 44px;
        line-height: 30px;

        .date-input-container {
          margin-right: unset !important;

          & > span {
            flex: 1 0 21%;
          }

          .customdaterange {
            flex: 1 0 71.8%;
            margin-left: 8px;
          }
        }

        &.right {
          height: 40px;
        }
      }
      */
}
.menubox.open .flex-icon-container.left > div {
  font-size: 12px;
}
.menubox.open .flex-icon-container.left > div:last-child {
  font-size: 14px;
}
.menubox.open .flex-icon-container.right {
  /*
        &.mobile {
          .menuitem {
            margin-left: 0;
            margin-right: 8px;
            background-size: 24px 24px;
          }

          .empty {
            width: 36px;
            height: 36px;
          }
        }
        */
}
.menubox.open .flex-icon-container.right .menuitem {
  margin-left: 8px;
  height: 28px;
}
.menubox.open .menuitem .open {
  display: block;
}
.menubox.open .menuitem .open .menudropdown {
  position: absolute;
  top: 48px;
  left: 0;
  width: 300px;
  max-height: 508px;
  overflow-y: auto;
  /*
          -webkit-box-shadow: 0 2px 2px #888888;
          box-shadow: 0 2px 2px #888888;
          */
  background-color: #008fc7;
  z-index: 100 !important;
}
.menubox.open .menuitem .open .menudropdown .radiobutton {
  float: left;
  margin: 7px 4px 7px 0px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../../../../asserts/imgs/chart/radio.png);
}
.menubox.open .menuitem .open .menudropdown .msi-sep {
  height: 0px;
  margin: 4px 8px;
  border-bottom: 1px solid #373737;
}
.menubox.open .menuitem .open .menudropdown .menusubitem {
  position: relative;
  min-height: 40px;
  line-height: 40px;
  margin: 0 8px;
  cursor: pointer;
  color: #ffffff;
}
.menubox.open .menuitem .open .menudropdown .menusubitem .displayname .nm {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.menubox.open .menuitem .open .menudropdown .msi-optbox .menusubitem.msi-box > span {
  display: block;
  float: none;
  margin: 0;
  text-align: center;
}
.menubox.open .menuitem {
  width: 16px;
  height: 16px;
  padding: 4px 8px;
  box-sizing: content-box;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}
.menubox.open .menuitem .menudropdown {
  position: absolute;
  top: 38px;
  left: 0;
  width: 300px;
  max-height: 508px;
  overflow-y: auto;
  /*
        -webkit-box-shadow: 0 2px 2px #888888;
        box-shadow: 0 2px 2px #888888;
        */
  /*background-color: #008FC7;*/
  background-color: #373737;
  z-index: 5;
  left: auto;
  right: 0;
}
.menubox.open .menuitem .menudropdown .sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100px;
  z-index: 9;
}
.menubox.open .menuitem .menudropdown .msi-grplbl {
  min-height: 40px;
  line-height: 40px;
  margin: 4px 16px 0 16px;
  font-weight: bold;
  cursor: initial;
  color: #ffffff;
  border-top: 1px solid #828282;
  /*background-color: #5588aa;*/
  /*background-color: #484848;*/
}
.menubox.open .menuitem .menudropdown .msi-grplbl.top {
  border-top: none;
  margin: 0;
  padding: 4px 16px 0 16px;
}
.menubox.open .menuitem .menudropdown div.optboxarrow {
  position: absolute;
  top: 11px;
  right: 0px;
  width: 8px;
  height: 16px;
  transition: transform 100ms ease;
  /*
          background: url(./../../../../asserts/imgs/chart/arrow_optbox.png)
            center center no-repeat;
            */
  background: url(../../../../asserts/imgs/chart/fubon/dropdown-down.svg) center center no-repeat;
}
.menubox.open .menuitem .menudropdown .radiobutton {
  float: left;
  margin: 7px 4px 7px 0px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../../../../asserts/imgs/chart/radio.png);
}
.menubox.open .menuitem .menudropdown .radiobutton-selected {
  float: left;
  margin: 7px 4px 7px 0px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../../../../asserts/imgs/chart/radio-selected.png);
}
.menubox.open .menuitem .menudropdown .msi-sep {
  height: 0px;
  margin: 4px 8px;
  border-bottom: 1px solid #474747;
}
.menubox.open .menuitem .menudropdown .menusubitem {
  position: relative;
  min-height: 40px;
  line-height: 40px;
  margin: 0 16px;
  cursor: pointer;
  color: #ffffff;
}
.menubox.open .menuitem .menudropdown .menusubitem .customdaterang span {
  float: left;
  margin-left: 4px;
}
.menubox.open .menuitem .menudropdown .menusubitem div.b2dbtn {
  display: none;
  position: absolute;
  top: 7px;
  right: 20px;
  width: 24px;
  height: 24px;
  background: url(../../../../asserts/imgs/chart/reset.png) center center no-repeat;
}
.menubox.open .menuitem .menudropdown .menusubitem .stylebox {
  position: relative;
  height: 40px;
  margin-left: 0;
}
.menubox.open .menuitem .menudropdown .menusubitem .stylebox .selectbox,
.menubox.open .menuitem .menudropdown .menusubitem .stylebox .inputbox,
.menubox.open .menuitem .menudropdown .menusubitem .stylebox .colorpicker {
  float: left;
  margin: 10px 4px 10px 0;
}
.menubox.open .menuitem .menudropdown .menusubitem .stylebox .styleboxlbl {
  float: left;
  margin-right: 4px;
}
.menubox.open .menuitem .menudropdown .menusubitem .stylebox .inputbox {
  border-radius: 40px;
}
.menubox.open .menuitem .menudropdown .menusubitem .stylebox .inputbox input {
  float: left;
  padding: 0 2px;
  margin: 0;
  border: none;
  height: 20px;
  line-height: 20px;
  color: #828282;
  border-radius: 40px;
}
.menubox.open .menuitem .menudropdown .menusubitem .checkboxbutton {
  float: left;
  margin: 7px 4px 7px 0px;
  width: 16px;
  height: 24px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../../../../asserts/imgs/chart/checkbox.png);
}
.menubox.open .menuitem .menudropdown .menusubitem .checkboxbutton-selected {
  float: left;
  margin: 7px 4px 7px 0px;
  width: 16px;
  height: 24px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../../../../asserts/imgs/chart/checkbox-selected.png);
}
.menubox.open .menuitem .menudropdown .menusubitem div.msiicon {
  float: left;
  margin: 4px 4px 4px 0px;
  width: 24px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
}
.menubox.open .menuitem .menudropdown .menusubitem .pval {
  margin-left: 5px;
}
.menubox.open .menuitem .menudropdown .menusubitem #msi-viewmainchart.msiicon {
  background-image: url(../../../../asserts/imgs/chart/icon_viewmainchart.png);
}
.menubox.open .menuitem .menudropdown .msi-optbox .taparam {
  float: left;
  margin-top: 10px;
  margin-left: 8px;
}
.menubox.open .menuitem .menudropdown .msi-optbox .taparamlbl {
  float: left;
}
.menubox.open .menuitem .menudropdown .msi-optbox .menusubitem.msi-box > span {
  display: block;
  float: none;
  margin: 0;
  text-align: center;
}
.menubox.open .menuitem .menudropdown .msi-box {
  float: left;
  width: 56px;
  height: 32px;
  margin: 4px 0 16px 4px;
  border: 1px solid #dddddd;
  border-radius: 3px;
}
.menubox.open .menuitem .menudropdown .msi-box.selected {
  background-color: #77ccff;
}
.menubox.open .menuitem .menudropdown .msi-box-c {
  clear: left;
  width: 86px;
}
.menubox.open .menuitem .menudropdown .customdaterange {
  /*
          position: absolute;
          top: -1px;
          left: 87px;
          */
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  width: 198px;
  border-radius: 40px;
  background-color: #ffffff;
}
.menubox.open .menuitem .menudropdown .customdaterange span {
  color: #bbbbbb;
}
.menubox.open .menuitem .menudropdown .customdaterange .dateinputbox {
  top: 7px;
  border: none;
}
.menubox.open .menuitem .menudropdown .customdaterange .dateinputbox input {
  text-align: center;
  width: 75px;
  line-height: 1;
  color: #bbbbbb;
}
.menubox.open .menuitem .menudropdown .colorpicker {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 1px solid #dddddd;
}
.menubox.open .menuitem .menudropdown .inputbox {
  position: relative;
  width: auto;
  height: 20px;
  padding: 0 2px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
}
.menubox.open .menuitem .menudropdown .selectbox {
  position: relative;
  width: auto;
  height: 20px;
  border-radius: 40px;
}
.menubox.open .menuitem .menudropdown .selectbox select {
  width: 80px;
  font-size: 14px;
  padding: 0;
  border-radius: 40px;
}
.menubox .openmenuhandle {
  float: left;
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-color: #f0f0f0;
}
.menubox .openmenuhandle .openmenuicon {
  width: 48px;
  height: 48px;
  background: url(../../../../asserts/imgs/chart/menu.png) center center no-repeat;
}
#addcompricinputbox {
  position: relative;
  height: 36px;
}
#addcompricinputbox .input-box-ric-search-result-dropdown {
  position: fixed !important;
}
#addcompricinputbox .powersearchbox {
  top: 4px;
  left: 8px;
  position: absolute;
  height: 28px;
  line-height: 28px;
  width: 300px;
}
.msi-optbox {
  clear: both;
  /*background-color: #66aacc;*/
}
.nocomparelbl {
  margin-left: 4px;
  height: 40px;
  line-height: 40px;
  cursor: initial;
  color: #bbbbbb;
}
.ricinputbox {
  position: absolute;
  left: 110px;
  width: 176px;
  top: 0;
  bottom: 0;
}
.modebox {
  opacity: 1;
  z-index: 2;
}
.selectbox .assettypeselectbox {
  position: absolute;
  width: 106px;
}
.selectbox .assettypeselectbox select {
  width: 106px !important;
}
button#addcompricbutton {
  float: right;
  width: 80px;
  height: 28px;
  margin-top: 4px;
  margin-right: 8px;
  cursor: pointer;
  border: 1px solid #dddddd;
}
.notasellbl {
  margin-left: 16px;
  height: 40px;
  line-height: 40px;
  cursor: initial;
  color: #bbbbbb;
}
.editbox .selectbox select {
  width: 106px;
  height: 26px !important;
  line-height: 26px !important;
}
.ricinputform .selectbox select {
  width: 169px;
  height: 26px !important;
  line-height: 26px !important;
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.powersearchbox .editbox .closeeditbox {
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 28px;
  background: url(../../../../asserts/imgs/chart/closeeditbox.png) center center no-repeat;
  transform: rotate(180deg);
}
.resultdropdown {
  position: absolute;
  width: 173px;
  left: 0;
  right: -1px;
  top: 30px;
  min-height: 40px;
  max-height: 402px;
  line-height: 40px;
  text-indent: 4px;
  overflow-y: auto;
  cursor: initial;
  background-color: #ffffff;
  border: 1px solid #bbbbbb;
  box-shadow: 0 0 4px #888888;
  z-index: 500;
}
.resultdropdown .result {
  position: relative;
  margin: 1px;
  height: 38px;
  line-height: 38px;
  cursor: pointer;
}
.resultdropdown .result .code {
  position: absolute;
  top: 6px;
  height: 10px;
  line-height: 10px;
  left: 4px;
  right: 4px;
  font-weight: bold;
  color: #666600;
  background: transparent;
}
.resultdropdown .result .nm {
  background: transparent;
  position: absolute;
  top: 16px;
  height: 22px;
  line-height: 22px;
  left: 4px;
  right: 4px;
  font-size: 13px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.floatmsibox,
.floatmsibox .menusubitem {
  display: flex;
  flex-wrap: wrap;
}
.open-tooltip .react-tooltip-lite {
  width: auto !important;
}
.menubox.tablet.open .flex-icon-container .date-input-container {
  margin-right: unset !important;
}
.menubox.tablet.open .flex-icon-container .date-input-container > span {
  flex: 1 0 21%;
}
.menubox.tablet.open .flex-icon-container .date-input-container .customdaterange {
  flex: 1 0 71.8%;
  margin-left: 8px;
}
.menubox.tablet .taconfbox > div > div > .menusubitem {
  min-height: auto !important;
  margin-bottom: 16px !important;
}
.menubox.tablet #mi-period-on .msi-optbox.special-period-box {
  align-items: center;
}
.menubox.tablet #mi-period-on .msi-optbox.special-period-box .menusubitem {
  margin-right: 8px;
  flex: 1 0 21%;
}
.menubox.tablet #mi-period-on .msi-optbox.special-period-box .menusubitem:last-child {
  margin: 0;
}
.menubox.tablet #mi-period-on .msi-optbox .menusubitem {
  margin-right: 8px;
  flex: 1 0 21%;
}
.menubox.tablet #mi-period-on .msi-optbox .menusubitem:nth-child(4),
.menubox.tablet #mi-period-on .msi-optbox .menusubitem:nth-child(8),
.menubox.tablet #mi-period-on .msi-optbox .menusubitem:nth-child(12) {
  margin: 0;
}
.menubox.tablet #mi-period-on .msi-optbox .customdaterange {
  width: 80%;
}
.menubox.tablet #mi-ta-on .menusubitem .msi-optbox {
  margin-bottom: 16px;
}
.menubox.tablet #mi-ta-on .menusubitem .msi-optbox .menusubitem {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.menubox.tablet #mi-ta-on .menusubitem .msi-optbox .menusubitem element {
  float: initial;
}
.menubox.tablet #mi-ta-on .menusubitem .msi-optbox .menusubitem .flexbox {
  display: flex;
}
.menubox.tablet #mi-ta-on .menusubitem .msi-optbox .stylebox {
  height: auto;
}
.menubox.tablet #mi-ta-on .menusubitem .box-container {
  display: flex;
  align-items: center;
  padding-right: 16px;
  max-width: 85%;
  height: auto;
  min-height: 32px;
}
.menubox.tablet #mi-ta-on .menusubitem .box-container span {
  display: block;
  top: initial;
  /*width: calc(100% - 24px);*/
  height: auto;
  line-height: 24px;
}
.menubox.tablet #mi-ta-on .menusubitem .box-container svg {
  top: initial;
  height: 24px;
}
.menubox.mobile.open .flex-icon-container {
  justify-content: space-between;
  height: 44px;
  line-height: 30px;
}
.menubox.mobile.open .flex-icon-container .date-input-container {
  margin-right: unset !important;
}
.menubox.mobile.open .flex-icon-container .date-input-container > span {
  flex: 1 0 21%;
}
.menubox.mobile.open .flex-icon-container .date-input-container .customdaterange {
  flex: 1 0 71.8%;
  margin-left: 8px;
}
.menubox.mobile.open .flex-icon-container.left {
  width: 100%;
}
.menubox.mobile.open .flex-icon-container.left .period-button {
  margin-right: 2px;
}
.menubox.mobile.open .flex-icon-container.right {
  height: 40px;
}
.menubox.mobile.open .flex-icon-container.right .menuitem {
  margin-left: 0;
  margin-right: 3px;
  background-size: 24px 24px;
}
.menubox.mobile.open .flex-icon-container.right .menuitem .setting-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.menubox.mobile.open .flex-icon-container.right .empty {
  width: 36px;
  height: 36px;
}
.menubox.mobile #mi-charttype-on .connection-box {
  top: 77px;
  left: 0;
  right: auto;
}
.menubox.mobile #mi-drawtool-on .connection-box {
  top: 77px;
  left: 15.5%;
  right: auto;
}
.menubox.mobile #mi-ta-on .connection-box {
  top: 77px;
  left: 31.6%;
  right: auto;
}
.menubox.mobile #mi-ta-on .menusubitem .msi-optbox .menusubitem {
  justify-content: flex-start;
}
.menubox.light.open .flex-icon-container .setting-option {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 1px 4px;
}
.menubox.light.open .flex-icon-container .setting-option .setting-button {
  border: 1px solid #e0e0e0;
  color: #828282;
  /* svg */
}
.menubox.light.open .flex-icon-container .setting-option .setting-button.selected {
  border: 1px solid #008fc7;
  background-color: #008fc7;
  color: #ffffff;
}
.menubox.light.open .flex-icon-container .setting-option .setting-button:first-child svg rect {
  fill: #828282;
}
.menubox.light.open .flex-icon-container .setting-option .setting-button:first-child svg rect:first-child,
.menubox.light.open .flex-icon-container .setting-option .setting-button:first-child svg rect:nth-child(4) {
  fill: none;
  stroke: #828282;
}
.menubox.light.open .flex-icon-container .setting-option .setting-button:first-child.selected svg rect {
  fill: #ffffff;
}
.menubox.light.open .flex-icon-container .setting-option .setting-button:first-child.selected svg rect:first-child,
.menubox.light.open .flex-icon-container .setting-option .setting-button:first-child.selected svg rect:nth-child(4) {
  fill: none;
  stroke: #ffffff;
}
.menubox.light.open .flex-icon-container .setting-option .setting-button:nth-child(2) svg path {
  fill: #828282;
}
.menubox.light.open .flex-icon-container .setting-option .setting-button:nth-child(2).selected svg path {
  fill: #ffffff;
}
.menubox.light.open .flex-icon-container .setting-option .setting-button:last-child svg rect {
  fill: #828282;
}
.menubox.light.open .flex-icon-container .setting-option .setting-button:last-child.selected svg rect {
  fill: #ffffff;
}
.menubox.light.open .flex-icon-container .hidden-shadow {
  position: absolute;
  top: 117px;
  left: -16px;
  width: calc(100% + 32px);
  height: 6px;
  background-color: #ffffff;
  z-index: 6;
}
.menubox.light.open .flex-icon-container .hidden-icon-shadow {
  position: absolute;
  top: 36px;
  right: 11.8%;
  width: 36px;
  height: 6px;
  background-color: #ffffff;
  z-index: 6;
}
.menubox.light.open .flex-icon-container .menuitem {
  background-color: #ffffff !important;
}
.menubox.light.open .flex-icon-container .menuitem.shawdow {
  box-shadow: rgba(0, 0, 0, 0.18) 0px -1px 4px;
}
.menubox.light.open .flex-icon-container .menuitem#mi-period-on {
  background-color: #008fc7 !important;
}
.menubox.light.open .flex-icon-container .menuitem .menudropdown {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 1px 4px;
}
.menubox.light.open .flex-icon-container .menuitem .menudropdown .menusubitem {
  color: #828282;
}
.menubox.light.open .flex-icon-container .menuitem .date-input-container > span {
  border: 1px solid #e0e0e0 !important;
}
.menubox.light.open .flex-icon-container .menuitem .date-input-container .customdaterange {
  background-color: #e0e0e0;
}
.menubox.light.open .flex-icon-container .menuitem .date-input-container .customdaterange > span {
  color: #828282;
}
.menubox.light.open .flex-icon-container .menuitem .msi-sep {
  border-bottom: 1px solid #e0e0e0;
}
.menubox.light.open .flex-icon-container .menuitem .inputbox,
.menubox.light.open .flex-icon-container .menuitem .inputbox input,
.menubox.light.open .flex-icon-container .menuitem .selectbox select {
  background-color: #e0e0e0;
  color: #333333 !important;
}
.menubox.light.open .flex-icon-container .menuitem .icon {
  position: relative;
  top: 7px;
  width: 16px;
  margin-right: 8px;
  pointer-events: none;
}
.menubox.light.open .flex-icon-container .menuitem .icon path,
.menubox.light.open .flex-icon-container .menuitem .icon rect {
  fill: #828282;
}
.menubox.light.open .flex-icon-container .menuitem .icon.candlestick path,
.menubox.light.open .flex-icon-container .menuitem .icon.candlestick rect {
  fill: none;
  stroke: #828282;
}
.menubox.light .seperate-line.light {
  border-left: 1px solid #e0e0e0;
}
.menubox.light #mi-ta-on .menudropdown .background {
  background-color: #ffffff;
}
.menubox.light #mi-ta-on .menudropdown .background .inputbox input {
  background-color: #e0e0e0;
}
.menubox.light #mi-ta-on .menudropdown .msi-grplbl span {
  color: #333333;
}
.menubox.light #mi-ta-on .menudropdown .taselbox .msi-grplbl {
  border-top: 1px solid #e0e0e0;
}
.menubox.light #mi-ta-on .menudropdown .reset-button {
  color: #009e9c;
}
.menubox.light #mi-ta-on .menudropdown .box-container {
  background-color: #e0e0e0;
  color: black;
}
.menubox.light #mi-ta-on .menudropdown .box-container svg path {
  fill: #333333;
}
.menubox.light #mi-ta-on .menudropdown .menusubitem {
  color: #828282;
}
