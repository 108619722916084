.number_con {
  flex: 1 1;
  -webkit-flex: 1;
  height: 32px;
  align-items: center;
}
.number_con input {
  flex: 1 1;
  -webkit-flex: 1;
  width: 60%;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  background: #393939 !important;
  margin-right: 4px;
  min-height: 32px;
  border-radius: 3px;
}
.number_con input::placeholder {
  color: #828282;
  text-align: center;
  font-weight: 400;
}
.number_con .number_img {
  height: 32px;
  width: 40px;
  border-radius: 3px;
  margin-right: 4px;
  cursor: pointer;
}
.number_con_not input {
  color: #828282;
  opacity: 0.5;
}
.number_con_has input {
  background: #161616 !important;
}
.number_con_light input {
  opacity: 0.6;
}
.linear_gradient_bg {
  background: linear-gradient(#b6b6b6, rgba(255, 255, 255, 0.99));
}
.number_min {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
