.common {
  padding: 8px 16px;
}
.common .child {
  width: 25%;
  justify-content: center;
  font-weight: 500;
}
.common .child:nth-child(n+2) {
  text-align: right;
}
.header {
  font-size: 13px;
  line-height: 15px;
  color: #828282;
  min-height: 57px;
}
