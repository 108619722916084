.create_alert {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1;
}
.create_alert .percent_symbol {
  position: absolute;
  left: 70%;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}
.create_alert .percent_symbol.light {
  color: #333333;
}
.create_alert .name_close_icon {
  width: 10px;
  height: 10px;
}
.create_alert .up_icon {
  margin-left: 10px;
}
.create_alert .up_icon.selected path {
  fill: #f5475b;
}
.create_alert .down_icon {
  margin-left: 10px;
}
.create_alert .down_icon.selected path {
  fill: #39c46e;
}
.create_alert .spanW {
  width: 120px;
  min-width: 120px;
}
.create_alert .tradeflow_con {
  align-items: center;
  height: 48px;
  padding: 0 16px;
}
.create_alert .button {
  background: #373737;
}
.create_alert .button span {
  color: #ffffff !important;
}
.create_alert .button.light {
  background: #ffffff;
}
.create_alert .button.light span {
  color: #333333 !important;
}
.create_alert .button.light[disabled] span {
  color: #828282 !important;
}
.create_alert .button.selected {
  background: #e0e0e0;
}
.create_alert .button.selected span {
  color: #161616 !important;
}
.create_alert .buyingPower .buySelected {
  flex: 1 1;
  -webkit-flex: 1;
  cursor: pointer;
  height: 100%;
  position: relative;
}
.create_alert .buyingPower .buySelected .dropsDown_con {
  position: absolute;
  right: 0;
  top: 48px;
  width: 100%;
}
.create_alert .buyingPower .buySelected .buySelected_right {
  flex: 1 1;
  -webkit-flex: 1;
  height: 100%;
  align-items: center;
}
.create_alert .buyingPower .buySelected .buySelected_right span:first-child {
  font-size: 14px;
  line-height: 16px;
  opacity: 0.5;
}
.create_alert .buyingPower .buySelected .buySelected_right span:nth-child(2) {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-right: 6px;
}
.create_alert .buyingPower .buySelected .buySelected_right span:last-child {
  position: relative;
  top: 1px;
}
.create_alert .buyingPower .buySelected .entername {
  width: 100%;
  height: 100%;
}
.create_alert .buyingPower .buySelected .entername input {
  font-size: 14px;
  line-height: 16px;
}
.create_alert .buyingPower .buySelected .entername input::placeholder {
  color: #828282;
  opacity: 0.5;
}
.create_alert .buyingPower .buySelected .entername .getNamecon {
  align-items: center;
  flex: 1 1;
  -webkit-flex: 1;
}
.create_alert .buyingPower .buySelected .entername .getNamecon span {
  font-weight: 700;
}
.create_alert .buyingPower .buySelected .entername .getNamecon span:nth-child(2) {
  margin: 0 10px 0 3px;
}
.create_alert .buyingPower .buySelected .entername .getNamecon span:last-child {
  color: #00d4f0;
  font-weight: 400;
}
.create_alert .buyingPower .buySelected .entername_light input {
  color: #333333;
}
.create_alert .buyingPower .img_dropDown {
  height: 17px;
  width: 17px;
}
.create_alert .quote_con .quote_con_turnover {
  flex: 1 1;
  -webkit-flex: 1;
  align-items: flex-end;
}
.create_alert .quote_con .quote_con_turnover img {
  margin: 0 10px;
  margin-bottom: 2px;
}
.create_alert .quote_con .quote_con_turnover span {
  position: relative;
  bottom: -1px;
}
.create_alert .quote_con .quote_con_turnover span:first-child {
  font-size: 18px;
  line-height: 21px;
  position: relative;
  top: 2px;
}
.create_alert .quote_con .quote_con_turnover span:nth-child(-n + 4):nth-child(n + 2) {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.create_alert .direction .direction_right {
  flex: 1 1;
  -webkit-flex: 1;
}
.create_alert .direction .direction_right button {
  width: 50%;
  margin-right: 8px;
  border-radius: 3px;
}
.create_alert .direction .direction_right button span {
  height: 100%;
}
.create_alert .direction .direction_right button:last-child {
  width: calc(50% - 8px);
  margin-right: 0;
}
.create_alert .direction_right_light button {
  border: 1px solid #e0e0e0;
}
.create_alert .direction_right_light button span {
  color: #333333;
}
.create_alert .direction_right_light button[disabled] {
  background: none !important;
}
.create_alert .direction_right_light button[disabled] span {
  color: #828282;
}
.create_alert .line {
  height: 8px;
  width: 100%;
}
.create_alert .modal_mask {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.create_alert .modal_mask .max_alert_modal {
  position: relative;
  padding: 16px;
  width: 528px;
  height: 192px;
  font-size: 16px;
  background: #222429;
  color: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}
.create_alert .modal_mask .max_alert_modal .message {
  display: flex;
  line-height: 24px;
}
.create_alert .modal_mask .max_alert_modal .message svg {
  margin-right: 8px;
}
.create_alert .modal_mask .max_alert_modal .botton_button_bar {
  position: absolute;
  bottom: 0;
  right: 0;
}
.create_alert .modal_mask .max_alert_modal .botton_button_bar :global(.ant-btn) {
  width: 128px;
  height: 40px;
}
.create_alert :global(.ant-btn) {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}
.create_alert :global(.ant-btn) span {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: break-spaces;
}
.create_alert.mobile :global(.ant-btn) {
  font-size: 12px !important;
}
.create_alert.mobile :global(.ant-btn) span {
  width: 70px;
}
.create_alert .buttonFz14 :global(.ant-btn) {
  font-size: 14px !important;
}
.create_alert_botton_button_bar {
  width: 100%;
}
