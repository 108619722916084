.dropDown_content {
  position: absolute;
  top: 42px;
  right: 16px;
  z-index: 1000;
  min-width: 98px;
}
.dropDown_content .dropDown_content_title {
  min-height: 32px;
  padding: 0 8px;
}
.dropDown_content .dropDown_content_child {
  min-height: 32px;
  padding: 0 8px;
  cursor: pointer;
  margin-right: 0 !important;
}
.dropDown_content .dropDown_content_child img {
  margin-right: 4px;
}
