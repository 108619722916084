.active_alerts {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1;
}
.active_alerts .padding120 {
  padding: 120px 0;
}
.active_alerts .title {
  display: block;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 12px;
  min-height: 30px;
  line-height: 30px;
}
.active_alerts .title .icon_search {
  position: absolute;
  top: 15px;
  left: 30px;
}
.active_alerts .title .icon_clear {
  position: absolute;
  top: 15px;
  right: 30px;
}
.active_alerts .title input {
  padding-left: 38px;
  width: 100%;
  border-radius: 40px;
}
.active_alerts .title input.typing {
  padding-left: 25px;
}
.active_alerts .title .dropdown > div {
  top: 38px;
  padding: 0 16px;
}
.active_alerts .common {
  align-items: center;
}
.active_alerts .common > span {
  text-align: left;
  padding-right: 8px;
}
.active_alerts .common > span:first-child {
  width: 56px;
  padding-left: 8px;
}
.active_alerts .common > span:nth-child(2) {
  width: calc(100% - 280px);
}
.active_alerts .common > span:nth-child(3) {
  width: 80px;
}
.active_alerts .common > span:nth-child(4) {
  width: 80px;
  text-align: right;
}
.active_alerts .common > span:last-child {
  width: 64px;
}
.active_alerts .common > span:last-child svg {
  float: right;
}
.active_alerts .common_min span {
  /*
      width: 20%;
      &:first-child {
        width: 40%;
      }
      */
}
.active_alerts .header {
  height: 32px;
  padding: 0 8px;
}
.active_alerts .header span {
  font-size: 12px;
  line-height: 14px;
}
.active_alerts .header .sort_button {
  cursor: pointer;
}
.active_alerts .header .sort_button svg {
  margin-left: 5px;
  width: 7px;
}
.active_alerts .body .body_one {
  min-height: 32px;
  padding: 0 8px;
}
.active_alerts .body .body_one_min {
  min-height: 32px;
}
.active_alerts .body .empty_area {
  height: 300px;
}
.active_alerts .body .bold {
  font-weight: bold;
}
.active_alerts .click_icon {
  cursor: pointer;
  margin: 5px;
  width: 16px;
  height: 16px;
}
.active_alerts .bottom_pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding-right: 4px;
  /*
    :global(.ant-pagination-item),
    :global(.ant-pagination-prev),
    :global(.ant-pagination-next) {
      margin-right: 2px;
    }
    */
}
.active_alerts .bottom_pagination .page_number {
  padding: 24px 0 24px 16px;
}
.active_alerts .bottom_pagination :global(.ant-pagination-item:hover) {
  background: #008fc7 !important;
  border-radius: 3px;
}
.active_alerts .bottom_pagination :global(.ant-pagination-item:hover a) {
  color: #ffffff !important;
}
.active_alerts .delete_modal_mask {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.active_alerts .delete_modal_mask .delete_modal {
  position: relative;
  padding: 16px;
  width: 528px;
  height: 192px;
  font-size: 16px;
  background: #222429;
  color: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}
.active_alerts .delete_modal_mask .delete_modal .message {
  display: flex;
  line-height: 24px;
}
.active_alerts .delete_modal_mask .delete_modal .message svg {
  margin-right: 8px;
}
.active_alerts .delete_modal_mask .delete_modal .message .name {
  padding: 0 5px;
  color: #00d4f0;
}
.active_alerts .delete_modal_mask .delete_modal .active_alert_botton_button_bar {
  position: absolute;
  bottom: 0;
  right: 0;
}
.active_alerts .delete_modal_mask .delete_modal .active_alert_botton_button_bar :global(.ant-btn) {
  width: 128px;
  height: 40px;
}
.active_alerts .delete_modal_mask.light .delete_modal .name {
  color: #009e9c !important;
}
.active_alerts.chinese .common > span:first-child {
  min-width: 66px;
  padding-left: 8px;
}
.active_alerts.chinese .common > span:nth-child(2) {
  width: calc(100% - 280px);
}
.active_alerts.chinese .common > span:nth-child(3) {
  width: 90px;
}
.active_alerts.chinese .common > span:nth-child(4) {
  width: 80px;
  text-align: right;
}
.active_alerts.chinese .common > span:last-child {
  width: 64px;
}
.active_alerts.chinese .common > span:last-child svg {
  float: right;
}
.active_alerts.mobile .common.body_one {
  min-height: 55px;
  font-size: 16px;
}
.active_alerts.mobile .common .name_mobile {
  font-size: 14px;
}
.active_alerts.mobile .common > span {
  text-align: left;
  padding-right: 8px;
}
.active_alerts.mobile .common > span:first-child {
  width: calc(100% - 240px);
  padding-left: 8px;
}
.active_alerts.mobile .common > span:nth-child(2) {
  width: 60px;
}
.active_alerts.mobile .common > span:nth-child(3) {
  width: 100px;
  text-align: right;
}
.active_alerts.mobile .common > span:last-child {
  width: 80px;
}
.active_alerts.mobile .common > span:last-child svg {
  float: right;
  margin: 0 5px;
}
.active_alerts.mobile .common > span:last-child svg:first-child {
  margin: 0 0 0 5px;
}
.active_alerts.mobile .header {
  height: 42px;
}
.active_alerts.mobile .header span {
  font-size: 13px !important;
  line-height: 15px;
}
.active_alerts.mobile .delete_modal {
  max-width: 326px;
  max-height: 192px;
}
.active_alerts.mobile .delete_modal .message {
  line-height: 19px;
}
.active_alerts.mobile .delete_modal .name {
  padding-left: 0 !important;
}
.active_alerts.mobile .delete_modal svg {
  min-width: 24px;
  min-height: 24px;
}
.active_alerts.mobile .delete_modal .active_alert_botton_button_bar {
  left: 0;
}
.active_alerts.mobile .delete_modal .active_alert_botton_button_bar :global(.ant-btn) {
  width: 50%;
}
.active_alerts.mobile .click_icon {
  width: 24px;
  height: 24px;
}
.active_alerts.mobile .bottom_pagination {
  flex-direction: column;
}
.active_alerts.mobile .no_data_msg {
  padding: 16px 0;
  text-align: center;
}
.active_alerts.mobile.chinese .common > span:first-child {
  width: calc(100% - 270px);
  padding-left: 8px;
}
.active_alerts.mobile.chinese .common > span:nth-child(2) {
  width: 90px;
  font-size: 14px;
}
.active_alerts.mobile.chinese .common > span:nth-child(3) {
  width: 100px;
  text-align: right;
}
.active_alerts.mobile.chinese .common > span:last-child {
  width: 80px;
}
.active_alerts.mobile.chinese .common > span:last-child svg {
  float: right;
  margin: 0 5px;
}
.active_alerts.mobile.chinese .common > span:last-child svg:first-child {
  margin: 0 0 0 5px;
}
