.pie_con {
  overflow: hidden;
}
.pie_con .no_Stock {
  font-size: 12px;
  line-height: 14px;
  width: 100%;
  text-align: center;
}
.pie_con .list {
  min-height: 20px;
}
.pie_con .list .list_left {
  align-items: center;
}
.pie_con .list .list_left .list_circle {
  width: 6px;
  height: 6px;
  margin-right: 8px;
}
.pie_con .list .list_left span {
  display: block;
}
.pie_con .list .list_left span:last-child {
  font-size: 12px;
  line-height: 14px;
  margin-right: 8px;
}
.pie_con .list .num {
  font-size: 12px;
  line-height: 14px;
}
.pie_con .list .num label {
  font-weight: 400;
}
