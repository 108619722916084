.stocks {
  position: relative;
}
.stocks .title {
  display: block;
  font-weight: 500;
  font-size: 12px;
  min-height: 30px;
  line-height: 30px;
  margin: 0 8px;
}
.stocks .title_min {
  line-height: 31px;
  min-height: 31px;
  margin: 0;
  padding: 0 16px !important;
}
.stocks .common {
  align-items: center;
}
.stocks .common span {
  width: 18%;
  text-align: right;
  padding-right: 8px;
}
.stocks .common span:first-child {
  text-align: left;
  padding-left: 8px;
}
.stocks .common span:nth-child(2) {
  width: 28%;
  text-align: left;
}
.stocks .common_min span {
  width: 20%;
}
.stocks .common_min span:first-child {
  width: 40%;
}
.stocks .header {
  padding: 8.5px 0;
  margin: 0 8px;
  position: relative;
}
.stocks .header span {
  font-size: 12px;
  line-height: 14px;
}
.stocks .body {
  padding-bottom: 60px;
}
.stocks .body .body_one {
  min-height: 32px;
  cursor: pointer;
  padding: 0 8px;
}
.stocks .body .body_one_min {
  min-height: 50px;
}
