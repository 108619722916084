.orderComfirm {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: #222429;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.orderComfirm .tradeflow_con {
  min-height: 48px;
  padding: 6px 16px;
  border-bottom: 1px solid #373737;
}
.orderComfirm .tradeflow_con span:nth-child(2) {
  color: #FFFFFF;
}
.orderComfirm .orderComfirm_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  padding: 0 8px;
  align-items: center;
}
.orderComfirm .orderComfirm_title span {
  padding-left: 6px;
}
.orderComfirm .orderComfirm_name span {
  display: block;
  text-align: right;
}
.orderComfirm .tradeflow_con_mobileD .tradeflow_con span {
  font-size: 16px;
}
.orderComfirm .tradeflow_con_mobileD .tradeflow_con span:first-child {
  color: #FFFFFF;
}
.orderComfirm .tradeflow_con_mobileD .tradeflow_con .orderComfirm_name_right span {
  font-size: 14px;
}
.orderComfirm .tradeflow_con_mobileW .tradeflow_con span {
  font-size: 16px;
}
.orderComfirm .tradeflow_con_mobileW .tradeflow_con span:first-child {
  color: #333333;
}
.orderComfirm .tradeflow_con_mobileW .tradeflow_con .orderComfirm_name_right span {
  font-size: 14px;
}
.orderComfirm_light {
  background: #FFFFFF;
}
.orderComfirm_light .tradeflow_con {
  border-bottom: 1px solid #E0E0E0;
}
.orderComfirm_light .tradeflow_con span:nth-child(2) {
  color: #333333;
}
