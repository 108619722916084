.account_layout {
  height: 100%;
}
.account_layout .account_common {
  margin: 0 16px;
}
.account_layout .account_common > * {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  height: 100%;
  width: 5%;
  padding-right: 6px;
  word-break: keep-all;
  align-items: center;
  flex-wrap: wrap;
}
.account_layout .account_common > *:nth-child(n+7) {
  justify-content: flex-end;
}
.account_layout .account_common > *:last-child {
  padding-right: 0;
}
.account_layout .account_common .account_name {
  width: 12%;
}
.account_layout .account_common .flex_column_date {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}
.account_layout .account_common .flex_column_date span:first-child {
  margin-bottom: 2px;
}
.account_layout .account_common .account_header_totalcost {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.account_layout .account_common .account_header_totalcost span {
  width: 100%;
  text-align: right;
}
.account_layout .account_common .account_header_totalcost span:first-child {
  margin-bottom: 2px;
}
.account_layout .account_common_zhcn > * {
  word-break: normal;
}
.account_layout .account_commonMin {
  padding: 6.5px 8px !important;
}
.account_layout .account_commonMin > * {
  width: 15%;
}
.account_layout .account_commonMin .flex_end {
  justify-content: flex-end;
}
.account_layout .account_commonMin .flex_column {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-end;
}
.account_layout .account_commonMin .flex_column .account_pending {
  margin-top: 3px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
}
.account_layout .account_common_left_Mobile {
  width: 100%;
}
.account_layout .account_common_left_Mobile span {
  display: block;
  width: 100%;
  text-align: left;
}
.account_layout .account_common_right_Mobile span {
  display: block;
  width: 100%;
  text-align: right;
}
.account_layout .account_header {
  padding: 4px 8px;
  align-items: center;
  min-height: 32px;
}
.account_layout .account_header > * {
  font-size: 10px;
  line-height: 12px;
  text-align: right;
}
.account_layout .header_pc > * {
  font-size: 12px;
  line-height: 14px;
}
.account_layout .account_flexend {
  justify-content: flex-end;
}
.account_layout .account_body {
  padding: 7.5px 8px;
  font-weight: 500;
  word-break: break-word;
  align-items: center;
  min-height: 32px;
}
.account_layout .account_body > *:nth-child(-n+10):nth-child(n+7) {
  color: #FFFFFF;
}
.account_layout .account_body_light > *:nth-child(-n+10):nth-child(n+7) {
  color: #333333;
}
.account_layout .account_dividendhistory {
  font-weight: 400;
  align-items: center;
  justify-content: flex-end;
}
.account_layout .account_dividendhistory > *:nth-child(-n+15):nth-child(n+5) {
  justify-content: flex-end;
  text-align: right;
  color: #828282;
}
.account_layout .account_common_todayOrder > * {
  width: 7%;
}
.account_layout .account_common_todaydeal > * {
  width: 7%;
}
.account_layout .account_common_todaydeal > *:nth-child(2) {
  width: 6%;
}
.account_layout .account_common_todaydeal > *:nth-child(3) {
  width: 5%;
}
.account_layout .account_common_todaydeal > *:nth-child(5) {
  width: 47%;
}
.account_layout .account_header_mobile {
  padding: 4px 16px;
}
.account_layout .account_header_mobile2 > * {
  font-size: 13px;
  line-height: 15px;
}
.account_layout .account_common_mobile {
  min-height: 48px !important;
  padding: 4px 16px;
}
.account_layout .account_tips {
  width: 100%;
  font-size: 12px;
  line-height: 14px;
}
.account_layout .account_tips div {
  padding: 10px 16px;
}
.account_layout .account_tips_min div {
  padding-bottom: 20px;
}
.header_con .header_con_left {
  overflow: hidden;
  align-items: center;
}
.header_con .header_con_left .header_tabs {
  overflow: hidden;
  overflow-x: auto;
  align-items: center;
}
.header_con .header_con_left .header_tabs::-webkit-scrollbar {
  display: none;
}
