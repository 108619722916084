.edit_title {
  align-items: center;
  height: 48px;
  min-height: 48px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  padding: 0 16px 0 10px;
}
.edit_title span {
  padding-left: 6px;
}
.body_one_edit {
  align-items: center !important;
  padding: 0 16px !important;
  cursor: default !important;
}
.body_one_edit img:first-child {
  margin-right: 6px !important;
}
.footer_con_edit {
  height: 55px !important;
}
.footer_con_edit > * {
  width: calc(100%/3) !important;
  line-height: 55px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  border-right: 0 !important;
}
