.dropDownSwitch .account {
  min-width: 200px;
  min-height: 30px;
  border-radius: 40px;
  padding: 2px 16px;
}
.dropDownSwitch .account img {
  cursor: default;
}
.dropDownSwitch .dropDownImg {
  width: 16px;
  height: 12px;
}
.dropDownSwitchM {
  margin-bottom: 8px;
}
.dropDownSwitchM .account {
  min-width: auto;
  margin-right: 0 !important;
  width: 100%;
}
.switchBank {
  flex: 1 1;
  -webkit-flex: 1;
}
.switchBank .account {
  min-width: auto;
  flex: 1 1;
  -webkit-flex: 1;
}
.dropDownCampagin {
  margin: 0 0 8px 0;
}
.dropDownCampagin .account {
  width: 100%;
  margin: 0 !important;
  min-width: auto;
}
.label_span {
  font-size: 10px;
  line-height: 12px;
  color: #008FC7;
  border: 1px solid #008FC7;
  border-radius: 3px;
  padding: 2px 5px;
  margin: 0 4px;
  vertical-align: middle;
  cursor: pointer;
}
.label_light {
  color: #009E9C;
  border: 1px solid #009E9C;
}
