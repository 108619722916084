.BankBalancePage {
  height: 100%;
  position: relative;
}
.BankBalancePage .headerCon {
  align-items: center;
}
.BankBalancePage .headerCon .btnWords {
  width: 32px;
  height: 32px;
  border-radius: 3px;
  cursor: pointer;
}
