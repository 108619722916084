.list_con .list_header {
  padding: 4px 16px;
  min-height: 32px;
}
.list_con .list_body {
  padding: 4px 16px;
  min-height: 48px;
  font-weight: 500;
  word-break: break-word;
}
