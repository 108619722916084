.number_con {
  flex: 1 1;
  -webkit-flex: 1;
  height: 32px;
  background: #393939;
  border-radius: 3px;
  padding: 0 4px;
  align-items: center;
}
.number_con input {
  flex: 1 1;
  -webkit-flex: 1;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  font-weight: 700;
  width: 90%;
}
.number_con input::placeholder {
  color: #828282;
  text-align: center;
  font-weight: 400;
}
.number_con .number_img_calculator {
  margin-right: 5px;
}
.number_con_light {
  background: #E0E0E0;
}
.number_con_light input {
  color: #333333 !important;
}
.number_con_not input {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #828282;
  opacity: 0.5;
}
