.campaginListPage {
  padding: 0 16px;
}
.campaginListPage .dropDownImg {
  width: 16px;
  height: 12px;
}
.campaginListPage .topCon {
  min-height: 75px;
  padding-left: 6px;
}
.campaginListPage .topCon .total span:last-child {
  font-size: 24px;
  font-weight: 700;
  margin-top: 12px;
}
.campaginListPage .topCon .market {
  min-width: 127px;
  min-height: 32px;
  border-radius: 40px;
  padding: 2px 16px;
  cursor: pointer;
}
.campaginListPage .topCon .period {
  min-width: 127px;
  min-height: 32px;
  margin: 0 8px;
  border-radius: 40px;
  padding: 2px 16px;
  cursor: pointer;
}
.campaginListPage .topCon .view {
  min-height: 32px;
  padding: 4px 8px;
  border: 1px solid #00D4F0;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}
.campaginListPage .commonCon {
  padding: 8px;
  min-height: 32px;
  align-items: center;
}
.campaginListPage .commonCon span {
  flex: 1 1;
}
.campaginListPage .commonCon span:nth-child(3) {
  text-align: right;
}
.campaginListPage .commonCon span:last-child {
  text-align: right;
}
.campaginListPage .headerCon span {
  font-size: 14px;
}
.campaginListPage .headerPC span {
  color: #828282;
}
.campaginListPageTable {
  padding: 0;
}
.campaginListPageTable .topCon {
  min-height: 66px;
  padding: 16px;
}
.campaginListPageTable .commonCon {
  padding: 8px 24px;
}
.campaginListPageMobile .topCon {
  min-height: 105px;
}
.campaginListPageMobile .topCon .viewMobile {
  min-height: 20px;
  font-size: 10px;
  margin-top: 12px;
}
