.button_bar {
  display: flex;
}
.button_bar .button_bar_button {
  cursor: pointer;
  margin-right: 8px;
  padding: 5px 8px;
  border-radius: 3px;
  color: #828282;
  font-size: 12px;
}
.button_bar .button_bar_button.selected,
.button_bar .button_bar_button:hover {
  background-color: #008fc7;
  color: #ffffff !important;
}
