.reminder {
  margin: 0 auto;
}
.reminder .reminder_title {
  padding: 11.5px 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
.reminder .reminder_content {
  padding: 20.5px 13px 20.5px 16px;
  min-height: 111px;
  font-size: 14px;
  line-height: 16px;
  word-break: break-word;
}
.reminder .place_button_reminder {
  height: auto;
  min-height: auto;
  justify-content: flex-end;
}
.reminder .place_button_reminder button {
  width: 128px;
}
