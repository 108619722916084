.body {
  padding: 16px 16px 28px 16px;
}
.body .status_title {
  margin-bottom: 16px;
  display: block;
}
.body .status_sub_title {
  margin-bottom: 8px;
  display: block;
}
.body .status_content_0 {
  margin: 0 0 9px 10px;
}
.body .status_content_0 .status_circle {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #828282;
  margin-right: 10px;
  position: relative;
  top: 5px;
}
.body .status_check {
  margin: 0 0 8px 20px;
}
.body .status_check img {
  margin-right: 4px;
  position: relative;
  bottom: 2px;
  cursor: default;
}
.body .status_content_1 {
  margin: 0 0 8px 25px;
}
.body .status_table_oneHeader {
  min-height: 32px;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
}
.body .status_table_oneHeader span {
  min-height: 32px;
}
.body .status_table_body span {
  min-height: 32px;
  text-align: center;
}
.body .border_dark {
  border: 1px solid #484848;
}
.body .border_bottom_dark {
  border-bottom: 1px solid #484848;
}
.body .border_right_dark {
  border-right: 1px solid #484848;
}
.body .border_bottom_light {
  border-bottom: 1px solid #CCCBCB;
}
.body .border_right_light {
  border-right: 1px solid #CCCBCB;
}
