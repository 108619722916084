.calculator_con {
  min-height: 269px;
  width: 528px;
  padding: 16px;
  background: #222429;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}
.calculator_con .calculator_title {
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
  display: block;
  margin-bottom: 25px;
}
.calculator_con .calculator_number_con {
  align-items: center;
  margin-bottom: 15px;
}
.calculator_con .calculator_number_con span {
  font-size: 16px;
  line-height: 18px;
}
.calculator_con .calculator_number_con span:first-child {
  min-width: 104px;
}
.calculator_con .calculator_number_con span:last-child {
  min-width: 60px;
  text-align: right;
}
.calculator_con .calculator_number_con .calculator_number_quantity {
  text-align: left !important;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
}
.calculator_con .calculator_btn {
  justify-content: flex-end;
}
.calculator_con .calculator_btn button {
  width: 128px;
  height: 40px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  padding: 0;
}
.calculator_con .calculator_btn button:first-child {
  margin-right: 15px;
}
.calculator_con .calculator_btn :global(.ant-btn:focus) {
  background: #393939;
}
.calculator_con_light {
  background: #FFFFFF;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}
.calculator_con_mobile {
  width: 80%;
  margin: 0 auto;
}
.calculator_con_mobile .calculator_btn {
  justify-content: space-between;
}
.calculator_con_mobile .calculator_btn button {
  width: 49%;
}
