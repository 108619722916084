.OverseasIncomeCon {
  width: 100%;
}
.OverseasIncomeCon .dateY {
  padding: 0 8px;
  min-width: 85px;
  align-items: center;
}
.OverseasIncomeCon .header {
  min-height: 64px;
  font-size: 13px;
  line-height: 15px;
  color: #828282;
}
.OverseasIncomeCon .header .overseasL .title {
  min-height: 31px;
  padding: 0 8px;
}
.OverseasIncomeCon .header .overseasL .subTitle {
  min-height: 32px;
  align-items: center;
}
.OverseasIncomeCon .header .overseasL .subTitle span {
  text-align: right;
  padding-right: 8px;
  flex: 1 1;
  -webkit-flex: 1;
}
.OverseasIncomeCon .body {
  min-height: 32px;
}
.OverseasIncomeCon .body .OverseasCommon span {
  text-align: right;
  padding-right: 8px;
  flex: 1 1;
  -webkit-flex: 1;
}
