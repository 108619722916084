.flag__usa {
  background: url(../../../asserts/imgs/chart/flags/US@1.5x.svg) no-repeat center;
  background-size: cover;
  background-color: white;
  width: 20px;
  height: 15px;
  display: block;
  top: 12px;
  left: -5px;
  position: relative;
}
.flag__hkg {
  background: url(../../../asserts/imgs/chart/flags/small/HKG.png) no-repeat center;
  background-size: cover;
  background-color: white;
  width: 20px;
  height: 15px;
  display: block;
  top: 12px;
  left: -5px;
  position: relative;
}
.flag__chn {
  background: url(../../../asserts/imgs/chart/flags/cn.svg) no-repeat center;
  background-size: cover;
  background-color: white;
  width: 20px;
  height: 15px;
  display: block;
  top: 12px;
  left: -5px;
  position: relative;
}
.flag__jpn {
  background: url(../../../asserts/imgs/chart/flags/small/JPN.png) no-repeat center;
  background-size: cover;
  background-color: white;
  width: 20px;
  height: 15px;
  display: block;
  top: 12px;
  left: -5px;
  position: relative;
}
.flag__kor {
  background: url(../../../asserts/imgs/chart/flags/small/KOR.png) no-repeat center;
  background-size: cover;
  background-color: white;
  width: 20px;
  height: 15px;
  display: block;
  top: 12px;
  left: -5px;
  position: relative;
}
.flag__sgp {
  background: url(../../../asserts/imgs/chart/flags/small/SGP.png) no-repeat center;
  background-size: cover;
  background-color: white;
  width: 20px;
  height: 15px;
  display: block;
  top: 12px;
  left: -5px;
  position: relative;
}
.flag__gbr {
  background: url(../../../asserts/imgs/chart/flags/GB@1.5x.svg) no-repeat center;
  background-size: cover;
  background-color: white;
  width: 1.3rem;
  height: 15px;
  display: block;
  top: 12px;
  left: -5px;
  position: relative;
}
.flag__euro {
  background: url(../../../asserts/imgs/chart/flags/euro.svg) no-repeat center;
  background-size: cover;
  background-color: white;
  width: 1.3rem;
  height: 15px;
  display: block;
  top: 12px;
  left: -5px;
  position: relative;
}
.flag__small {
  height: 0.8125rem;
  width: 1.2rem;
  margin-top: 0.15px;
}
