.header_con {
  align-items: center;
  position: relative;
}
.header_con .header_left .top {
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 6px;
}
.header_con .header_left .top .top_dropdown_img {
  width: 13px;
  height: 12px;
  position: relative;
  top: 2px;
}
.header_con .header_left .top .cur {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 0 2px 0 4px;
}
.header_con .header_left .bottom {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}
.header_con .header_left .share_num {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}
.header_con .header_left_mobile {
  width: 100%;
  padding: 10px 16px;
  justify-content: space-between;
}
.header_con .header_right {
  flex: 1 1;
  -webkit-flex: 1;
  align-items: center;
}
.header_con .header_right .input_con_mobile {
  width: calc(45% - 8px);
}
.header_con .header_right_mobile {
  padding: 12px 16px;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.header_con .dropsDown_con {
  min-height: 100px;
  max-height: 300px;
  position: absolute;
}
.page_con {
  justify-content: flex-end;
  margin: 10px 0 20px 0;
}
