.news_right .right_header {
  height: 48px;
  min-height: 48px;
  font-size: 16px;
  font-weight: 700;
}
.news_right .right_header img {
  margin: 0 4px 0 8px;
}
.news_right .news_right_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: block;
}
.news_right .news_right_date {
  margin: 16px 0;
}
.news_right .news_right_date .news_date_left {
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}
.news_right .news_right_date img {
  width: 24px;
  height: 24px;
  margin: 0;
}
.news_right .news_slider {
  height: 32px;
  padding: 0 16px;
  border-radius: 40px;
  margin-bottom: 16px;
}
.news_right .news_slider img {
  width: 16px;
  height: 16px;
  cursor: default;
  margin: 0;
}
.news_right .news_slider .news_slidercon {
  margin: 0 8px;
}
.news_right .news_slider .news_slidercon :global(.ant-slider-rail) {
  background: #828282;
}
.news_right .news_slider .news_slidercon :global(.ant-slider-track) {
  background: #008FC7;
}
.news_right .news_slider .news_slidercon :global(.ant-slider-handle) {
  background: #008FC7;
  border: 2px solid #008FC7;
}
.news_right a {
  color: #00d4f0;
}
.news_right h6 {
  color: #E0E0E0;
  font-size: 16px;
}
.news_right img {
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
  cursor: default;
}
.news_right ul {
  margin-bottom: 16px;
}
.news_right .news_con_h6 h6 {
  color: #333333;
}
.news_right .news_con_h6 a {
  color: #009e9c;
}
.news_tablet_right {
  width: 100%;
}
.news_tablet_right .news_con_right {
  padding: 0 16px;
}
.news_isMobile_right {
  margin: 0 auto;
}
.news_con_right2 {
  width: 80%;
  margin: 0 auto;
}
