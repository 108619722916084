.left_list {
  position: relative;
  height: 100%;
}
.left_list .common {
  align-items: center;
}
.left_list .common span {
  display: block;
  width: 20%;
  text-align: center;
}
.left_list .common span:first-child {
  width: 60%;
  padding-left: 16px;
  text-align: left;
}
.left_list .common span:nth-child(2) {
  text-align: right;
  padding-right: 8px;
}
.left_list .header {
  min-height: 32px;
}
.left_list .header span {
  color: #828282;
  font-weight: 400;
  font-size: 12px;
}
.left_list .body {
  flex: 1 1;
  -webkit-flex: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 40px;
}
.left_list .body .body_one {
  min-height: 40px;
  cursor: pointer;
}
.left_list .body .body_one span {
  font-weight: 500;
  font-size: 14px;
}
.left_list .body .body_one span:first-child {
  font-weight: 700;
}
.left_list .body .body_one span:nth-child(2) {
  padding-right: 8px;
}
.left_list .body .body_one span:last-child {
  height: 40px;
  line-height: 40px;
  color: #FFFFFF;
}
