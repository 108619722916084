.criteria_con {
  position: absolute;
  border-radius: 3px;
  overflow: hidden;
  z-index: 99;
  background: #333333;
  box-shadow: 2px 2px 8px #000000;
}
.criteria_con .criteria_title_con {
  align-items: center;
  min-height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  padding: 0 8px;
}
.criteria_con .criteria_title_con img {
  margin-right: 4px;
}
.criteria_con .criteria_body::-webkit-scrollbar-thumb {
  background-color: #000000 !important;
}
.criteria_con .criteria_body_light::-webkit-scrollbar-thumb {
  background-color: #828282 !important;
}
.criteria_con .criteria_btn {
  padding: 16px 12px;
  border-top: 1px solid #828282;
}
.criteria_con .criteria_btn button {
  width: calc(100% / 2 - 4px);
  border-radius: 3px;
  height: 40px;
  font-weight: 700;
  font-size: 16px;
}
.criteria_con .criteria_btn button:first-child {
  margin-right: 8px;
  background: #828282;
}
