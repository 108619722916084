.order_fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  -webkit-overflow-scrolling: touch;
}
.order_fixed .order_con .tradeflow_con {
  min-height: 48px;
  padding: 4px 16px;
  border-bottom: 1px solid #373737;
}
.order_fixed .order_con .title {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  padding: 0 8px;
  align-items: center;
  position: relative;
}
.order_fixed .order_con .title span {
  padding-left: 6px;
}
.order_fixed .order_con .title .detail_close {
  right: 8px;
}
.order_fixed .order_con .button_con {
  padding: 16px;
}
.order_fixed .order_con .button_con button {
  width: 100%;
  height: 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
}
