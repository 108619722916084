.marketCap .marketCap_header {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin: 16px 16px 8px 16px;
}
.marketCap .marketCap_header .marketCap_img {
  width: 20px;
  cursor: pointer;
  text-align: right;
}
.marketCap .marketCap_header .marketCap_img img {
  width: 15px;
  height: 14px;
}
.marketCap .market_data_con {
  font-size: 12px;
  line-height: 14px;
  color: #00D4F0;
  padding: 0 16px 16px 16px;
}
.marketCap .marketCap_checkbox {
  min-height: 24px;
  padding: 0 16px;
  cursor: pointer;
}
.marketCap .marketCap_checkbox img {
  margin-right: 8px;
}
.marketCap .criteria_btn_notallowed {
  cursor: not-allowed;
}
.marketCap .checkbox_line {
  height: 1px;
  margin: 0 16px;
}
