.profile_con {
  padding: 16px 16px 0 16px;
  position: relative;
}
.profile_con .profile_title {
  display: block;
  font-weight: 700;
  margin-bottom: 14px;
}
.profile_con .profile_left {
  width: 50%;
  padding-right: 33px;
  position: relative;
}
.profile_con .profile_left_min {
  width: 100%;
  padding: 0 0 20px 0;
  margin-bottom: 16px;
}
.profile_con .profile_right {
  width: calc(50% - 1px);
  padding-left: 16px;
}
.profile_con .profile_right .profile_msg {
  margin-bottom: 8px;
}
.profile_con .profile_right .profile_msg span:first-child {
  min-width: 134px;
  margin-right: 10px;
}
.profile_con .profile_right .profile_msg span:last-child {
  font-weight: 500;
  text-align: right;
}
.profile_con .profile_right_min {
  width: 100%;
  padding: 0 0 44px 0;
}
.profile_con .profile_right_min .profile_msg span:first-child {
  min-width: 93px;
}
.profile_con .profile_right_zh .profile_msg span:first-child {
  min-width: 56px;
}
