.StatementPage {
  height: 100%;
  position: relative;
}
.StatementPage .headerCon .inputCons {
  max-width: 600px;
  margin-right: 8px;
}
.StatementPage .headerCon .btnWords {
  width: 32px;
  height: 32px;
  border-radius: 3px;
  cursor: pointer;
}
