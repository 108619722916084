.header_line {
  height: 10px;
  width: 100%;
  background: linear-gradient(90deg, #0089D1 0%, #01B6AD 100%);
}
.header_con {
  width: 100%;
  padding: 0 16px;
  align-items: center;
}
.header_con img {
  width: 201px;
  height: 30px;
}
.header_con_min img {
  width: 160px;
  height: 24px;
}
