.common {
  min-height: 16px;
  align-items: center;
  padding: 2px 8px;
}
.common .child {
  width: 5%;
  min-height: 32px;
  padding-right: 8px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-end;
}
.common .child:first-child {
  width: 4%;
}
.common .child:nth-child(2) {
  width: 6%;
}
.common .child:nth-child(4) {
  width: 9%;
}
.common .child:nth-child(n+1):nth-child(-n+4) {
  justify-content: flex-start;
}
.common .child:nth-child(n+6):nth-child(-n+14) {
  width: 6%;
}
.common .child:nth-last-child(2) {
  width: 7%;
}
.common .child:last-child {
  padding-right: 0;
}
.header .child {
  font-size: 13px;
  line-height: 15px;
  color: #828282;
  text-align: right;
}
.header .child:nth-last-child(2) {
  padding-right: 12px;
}
.header .child span {
  width: 100%;
  text-align: right;
}
.body .child:nth-last-child(2) {
  padding-right: 0;
}
