.notFound {
  width: 100%;
  height: 100%;
  background: #222429;
}
.App_loginout {
  background-size: 100% 100%;
}
.App_loginout .App_login .App_login_logo {
  width: 320px;
  height: 46px;
  margin: 20px 0 32px 0;
  cursor: default;
}
.App_loginout .App_login .App_login_logo_mobile {
  width: 269px;
  height: 43px;
}
.App_loginout .App_login .App_login_content {
  padding: 40px 32px 52px 32px;
  background: #FFFFFF;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}
.App_loginout .App_login .App_login_content .title_con {
  margin-bottom: 16px;
}
.App_loginout .App_login .App_login_content .title_con .App_login_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
.App_loginout .App_login .App_login_content .title_con .subtitle {
  color: #F5475B;
  font-size: 18px;
}
.App_loginout .App_login .App_login_content input {
  color: #333333;
  padding: 8px 16px;
  border-radius: 4px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  margin-bottom: 16px;
  min-height: 48px;
}
.App_loginout .App_login .App_login_content input::placeholder {
  color: #E0E0E0;
}
.App_loginout .App_login .App_login_content input::-internal-autofill-selected {
  background-color: #FFFFFF !important;
  background: #FFFFFF;
}
.App_loginout .App_login .App_login_content .App_login_pwd {
  position: relative;
  margin-bottom: 16px;
}
.App_loginout .App_login .App_login_content .App_login_pwd input {
  padding: 8px 40px 8px 16px;
  margin-bottom: 0;
}
.App_loginout .App_login .App_login_content .App_login_pwd img {
  right: 15px;
}
.App_loginout .App_login .App_login_content .App_login_core {
  align-items: center;
  justify-content: space-between;
}
.App_loginout .App_login .App_login_content .App_login_core input {
  flex: 1 1;
  -webkit-flex: 1;
  margin-bottom: 0;
}
.App_loginout .App_login .App_login_content .App_login_core .App_login_core_right {
  width: 161px;
  margin-left: 16px;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.App_loginout .App_login .App_login_content .App_login_core .App_login_core_right img {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
}
.App_loginout .App_login .App_login_content .App_login_core .App_login_core_right > * {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48px;
}
.App_loginout .App_login .App_login_content .App_login_core .App_login_core_right > * canvas {
  width: 129px;
  height: 48px;
  line-height: 48px;
}
.App_loginout .App_login .App_login_content .App_login_core .App_login_core_right > * div {
  z-index: 1;
  opacity: 0;
}
.App_loginout .App_login .App_login_content .App_login_remember {
  min-height: 24px;
  margin-top: 16px;
  align-items: center;
  justify-content: space-between;
}
.App_loginout .App_login .App_login_content .App_login_remember .App_login_remember_left {
  align-items: center;
}
.App_loginout .App_login .App_login_content .App_login_remember .App_login_remember_left span {
  margin-left: 8px;
}
.App_loginout .App_login .App_login_content .App_login_remember .App_login_remember_right {
  color: #008FC7;
  cursor: pointer;
}
.App_loginout .App_login .App_login_content button {
  background: #008FC7;
  border-radius: 3px;
  width: 100%;
  height: 56px;
  margin-top: 16px;
}
.App_loginout .App_login .App_login_sign {
  color: #333333;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  display: block;
  position: absolute;
  left: 141px;
  bottom: 23px;
}
.App_loginout .App_login .App_login_sign_1 {
  text-align: center;
  left: 0;
}
.App_loginout .App_login .App_login_sign_2 {
  position: relative !important;
  bottom: 0 !important;
  left: 141px;
  margin-top: 30px;
}
.App_loginout .App_login_mobile {
  width: 100%;
  margin: 0;
  align-items: center;
}
.App_adminout {
  background: #222429;
}
.App_adminout .App_admin {
  width: 490px;
  min-height: 340px;
  background: #FFFFFF;
  padding: 20px;
  text-align: center;
}
.App_adminout .App_admin input:-internal-autofill-selected {
  background-color: #FFFFFF !important;
  background: #FFFFFF;
}
.App_adminout .App_admin .App_login_logo {
  width: 234px;
  height: 35px;
  margin-bottom: 50px;
  cursor: default;
}
.App_adminout .App_admin .App_login_username {
  align-items: center;
  width: 80%;
  margin: 0 auto;
}
.App_adminout .App_admin .App_login_username img {
  width: 26px;
  min-width: 26px;
  height: 26px;
  cursor: default;
}
.App_adminout .App_admin .App_login_username input {
  flex: 1 1;
  color: #000000;
  padding: 8px 16px;
  margin-left: 10px;
  border-radius: 4px;
}
.App_adminout .App_admin .App_login_username button {
  background: #008FC7;
  width: 100%;
  height: 48px;
  margin: 40px 0 20px 0;
}
.App_adminout .App_admin .App_login_userpwd {
  margin-top: 10px;
}
