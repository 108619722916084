.updatePwdCon {
  height: 100%;
  margin-left: 141px;
  justify-content: flex-end;
}
.updatePwdCon .logo {
  width: 320px;
  height: 46px;
  margin: 32px 0 32px 30px;
  cursor: default;
}
.updatePwdCon .logoMobile {
  width: 269px;
  height: 43px;
}
.updatePwdCon .updatePwdChild {
  width: 456px;
  max-width: 456px;
  max-height: 602px;
  padding: 40px 32px;
  background: #FFFFFF;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}
.updatePwdCon .updatePwdChild .title {
  font-size: 24px;
  line-height: 27px;
  font-weight: 700;
  color: #161616;
  display: block;
  margin-bottom: 16px;
}
.updatePwdCon .updatePwdChild .pwdCon {
  min-height: 48px;
  margin-bottom: 16px;
  position: relative;
}
.updatePwdCon .updatePwdChild .pwdCon input {
  color: #333333;
  padding: 8px 16px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  min-height: 48px;
}
.updatePwdCon .updatePwdChild .pwdCon input::placeholder {
  color: #E0E0E0;
}
.updatePwdCon .updatePwdChild .pwdCon input::-internal-autofill-selected {
  background-color: #FFFFFF !important;
  background: #FFFFFF;
}
.updatePwdCon .updatePwdChild .pwdCon img {
  right: 15px;
  cursor: pointer;
}
.updatePwdCon .updatePwdChild .tipsCon {
  padding: 16px;
  border-radius: 3px;
  background-color: #FFF6E3;
}
.updatePwdCon .updatePwdChild .tipsCon .tipsTitle {
  font-size: 16px;
  font-weight: 700;
  color: #333333;
  min-height: 24px;
  margin-bottom: 10px;
}
.updatePwdCon .updatePwdChild .tipsCon .contents {
  color: #333333;
  margin-bottom: 2px;
}
.updatePwdCon .updatePwdChild .btnCon {
  padding-top: 20px;
  background-color: #FFFFFF;
}
.updatePwdCon .updatePwdChild .btnCon button {
  flex: 1 1;
  -webkit-flex: 1;
  height: 56px;
  padding: 0;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
}
.updatePwdCon .updatePwdChildMobile {
  width: 100%;
}
.updatePwdConM {
  height: auto;
  margin-left: 0;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  -webkit-flex: 1;
}
.updatePwdConM .logo {
  margin: 32px 0 20px 0;
  position: relative;
  right: 40px;
}
.sign {
  color: #333333;
  font-size: 12px;
  line-height: 14px;
  margin: 40px 0 23px 0;
}
