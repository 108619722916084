.title {
  font-size: 12px;
  line-height: 14px;
  color: #828282;
}
.num {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}
.tooltip_con {
  align-items: center;
}
.tooltip_con img {
  position: relative;
  left: 1px;
}
.tooltip_con .tooltip_cur {
  font-size: 12px;
  line-height: 14px;
  margin: 0 2px 0 3px;
}
.marginRight36 {
  margin-right: 36px;
}
.min_height24 {
  min-height: 24px !important;
}
.min_height62 {
  min-height: 62px !important;
}
.min_height64 {
  min-height: 64px !important;
}
.width_per5 {
  width: calc(100% / 5);
}
.msg_con_mobileD .title {
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
}
.msg_con_mobileD .tooltip_cur {
  color: #FFFFFF;
}
.msg_con_mobileW .title {
  font-size: 16px;
  line-height: 18px;
  color: #333333;
}
.msg_con_mobileW .tooltip_cur {
  color: #333333;
}
