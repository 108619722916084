.body_out {
  padding: 16px 16px 25px 16px;
}
.body_out .body {
  position: relative;
}
.body_out .body .grid_con {
  position: absolute;
  cursor: pointer;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  flex-wrap: wrap;
  text-align: center;
}
.body_out .body .grid_con .highcharts-container {
  width: 100% !important;
  height: 100% !important;
}
.body_out .body .charts {
  position: absolute;
  z-index: 999;
  min-width: 368px;
}
.body_out .speed {
  margin: 25px 0  0 0;
}
.body_out .speed span {
  display: block;
  width: 33px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  margin-right: 1px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
}
